<script>
import * as echarts from 'echarts';
import HeaderSearch from '../header-search/index.vue';
import StatisticsCard from '../statistics-card/index.vue';
import WaterWarningInfoModal from '../water-warning-info-modal/index.vue';
import WaterHistoryModal from '../water-history-modal/index.vue';
import { EarlyWarningAPI } from '@/api';
import moment from 'moment';
import { saveAs } from 'file-saver';

export default {
  name: 'Index',
  components: {
    HeaderSearch,
    StatisticsCard,
    WaterWarningInfoModal,
    WaterHistoryModal
  },
  data() {
    return {
      statisticsList: [
        {
          id: 1,
          label: '总测点数量',
          value: 0,
          unit: '次',
          key: 'allMarkCount'
        },
        {
          id: 2,
          label: '总采集次数',
          value: 0,
          unit: '次',
          key: 'allNum'
        },
        {
          id: 3,
          label: '总合格次数',
          value: 0,
          unit: '次',
          key: 'passNum'
        },
        {
          id: 4,
          label: '总预警数量',
          value: 0,
          unit: '次',
          key: 'waringNum'
        }
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      },
      tableData: [],
      waterWarningInfoModalShow: false,
      waterHistoryModalShow: false,
      tabPosition: 'month',
      dateDictionary: [
        {
          key: 'month',
          value: {
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD')
          }
        },
        {
          key: 'week',
          value: {
            startDate: moment().startOf('week').format('YYYY-MM-DD'),
            endDate: moment().endOf('week').format('YYYY-MM-DD')
          }
        },
        {
          key: 'today',
          value: {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
          }
        }
      ],
      waterWarringChart: [],
      currentDate: undefined,
      currentWarning: undefined
    };
  },
  mounted() {
    const dateObj = this.dateDictionary.find((v) => v.key === this.tabPosition);
    if (dateObj) {
      this.currentDate = dateObj.value;
      this.getMarkWaterWarringChart(dateObj.value);
      this.getMarkWaterWarringChartList(dateObj.value);
      this.getAllMarkWaterWarringChart(dateObj.value);
    }
  },
  methods: {
    initPie(data) {
      const chartDom = document.getElementById('echarts-1');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'center',
          right: '10',
          orient: 'vertical',
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              normal: {
                color: (params) => {
                  const colorList = [
                    '#50FFB5',
                    '#FFF250',
                    '#FF7B50',
                    '#5B50FF',
                    '#FF5050',
                    '#50ABFF'
                  ];
                  return colorList[params.dataIndex];
                }
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },
    initHistogram(data) {
      const chartData = data.map((v) => v.value);
      const xDara = data.map((v) => v.name);
      const chartDom = document.getElementById('echarts-2');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: xDara,
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        yAxis: {
          name: '预警次数',
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          alignTicks: true
        },
        series: [
          {
            data: chartData,
            type: 'bar',
            barWidth: '13',
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff'
              }
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(0,58,255)' },
                { offset: 1, color: '#159AFF' }
              ])
            }
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },
    // 获取水位预警饼图，柱状图数据
    getMarkWaterWarringChart(dateObj) {
      EarlyWarningAPI.getMarkWaterWarringChart(dateObj).then((res) => {
        this.waterWarringChart = res.data || [];
        const pieData = this.waterWarringChart.map((v) => ({ name: v.name, value: v.waringNum }));
        const histogramData = this.waterWarringChart.map((v) => ({
          name: v.name,
          value: v.waringNum
        }));
        this.initPie(pieData);
        this.initHistogram(histogramData);
      });
    },
    // 获取水位预警表格数据
    getMarkWaterWarringChartList(dateObj) {
      EarlyWarningAPI.getMarkWaterWarringChartList({
        ...dateObj,
        currentPage: this.pagination.pageNo,
        pageSize: this.pagination.pageSize
      }).then((res) => {
        this.tableData = res.data.items || [];
        this.pagination.totalCount = res.data.totalCount;
      });
    },
    // 表格数据-分页
    handleCurrentChange(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getMarkWaterWarringChartList(this.currentDate);
    },
    // 水位预警-总数居
    getAllMarkWaterWarringChart(dateObj) {
      EarlyWarningAPI.getAllMarkWaterWarringChart(dateObj).then((res) => {
        if (res.data) {
          this.statisticsList.forEach((item) => {
            if (Object.keys(res.data).includes(item.key)) {
              item.value = res.data[item.key];
            }
          });
        }
      });
    },
    tabChangeEvent(value) {
      this.tabPosition = value;
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      const dateObj = this.dateDictionary.find((v) => v.key === value);
      if (dateObj) {
        this.currentDate = dateObj.value;
        this.getMarkWaterWarringChart(dateObj.value);
        this.getMarkWaterWarringChartList(dateObj.value);
        this.getAllMarkWaterWarringChart(dateObj.value);
      }
    },
    searchSubmit(obj) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.currentDate = obj;
      this.getMarkWaterWarringChart(obj);
      this.getMarkWaterWarringChartList(obj);
      this.getAllMarkWaterWarringChart(obj);
      this.tabPosition = '';
    },
    // 查看预警
    checkWarningInfoClick(row) {
      this.currentWarning = row;
      this.waterWarningInfoModalShow = true;
    },
    waterWarningInfoModalClose() {
      this.waterWarningInfoModalShow = false;
    },
    //   历史曲线
    historyLineClick(row) {
      this.waterHistoryModalShow = true;
    },
    waterHistoryModalClose() {
      this.waterHistoryModalShow = false;
    },
    // 导出预警
    exportWarning(row) {
      EarlyWarningAPI.exportMarkWaterWarring({ id: row.id }).then((res) => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        });
        saveAs(blob, `${row.name}.xls`);
      });
    }
  }
};
</script>

<template>
  <div class="water-panels animate__animated animate__bounceInRight">
    <header-search
      :tab-position="tabPosition"
      @tabChangeEvent="tabChangeEvent"
      @searchSubmit="searchSubmit"
    />
    <div class="echarts-wrap">
      <div id="echarts-1" class="echarts-1"></div>
      <div id="echarts-2" class="echarts-2"></div>
    </div>
    <statistics-card :statistics-list="statisticsList" />
    <div class="statistics-table">
      <el-table style="width: 100%" height=" calc(100% - 50px)" :data="tableData">
        <el-table-column label="序号" align="center">
          <template v-slot="{ $index }">
            {{ $index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="水位测点名称" align="center" prop="name"> </el-table-column>
        <el-table-column label="更新时间" align="center" prop="lastDate"> </el-table-column>
        <el-table-column label="数据采集次数" align="center" prop="allNum"> </el-table-column>
        <el-table-column label="合格次数" align="center" prop="passNum"> </el-table-column>
        <el-table-column label="预警数量" align="center" prop="waringNum"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="历史曲线" placement="top">
              <img
                v-hover
                src="~@/assets/image/icons/history-line.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="historyLineClick(scope.row)"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="查看预警" placement="top">
              <img
                v-hover
                src="~@/assets/home/subplatForm/icon/find.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="checkWarningInfoClick(scope.row)"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="导出预警" placement="top">
              <img
                v-hover
                src="~@/assets/image/icons/export.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="exportWarning(scope.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total,prev, pager, next"
          :total="pagination.totalCount"
          :page-size="pagination.pageSize"
          :current-page.sync="pagination.pageNo"
          :page-sizes="[10, 20, 30, 50]"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--查看预警-->
    <water-warning-info-modal
      v-if="waterWarningInfoModalShow"
      :show="waterWarningInfoModalShow"
      :current-warning="currentWarning"
      @close="waterWarningInfoModalClose"
    />
    <!--历史曲线-->
    <water-history-modal
      v-if="waterHistoryModalShow"
      :show="waterHistoryModalShow"
      @close="waterHistoryModalClose"
    />
  </div>
</template>

<style scoped lang="scss">
.water-panels {
  position: fixed;
  top: 54px;
  left: 450px;
  right: 60px;
  height: calc(100vh - 60px);
  background-color: #000;
  border-radius: 8px;
  .echarts-wrap {
    height: 358px;
    display: flex;

    .echarts-1,
    .echarts-2 {
      flex: 1;
    }
  }

  .statistics-table {
    margin-top: 8px;
    height: calc(100% - 488px);
    padding: 0 20px;

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
}
</style>
