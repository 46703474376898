var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"airway-popup"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('span',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"iconfont icon-guanbi",on:{"click":_vm.close}})]),(_vm.active == 0)?_c('div',{staticClass:"content_box left"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"85px"}},[_c('el-form-item',{attrs:{"label":"航线名称","prop":"name"}},[_c('div',{staticClass:"required"},[_vm._v("*")]),_c('el-input',{attrs:{"readonly":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"责任机构","prop":"organizationId"}},[_c('el-cascader',{attrs:{"disabled":"","placeholder":"请选择责任机构","show-all-levels":false,"options":_vm.orgOptions,"props":{
              value: 'id',
              label: 'name',
              children: 'childNodes',
              checkStrictly: true,
              emitPath: false,
            }},model:{value:(_vm.form.organizationId),callback:function ($$v) {_vm.$set(_vm.form, "organizationId", $$v)},expression:"form.organizationId"}})],1),_c('el-form-item',{attrs:{"label":"默认高度","prop":"alt"}},[_c('el-input-number',{attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.alt),callback:function ($$v) {_vm.$set(_vm.form, "alt", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.alt"}}),_vm._v(" m ")],1),_c('el-form-item',{attrs:{"label":"默认速度","prop":"speed"}},[_c('el-input-number',{attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.speed),callback:function ($$v) {_vm.$set(_vm.form, "speed", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.speed"}}),_vm._v(" m/s ")],1),_c('el-form-item',{attrs:{"label":"默认偏航角","prop":"yaw"}},[_c('el-input-number',{attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.yaw),callback:function ($$v) {_vm.$set(_vm.form, "yaw", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.yaw"}}),_vm._v(" (°) ")],1),_c('el-form-item',{attrs:{"label":"默认俯仰角","prop":"pitch"}},[_c('el-input-number',{attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.pitch),callback:function ($$v) {_vm.$set(_vm.form, "pitch", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.pitch"}}),_vm._v(" (°) ")],1),_c('el-form-item',{attrs:{"label":"绘制航点","required":""}},[_c('el-button',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"draw-btn",class:{
              active: _vm.isDraw,
            },on:{"click":_vm.editPoint}},[_c('div',{},[_c('div',{staticClass:"dib"},[_vm._v(_vm._s(_vm.pointsInfo.length))]),_c('div',{staticClass:"iconfont ml4 icon-shengchenghangxian dib"})])])],1)],1)],1):_vm._e(),_c('div',{staticClass:"footer"},[_c('button',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"left btn",on:{"click":_vm.changeRoute}},[_vm._v("保存")]),_c('button',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"right btn",on:{"click":_vm.clearHandle}},[_vm._v("重置")])])]),_c('div',[(_vm.isShowWaypointPopup)?_c('WaypointChange',{attrs:{"uuid":_vm.uuid,"point-info":_vm.pointInfo,"points-info":_vm.pointsInfo,"current-point-num":_vm.currentPointNum},on:{"waypointPopupClose":_vm.waypointPopupClose,"changePointInfo":_vm.changePointInfo,"deletePointInfo":_vm.deletePointInfo}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs"},[_c('span',{staticClass:"tab_box left"},[_vm._v("航线编辑")])])}]

export { render, staticRenderFns }