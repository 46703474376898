<template>
  <div class="p8 w675">
    <div class="table-search">
      <el-form class="jcsb" :model="searchForm" label-width="80px">
        <el-form-item>
          <el-date-picker
            v-model="searchForm.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 250px"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="searchSubmit" class="fr">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="task-notifications mt10">
        <el-table style="width: 100%" :data="messageList" border height="380">
          <el-table-column label="序号" align="center" width="80px">
            <template slot-scope="scope">
              <div class="serial-wrap-number">
                <div :class="scope.row.isRead == 0 ? 'dot' : ''" />
                <span class="number">{{ scope.$index + 1 }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="warningTime" label="预警时间" align="center" />
          <el-table-column
            prop="warningName"
            label="AI识别类型"
            align="center"
            width="150px"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.warningName"
                placement="top"
              >
                <div class="task-name">{{ scope.row.warningName }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作" align="center">
            <template slot-scope="scope">
              <el-badge :is-dot="scope.row.isRead == 0" class="item mt10">
                <el-image
                  :preview-src-list="[scope.row.image]"
                  style="width: 20px; height: 20px"
                  :src="scope.row.image"
                  crossorigin="anonymous"
                  @click="readWarningFn(scope.row)"
                ></el-image>
              </el-badge>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            layout="total,prev, pager, next"
            :total="pagination.totalCount"
            :page-size="pagination.pageSize"
            :current-page.sync="pagination.pageNo"
            :page-sizes="[10, 20, 30, 50]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Airspace } from "@/api";
import { filterObjAttr } from "@/utils";
import moment from "moment/moment";
export default {
  data() {
    return {
      searchForm: {
        time: undefined,
      },
      messageList: [],
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
    };
  },
  created() {
    this.earlyWarning();
  },
  methods: {
    readWarningFn(row) {
      const userInfo = JSON.parse(localStorage.getItem("user_info"));
      if (row.isRead == 0) {
        Airspace.readWarning({
          userId: userInfo.id,
          imageId: row.id,
        }).then((res) => {
          if (res.code == 200) {
            row.isRead = 1;
          }
        });
        // 刷新总预警列表
        this.$store.commit("uavApplications/setState", {
          key: "refreshWarningCount",
          value: Date.now(),
        });
      }
      this.$nextTick((e) => {
        document
          .querySelector(".el-image-viewer__canvas")
          .childNodes[0].setAttribute("crossorigin", "anonymous");
      });
      return false;
    },
    handleSizeChange(pageSize) {
      this.pagination.pageSize = pageSize;
      this.earlyWarning(filterObjAttr(this.searchForm, ["time"]));
    },
    // 分页
    handleCurrentChange(pageNo) {
      this.pagination.pageNo = pageNo;
      this.earlyWarning(filterObjAttr(this.searchForm, ["time"]));
    },
    earlyWarning(query) {
      Airspace.earlyWarning({
        page: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        ...query,
      }).then((res) => {
        if (res.code == 200) {
          this.messageList = res.data.records;
          this.pagination.totalCount = res.data.total;
        }
        console.log(res, "resres");
      });
    },
    // 筛选
    searchSubmit() {
      if (this.searchForm.time?.length && Array.isArray(this.searchForm.time)) {
        this.searchForm.startTime =
          moment(this.searchForm.time.at(0)).format("YYYY-MM-DD") + " 00:00:00";
        this.searchForm.endTime =
          moment(this.searchForm.time.at(-1)).format("YYYY-MM-DD") +
          " 23:59:59";
      } else {
        this.searchForm.startTime = undefined;
        this.searchForm.endTime = undefined;
      }
      this.earlyWarning(filterObjAttr(this.searchForm, ["time"]));
    },
  },
};
</script>
<style lang="scss">
.task-notifications {
  .serial-wrap-number {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: red;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .pagination {
    display: flex;
    margin-top: 10px;
    float: right;
  }
  .task-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ::v-deep {
    el-table__body-wrapper {
      background-color: #0a0b0d;
    }
    .el-image-viewer__img {
      transform: scale(1.3) rotate(0deg);
    }
  }
}
</style>
