<template>
  <div class="uav-control">
    <div class="search_box line">
      <el-input
        v-model="search.name"
        placeholder="请输入鹰巢名称/机构名称"
        prefix-icon="el-icon-search"
        @change="getUavList"
      />
    </div>
    <el-tree
      :data="data"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :render-content="renderContent"
      :props="{ label: 'name', children: 'childs' }"
    />
  </div>
</template>
<script>
let locations = null;
import { Control_API, Tg } from "@/api";
import { mapState } from "vuex";
import { permissionIds } from "@/utils/permission";
export default {
  data() {
    return {
      showData: false,
      data: [],
      openId: null,
      uavData: {},
      uavId: null,
      uavMap: {},
      user_info: JSON.parse(window.localStorage.getItem("user_info")),
      openData: {},
      uavDetails: null,
      timeHandle: null,
      mqtt: {
        client: null,
        decoder: null,
        encoder: new TextEncoder(),
      },
      search: {
        name: "",
      },
      list: [{}],
      showTaskBox: false,
      showTaskDetails: false,
      taskDetails: null,
      typeGroup: [
        {
          id: 0,
          name: "待执行",
        },
        {
          id: 1,
          name: "执行中",
        },
        {
          id: 2,
          name: "已完成",
        },
      ],
      typeOfRatify: [
        {
          id: 0,
          name: "无需审批",
        },
        {
          id: 1,
          name: "待审批",
        },
        {
          id: 2,
          name: "审批完成",
        },
        {
          id: 3,
          name: "已驳回",
        },
      ],
      oldProcessStatus: undefined,
    };
  },
  computed: {
    ...mapState(["userInfo", "projectData"]),
    ...mapState("uavApplicationsEagleNest", ["currentEagle"]),
    openTest() {
      return this.$store.state.uavApplicationsEagleNest.openTest;
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    //   鹰巢流程状态
    processStatus() {
      return this.$store.state.MMCMQTT?.dataSet?.[
        this.currentEagle?.hardId
      ]?.[3];
    },
  },
  watch: {
    processStatus: {
      handler(val) {
        if (val?.state !== this.oldProcessStatus?.state) {
          this.oldProcessStatus = val;
          this.$message[val.state === -1 ? "error" : "success"](val.body);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    await this.getUavList();
    this.timeHandle = setInterval(async () => {
      await this.getUavList();
    }, 25000);
  },
  beforeDestroy() {
    if (locations) {
      viewer.entities.remove(locations);
    }
    clearInterval(this.timeHandle);
    if (this.$uav && this.currentEagle) {
      // 鹰巢跟无人机应用使用同意无人机数据管理，切换时需清除鹰巢无人机数据
      if (this.$uav.network === 1) {
        this.$store.dispatch("MMCMQTT/unsubscribe", {
          topic: "PX4/RECEIVE/" + this.$uav.hardId,
          callback() {},
        });
      } else {
        this.$store.dispatch("MMCGroundStation/unsubscribe", this.$uav.hardId);
      }
      this.$store.dispatch("MMCMQTT/unsubscribe", {
        topic: "PROCESS/RECEIVE/" + this.currentEagle.hardId,
        callback() {},
      });
      this.$store.dispatch("MMCMQTT/unsubscribe", {
        topic: "APRON/RECEIVE/" + this.currentEagle.hardId,
        callback() {},
      });
      this.$store.dispatch("uavApplicationsEagleNest/destroy");
      this.$store.commit("uavApplications/setState", {
        key: "routeId",
        value: null,
      });
      this.$store.commit("uavApplications/setState", {
        key: "uav",
        value: null,
      });
      this.$store.dispatch("uavApplications/destroy");
    }
  },
  methods: {
    taskType(val) {
      if (val === 0) {
        return "常态任务";
      } else if (val === 1) {
        return "定时任务";
      } else if (val === 2) {
        return "周期任务";
      }
    },
    taskTypeName(item) {
      // 先判断审批状态 如果通过或者不用审批 直接取拿执行状态就行 反之拿审批状态
      if (item.approveStatus === 1 || item.approveStatus === 3) {
        this.type = item.approveStatus;
        for (const obj of this.typeOfRatify) {
          if (obj.id == item.approveStatus) {
            return obj.name;
          }
        }
      } else {
        this.type = item.taskStatus;
        for (const obj of this.typeGroup) {
          if (obj.id == item.taskStatus) {
            return obj.name;
          }
        }
      }
    },
    cancel() {
      this.showTaskBox = false;
    },
    async getTaskList(item) {
      const res = await Control_API.getTaskList({
        target: 1,
        current: 1,
        projectId: this.projectData?.id,
        taskStatus: item.taskId ? 1 : undefined,
      });
      if (res.code === 200) {
        this.list = res.data;
      }
    },
    permissionFn(pid) {
      const ids = permissionIds();
      if (ids) {
        if (ids.some((id) => id === pid)) {
          return "";
        } else {
          return "dn";
        }
      } else {
        return "dn";
      }
    },
    renderContent(h, { node, data, store }) {
      if (
        !(data.children && data.children.length) &&
        data.type &&
        data.type === "nests"
      ) {
        return (
          <span
            class="custom-tree-node"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: node.data.online ? "#00FF00" : "#808080",
              width: "300px",
            }}
          >
            <span class="uav-title">
              <span class="uav-title__icon"></span>
              <span class="uav-title__text" title={node.label}>
                {node.label}
              </span>
            </span>
            <span class="ctrl-list">
              <div
                class={`ctrl-icon  ${this.permissionFn(556)}  icon--control`}
                on-click={() => this.eagleNestOperate(data)}
                title="控制"
              ></div>
              <div
                class={`ctrl-icon ${this.permissionFn(557)} ${
                  data.uav?.currentOperator
                    ? "icon--take-over-control-on"
                    : "icon--take-over-control"
                }`}
                on-click={() => this.takeOverTheDrone(data)}
                title="接管"
              ></div>
              {/* <div class={`ctrl-icon icon--lib ${(node.data.online || this.openTest) ? '' : 'disable'}`}
                on-click={() => this.onViewLibrary(node, data)}>
              </div> */}
              <div
                class={`ctrl-icon icon--video  ${this.permissionFn(558)}`}
                disabled={!node.data.online}
                on-click={() => this.onPlay(data)}
                title="视频"
              ></div>
            </span>
            <span
              class="take-over"
              title={
                node.data?.uav?.currentOperatorName
                  ? `${node.data.uav.currentOperatorName}正在接管中,账号为${
                      node.data.uav.username || ""
                    }`
                  : ""
              }
            >
              {node.data?.uav?.currentOperatorName
                ? `${node.data.uav.currentOperatorName}正在接管中,账号为${
                    node.data.uav.username || ""
                  }`
                : ""}
            </span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            {/* <span>{`${node.label} ${node.data.online}/${node.data.online + node.data.offline} `}</span> */}
            <span>{`${node.label}`}</span>
            <span style="color: #409EFF;padding-left:4px;">{`${node.data.online}`}</span>
            <span>{`/${node.data.online + node.data.offline} `}</span>
          </span>
        );
      }
    },
    messageBox(title, message, fun) {
      if (!title || !message) return console.log("请输入提示标题以及提示内容");
      this.$msgbox({
        title,
        message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {
        fun && fun();
      });
    },
    takeOverTheDrone(data) {
      if (!data.online) {
        return this.$message.warning("鹰巢未在线");
      }
      if (!data.uav) {
        return this.$message.warning(`鹰巢${data.name}未关联无人机`);
      }
      if (data.uav.currentOperator === this.userInfo.id) {
        // 是否被本账号接管
        this.messageBox("退出接管", `是否退出接管${data.name}`, async () => {
          const res = await Tg.setUavControlOff(data.uav.id);
          if (res.code === 200) {
            this.$message.success(`已经退出接管${data.name}`);
            // 更新当前无人机的接管状态
            if (this.$uav) {
              this.$store.commit("uavApplications/setState", {
                key: "uav",
                value: {
                  ...this.$uav,
                  currentOperator: 0,
                },
              });
            }
            await this.getUavList();
          }
        });
      } else if (data.uav.currentOperator) {
        // 不是本账号接管的
        this.messageBox(
          "提示",
          `当前选择的鹰巢正在被其他用户接管，请稍后操作？`
        );
      } else {
        // 未被接管
        this.messageBox("接管鹰巢", `是否接管${data.name}`, async () => {
          const res = await Tg.setUavControlOn({
            force: false,
            id: data.uav.id,
            uid: this.userInfo.id,
          });
          if (res.code === 200) {
            this.$message.success(`已成功接管${data.name}`);
            // 更新当前无人机的接管状态
            if (this.$uav) {
              this.$store.commit("uavApplications/setState", {
                key: "uav",
                value: {
                  ...this.$uav,
                  currentOperator: this.userInfo.id,
                },
              });
            }
            await this.getUavList();
          }
        });
      }
    },
    onPlay(data) {
      if (data.online === 1) {
        if (data.uav?.streamConfiguration) {
          this.$store.commit("uavApplicationsEagleNest/setState", {
            key: "currentEagle",
            value: data,
          });
          this.$store.commit("uavApplications/setState", {
            key: "showVideo",
            value: true,
          });
          // if (this.currentEagle && this.$uav) {
            // 鹰巢列表折叠
            this.$store.commit("SET_OPEN_LEFT_NAV_SILDER", false);
          // }
        } else {
          this.$message.warning("流配置未配置");
        }
      } else {
        this.$message.warning("鹰巢未在线");
      }
    },
    async getUavList() {
      const res = await Control_API.getUavNestList({
        name: this.search.name,
      });
      if (res.code === 200) {
        this.data = res.data;
        this.findList(this.data, "nests", "childs");
      }
    },
    findList(list, uavListName, childrenName) {
      try {
        list.forEach((element) => {
          if (
            element[uavListName] &&
            element[uavListName].length &&
            element.type !== uavListName
          ) {
            element[uavListName].forEach((e) => (e.type = uavListName));
            element[childrenName] = [
              ...element[uavListName],
              ...element[childrenName],
            ];
          }
          if (this.$uav && element[uavListName]) {
            // 实时更新无人机数据，currentOperator 接管人参数
            const newVal = element[uavListName].find(
              (e) => e.id === this.$uav.id
            );
            if (
              newVal &&
              newVal.currentOperator !== this.$uav.currentOperator
            ) {
              this.$store.commit("uavApplicationsEagleNest/setState", {
                key: "uav",
                value: {
                  ...this.$uav,
                  currentOperator: newVal.currentOperator,
                },
              });
            }
          }
          if (element[childrenName] && element[childrenName].length) {
            this.findList(element[childrenName], "nests", "childs");
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    // 鹰巢控制
    eagleNestOperate(data) {
      if (data.online === 1) {
        // 判断鹰巢是否配置流
        if (data.uav?.streamConfiguration) {
          this.$store.commit("uavApplications/setState", {
            key: "showVideo",
            value: true,
          });
        } else {
          this.$message.warning("流配置未配置");
        }
        this.eagleNestLink(data);
      } else {
        this.$message.warning("鹰巢未在线");
      }
    },
    // 鹰巢链接
    eagleNestLink(data) {
      if (this.$uav && this.$uav.hardId !== data.uav.hardId) {
        const hardId = this.$uav.hardId;
        // 判断上一台飞机,属于飞控中心还是地面站, 执行对应的取消订阅操作
        if (this.$uav.network === 1) {
          this.$store.dispatch("MMCMQTT/unsubscribe", {
            topic: "PX4/RECEIVE/" + hardId,
            callback() {},
          });
        } else {
          this.$store.dispatch("MMCGroundStation/unsubscribe", hardId);
        }
      }
      this.$store.commit("SET_OPEN_LEFT_NAV_SILDER", false);
      // 飞控中心链路-鹰巢
      this.$store.dispatch("MMCMQTT/subscribe", {
        topic: "APRON/RECEIVE/" + data.hardId,
        callback() {},
      });
      this.$store.dispatch("MMCMQTT/subscribe", {
        topic: "PROCESS/RECEIVE/" + data.hardId,
        callback() {},
      });
      this.$store.commit("uavApplicationsEagleNest/setState", {
        key: "currentEagle",
        value: data,
      });
      // 判断该鹰巢下是否有无人机
      if (data.uav) {
        if (data.uav.network === 1) {
          // 飞控中心链路
          this.$store.dispatch("MMCMQTT/subscribe", {
            topic: "PX4/RECEIVE/" + data.uav.hardId,
            callback() {},
          });
        } else {
          // 地面站链路
          this.$store.dispatch("MMCGroundStation/subscribe", data.uav.hardId);
        }
        this.$store.commit("uavApplications/setState", {
          key: "uav",
          value: data.uav,
        });
      }
      const device = data;
      if (device.latitude && device.longitude) {
        let viewer = window.viewer;
        if (locations) {
          viewer.entities.remove(locations);
        }
        let f = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(
            Number(device.longitude),
            Number(device.latitude)
          ),
          billboard: {
            image: require("./ycaddress.png"),
            width: 30,
            height: 40,
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });
        locations = f;
        // 保存选中的数据
        this.$store.commit("fckernel/set_state", {
          key: "active_nestData",
          value: device,
        });
        viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            Number(device.longitude),
            Number(device.latitude),
            30000
          ),
          maximumHeight: 10,
        });
      } else {
        // let viewer = window.viewer
        // locations.forEach((item, index) => viewer.entities.remove(item))
        // this.$el_message("该鹰巢没有经纬度", () => { }, "warning");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/list-common.scss";
.uav-control {
  height: 100%;
  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;
    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;
      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    &::v-deep {
      .el-input {
        margin: 0px 8px;
        border: 1px solid #233a58;
        border-radius: 10px;
        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }
  ::v-deep {
    .el-tree {
      box-sizing: content-box;
      height: calc(100% - 48px);
      overflow: auto;
      margin-right: 10px;
      color: #c6ccd4;
      font-size: 12px;
      background: none !important;
      .el-tree-node:after {
        border-top: none;
      }
      .el-tree-node {
        position: relative;
        padding-left: 16px;
        box-sizing: border-box;
        background: none;
        /*设置横向滚动条*/
        min-width: 100% !important;
        display: block !important;
        float: left;
        .el-tree-node__content {
          background: none;
          border: 1px solid transparent;
          padding-left: 0 !important;
          padding-top: 5px;
          &:hover .custom-tree-node {
            border: 1px solid #c6ccd4;
            background: none;
          }
        }
      }
      .is-current {
        .el-tree-node {
          .el-tree-node__content {
            &.custom-tree-node {
              border: 1px solid transparent;
              padding-left: 0 !important;
            }
            &:hover .custom-tree-node {
              border: 1px solid #c6ccd4;
              background: none;
            }
          }
        }
        .el-tree-node__content .custom-tree-node {
          border: 1px solid #c6ccd4;
        }
      }
      //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
      .el-tree-node__expand-icon.is-leaf {
        display: none;
      }
      .el-tree-node__children {
        padding-left: 16px;
      }

      .el-tree-node :last-child:before {
        height: 38px;
      }

      .el-tree-node:before {
        border-left: none;
      }

      .el-tree-node:after {
        border-top: none;
      }

      .el-tree-node:before {
        content: "";
        left: -4px;
        position: absolute;
        right: auto;
        border-width: 1px;
      }

      .el-tree-node:after {
        content: "";
        left: -4px;
        position: absolute;
        right: auto;
        border-width: 1px;
      }

      .el-tree-node:before {
        border-left: 1px solid #2c343c;
        bottom: 0px;
        height: 100%;
        top: -26px;
        width: 1px;
      }

      .el-tree-node:after {
        border-top: 1px solid #2c343c;
        height: 20px;
        top: 12px;
        width: 18px;
      }
      .el-tree-node__content > .el-tree-node__expand-icon {
        padding: 0;
      }
      .el-tree-node__expand-icon.expanded {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      .el-icon-caret-right:before {
        content: "\e723";
        font-size: 16px;
      }
      .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        content: "\e722";
        font-size: 16px;
      }
      .el-checkbox__inner {
        z-index: unset;
      }
    }
    .uav-title {
      display: flex;
      gap: 2px;
      align-items: center;
      overflow: hidden;
      width: 200px;
      .uav-title__icon {
        width: 16px;
        height: 16px;
        background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/uav.png");
        background-size: 100% 100%;
        flex-shrink: 0;
      }

      .uav-title__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ctrl-list {
      display: flex;
      gap: 2px;

      .ctrl-icon {
        width: 24px;
        height: 24px;

        &.icon--control {
          background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/control1.svg");
          background-size: 100% 100%;
        }
        &.icon--take-over-control {
          background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/take-over-control.png");
          background-size: 100% 100%;
        }
        &.icon--take-over-control-on {
          background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/take-over-control-on.png");
          background-size: 100% 100%;
        }
        &.icon--lib {
          background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/lib.png");
          background-size: 100% 100%;
        }
        &.icon--video {
          background-image: url("~@/views/uavApplications/components/flight-popup2/flight-uav-control/assets/video1.svg");
          background-size: 100% 100%;
        }
      }
    }
    .take-over {
      color: rgb(245, 154, 35);
      margin-left: 3px;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    //修改选择框的颜色
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #b3bbc5 !important;
      background-color: #16181b !important;
    }
    .el-checkbox__input .el-checkbox__inner {
      border-color: #b3bbc5 !important;
      background-color: #16181b !important;
    }

    .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #b3bbc5 !important;
    }
    //修改选中后文本的颜色
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #b3bbc5 !important;
    }
    .el-checkbox__inner::after {
      border-color: #50abff;
    }
  }
  .taskBox {
    position: fixed;
    left: 10%;
    top: 30%;
    width: 352px;
    height: 448px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
      inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 4px 0px #2e63dc;
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(52, 223, 255, 1),
        rgba(24, 113, 214, 0)
      )
      1 1;
    backdrop-filter: blur(4px);
    .head {
      box-sizing: border-box;
      width: 352px;
      height: 32px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);
      border-radius: 4px 4px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .left {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ebf5ff;
      }
      .right {
        color: #ebf5ff;
      }
    }
  }
  .taskDetails {
    top: 60%;
    padding: 8px;
    .body {
      padding: 10px 0;
      background: #2c313b;
      border-radius: 4px 4px 0px 0px;
      .item {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .label {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #b3bbc5;
          margin-right: 16px;
        }
        .value {
          box-sizing: border-box;
          width: 252px;
          height: 32px;
          background: #16181b;
          border-radius: 8px;
          border: 1px solid #233a58;
          font-size: 14px;
          color: #d8dde8;
          padding: 6px 12px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        .remark {
          box-sizing: border-box;
          width: 252px;
          height: 67px;
          background: #16181b;
          border-radius: 8px;
          font-size: 14px;
          border: 1px solid #233a58;
          color: #d8dde8;
          padding: 6px 12px;
        }
      }
    }
  }
}
</style>
