<script>
import moment from 'moment';

export default {
  name: 'Index',
  props: {
    tabPosition: {
      type: String,
      default: () => 'month'
    }
  },
  data() {
    return {
      searchDate: ''
    };
  },
  methods: {
    radioChangeEvent(value) {
      this.$emit('tabChangeEvent', value);
    },
    searchEvent() {
      if (this.searchDate) {
        const obj = {
          startDate: moment(this.searchDate[0]).format('YYYY-MM-DD'),
          endDate: moment(this.searchDate[1]).format('YYYY-MM-DD')
        };
        this.$emit('searchSubmit', obj);
      } else {
        this.$emit('tabChangeEvent', 'month');
      }
    }
  }
};
</script>

<template>
  <div class="header-search">
    <div class="date-select">
      <el-radio-group v-model="tabPosition" size="small" @input="radioChangeEvent">
        <el-radio-button label="month">本月</el-radio-button>
        <el-radio-button label="week">本周</el-radio-button>
        <el-radio-button label="today">今日</el-radio-button>
      </el-radio-group>
    </div>
    <div class="day-select">
      <el-form label-width="80px">
        <el-form-item label="日期">
          <el-date-picker
            v-model="searchDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 250px; background-color: #000"
          />
        </el-form-item>
      </el-form>
    </div>
    <div class="search-operate">
      <el-button type="primary" size="small" @click="searchEvent">搜索</el-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header-search {
  height: 47px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: rgb(20, 22, 34);
  border-radius: 5px 5px 0 0;
  .search-operate {
    margin-left: 10px;
  }
}
</style>
