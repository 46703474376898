var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flight-task-list"},[_c('div',{staticClass:"search_box task"},[_c('div',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"btn_create",on:{"click":function($event){return _vm.change_active(4)}}},[_c('span',{staticClass:"iconfont icon-xinzengtaizhang"}),_c('span',[_vm._v("任务创建")])]),_c('el-input',{attrs:{"placeholder":"请输入任务名称","prefix-icon":"el-icon-search"},model:{value:(_vm.taskText),callback:function ($$v) {_vm.taskText=$$v},expression:"taskText"}}),_c('el-select',{attrs:{"placeholder":"请选择执行状态"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('div',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"btn_search",on:{"click":_vm.goSearch}},[_vm._v("查询")])],1),_c('div',{staticClass:"tb-box"},[_vm._m(0),_c('div',{staticClass:"tb-bd-box"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"tb-tr"},[_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(item.taskName || '暂无')+" ")]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(item.routeName || '暂无')+" ")]),_c('div',{staticClass:"td",style:({ color: item.taskStatus == 2 ? '#FA8100 ' : '#C6CCD4' })},[_vm._v(" "+_vm._s(_vm.taskTypeName(item.taskStatus) || '暂无')+" ")]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(item.organizationName || '暂无')+" ")]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(item.createWhen || '暂无')+" ")]),_c('div',{staticClass:"td",style:({
            opacity: item.deviceStatus == 1 ? '1' : '0.5',
            color: item.deviceStatus == 1 ? '#a4ff64' : ''
          })},[_vm._v(" "+_vm._s(item.deviceName || '暂无')+" ")]),_c('div',{staticClass:"td"},[_c('el-tooltip',{directives:[{name:"hover",rawName:"v-hover"}],attrs:{"effect":"dark","content":"编辑","placement":"top"}},[_c('div',{staticClass:"iconfont icon-bianji",on:{"click":function($event){return _vm.goEdit(item)}}})]),_c('el-tooltip',{directives:[{name:"hover",rawName:"v-hover"}],attrs:{"effect":"dark","content":"详情","placement":"top"}},[(item.taskStatus == 2 || item.taskStatus == 3)?_c('div',{staticClass:"iconfont icon-chakan",style:({
                cursor:
                  item.taskStatus == 2 || item.taskStatus == 3 ? 'pointer' : ['not-allowed'],
                opacity: item.taskStatus == 2 || item.taskStatus == 3 ? '1' : '0.5'
              }),on:{"click":function($event){return _vm.goControlBar(item)}}}):_vm._e()]),_c('el-tooltip',{directives:[{name:"hover",rawName:"v-hover"}],attrs:{"effect":"dark","content":"执行","placement":"top"}},[(item.taskStatus == 1)?_c('div',{staticClass:"iconfont icon-zhihang",style:({
                cursor: item.taskStatus == 1 ? 'pointer' : ['not-allowed'],
                opacity: item.taskStatus == 1 ? '1' : '0.5'
              }),on:{"click":function($event){return _vm.goExecute(item)}}}):_vm._e()]),_c('el-tooltip',{attrs:{"effect":"dark","content":"删除","placement":"top"}},[_c('div',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"iconfont icon-shanchu",class:{ disable: item.taskStatus != 1 },on:{"click":function($event){return _vm.deleteItem(item)}}})])],1)])}),0)]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next, jumper","total":_vm.pageTotal,"page-size":_vm.page.size},on:{"current-change":_vm.handleCurrentChange}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tb-hd-box"},[_c('div',{staticClass:"tb-hd"},[_vm._v("任务名称")]),_c('div',{staticClass:"tb-hd"},[_vm._v("航线名称")]),_c('div',{staticClass:"tb-hd"},[_vm._v("执行状态")]),_c('div',{staticClass:"tb-hd"},[_vm._v("责任单位")]),_c('div',{staticClass:"tb-hd"},[_vm._v("创建时间")]),_c('div',{staticClass:"tb-hd"},[_vm._v("无人机")]),_c('div',{staticClass:"tb-hd"},[_vm._v("操作")])])}]

export { render, staticRenderFns }