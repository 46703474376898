<template>
  <div class="project-create">
    <div v-if="!readonly" class="header">
      <span class="title">{{ editData ? '项目编辑' : '项目创建' }}</span>
    </div>
    <div class="body">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="项目名称" prop="projectName" required="">
          <el-input
            v-model="form.projectName"
            :placeholder="readonly ? '' : '请输入项目名称'"
            clearable
            :readonly="readonly"
          />
        </el-form-item>
        <el-form-item label="项目描述" style="margin-top: 15px">
          <!-- <el-input type="textarea"
                    :rows="5"
                    placeholder="任务描述"
                    v-model="form.textarea"
                    :show-word-limit="true"
                    maxlength=64
                    :autosize="{ minRows: 5, maxRows: 5 }">
          </el-input> -->
          <el-input
            v-model="form.textarea"
            :placeholder="readonly ? '' : '请输入项目简介'"
            clearable
            :readonly="readonly"
          />
        </el-form-item>
        <el-form-item v-if="!readonly" label="中心点">
          <div class="required">*</div>
          <div v-hover class="draw-btn" :class="{ active: isDraw }" @click="setCenterPoint">
            <span>设置项目中心点</span>
          </div>
        </el-form-item>
        <el-form-item label="经度："
          ><span>{{ lng + '°' || '暂无' }}</span></el-form-item
        >
        <el-form-item label="纬度："
          ><span>{{ lat + '°' || '暂无' }}</span></el-form-item
        >
        <div class="form_btn">
          <template v-if="readonly">
            <div class="btn_back" @click="$emit('back')">返回项目列表</div>
          </template>
          <template v-else>
            <div class="btn_create" @click="addProject">创建</div>
            <div class="btn_cancel" @click="cancel">取消</div>
          </template>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { projectAPI } from '@/api';
import { Utils } from '@/utils/cesium';
import { mapState } from 'vuex';
let dataSrouce, handler;
export default {
  name: 'MmcVueTianmuIndex',

  props: {
    editData: {
      type: Object,
      default() {
        return null;
      }
    },
    // 预览模式，不能更改和提交
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const validateTaskName = (rule, value, callback) => {
      const regEn = /[`~!@#$%^&*_+<>?:"{},.\/;'[\]]/im.test(value);
      if (!value || !value.trim()) {
        callback(new Error('请输入项目名！'));
      } else if (regEn) {
        callback(new Error('不能输入特殊符号！'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        projectName: [{ validator: validateTaskName, trigger: ['change'] }]
      },
      form: {
        projectName: '',
        textarea: ''
      },
      lng: '113',
      lat: '22',
      isDraw: false
    };
  },

  computed: {
    ...mapState(['isCesium']),
    isEdit() {
      return !!this.editData;
    }
  },

  watch: {
    editData: {
      immediate: true,
      handler() {
        if (this.editData) {
          this.form.projectName = this.editData.projectName;
          this.form.textarea = this.editData.projectDescript;
          this.lng = this.editData.longitude;
          this.lat = this.editData.latitude;
        }
      }
    }
  },

  mounted() {},

  methods: {
    async setCenterPoint() {
      this.isDraw = !this.isDraw;
      console.log('this.isDraw...', this.isDraw);
      if (this.isCesium) {
        if (this.isDraw) {
          const viewer = window.viewer;

          viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
            Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
          );

          // if (!dataSrouce) {
          //   dataSrouce = new Cesium.CustomDataSource("center_point")
          //   await viewer.dataSources.add(dataSrouce)
          // }

          handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
          handler.setInputAction(async (event) => {
            // let windowPosition = event.position;
            const position = Utils.getCartesian3FromPX(viewer, event.position);
            const { latitude, longitude } = this.cartesianToLatLng(position);
            this.lng = longitude;
            this.lat = latitude;
          }, Cesium.ScreenSpaceEventType.LEFT_DOWN);
        } else {
          handler = handler && handler.destroy();
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请切换至三维地图！',
          duration: 1000
        });
      }
    },
    cartesianToLatLng(cartesian) {
      const cartographic = Cesium.Cartographic.fromCartesian(
        cartesian,
        viewer.scene.globe.ellipsoid,
        new Cesium.Cartographic()
      );
      const latitude = Cesium.Math.toDegrees(cartographic.latitude);
      const longitude = Cesium.Math.toDegrees(cartographic.longitude);
      const altitude = cartographic.height;
      return { latitude, longitude, altitude };
    },
    addProject() {
      const userInfo = JSON.parse(window.localStorage.getItem('user_info'));
      const data = {
        accountId: userInfo.id,
        latitude: this.lat,
        longitude: this.lng,
        projectDescript: this.form.textarea,
        projectName: this.form.projectName
      };
      this.$refs['form'].validate((validate) => {
        if (validate) {
          projectAPI.addProject({ ...data }).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '项目创建成功！',
                duration: 1000
              });
              this.$bus.$emit('roloadProjectList');
              this.$bus.$emit('closeSideList');
            } else if (res.code == 500) {
              this.$message({
                type: 'warning',
                message: '项目名称重复！',
                duration: 1000
              });
            }
          });
        }
      });
    },
    cancel() {
      this.$bus.$emit('closeSideList');
    }
  }
};
</script>

<style lang="scss" scoped>
.project-create {
  // width: 439px;
  height: 100%;
  border-radius: 4px;
  margin-top: -30px;
  position: relative;
  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    border-radius: 4px 4px 0 0;
    margin-left: 10px;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }
    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .body {
    height: calc(100% - 105px);
    // width: 317px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 10px;
    padding-right: 20px;
    border-bottom: 1px solid #23364b;
    .form_btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-family: MicrosoftYaHei;
      position: absolute;
      bottom: 14px;
      width: 100%;
      .btn_create,
      .btn_cancel,
      .btn_back {
        box-sizing: border-box;
        width: 92px;
        height: 32px;
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }

      .btn_back {
        margin-top: -110px;
      }
    }
  }

  .el-form {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #b3bbc5;
    letter-spacing: 0;
    font-weight: 400;
    &::v-deep {
      .el-form-item__error {
        padding-top: 0px;
        position: absolute;
        bottom: -12px;
        margin-left: 15px;
      }
      .el-input__count {
        background: none;
        position: absolute;
        right: 16px;
        bottom: -7px;
      }
      .el-form-item {
        margin-bottom: 16px;
        .required {
          color: #ed6a6a;
          width: 4px;
          position: absolute;
          left: -29%;
        }
        .el-form-item__label {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #b3bbc5;
          width: 100px;
        }
        .el-input {
          // width: 227px;
          height: 32px;
          line-height: 32px;
          border: 1px solid #233a58;
          border-radius: 8px;
          .el-input__inner {
            background: #16181b;
            height: 100%;
            width: 100%;
            border-radius: 8px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            // color: #7f828a;
            color: #fff;
            letter-spacing: 0;
            font-weight: 400;
          }
          .el-input__icon {
            line-height: 32px;
            color: #1eb9fa;
          }
        }
        .el-textarea__inner {
          margin-top: 4px;
          width: 227px;
          height: 100px;
          max-height: 100px;
          min-height: 100px;
          background: #16181b;
          border-radius: 8px;
          border: none;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #c6ccd4;
          letter-spacing: 0;
          font-weight: 400;
        }
        .draw-btn {
          background: #3a4252;
          border: 1px solid #3bc1e5;
          border-radius: 4px;
          width: 142px;
          height: 32px;
          // line-height: 32px;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #f8f8f8;
          letter-spacing: 0;
          font-weight: 400;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            height: 32px;
            line-height: 34px;
            text-align: center;
          }
          &.active {
            background: #326789;
          }
        }
        .el-button {
        }
      }
    }
  }
}
</style>
