var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"airway-popup"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"tabs"},[_c('span',{staticClass:"tab_box left",class:{ active: _vm.active == 0 },on:{"click":function($event){return _vm.handle_tab_click(0)}}},[_vm._v("规划航线")]),_c('span',{staticClass:"tab_box right",class:{ active: _vm.active == 1 },on:{"click":function($event){return _vm.handle_tab_click(1)}}},[_vm._v("导入航线")])]),_c('span',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"iconfont icon-guanbi",on:{"click":_vm.close}})]),(_vm.active == 0)?_c('div',{staticClass:"content_box left"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"85px"}},[_c('el-form-item',{attrs:{"label":"航线名称","prop":"name"}},[_c('div',{staticClass:"required"},[_vm._v("*")]),_c('el-input',{attrs:{"clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"责任机构","prop":"dutyOrganizationId"}},[_c('el-cascader',{attrs:{"placeholder":"请选择责任机构","show-all-levels":false,"options":_vm.orgOptions,"props":{
              value: 'id',
              label: 'name',
              children: 'childNodes',
              checkStrictly: true,
              emitPath: false,
            }},model:{value:(_vm.form.dutyOrganizationId),callback:function ($$v) {_vm.$set(_vm.form, "dutyOrganizationId", $$v)},expression:"form.dutyOrganizationId"}})],1),_c('el-form-item',{attrs:{"label":"默认高度","prop":"alt"}},[_c('el-input-number',{ref:"height",attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.alt),callback:function ($$v) {_vm.$set(_vm.form, "alt", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.alt"}}),_vm._v(" m ")],1),_c('el-form-item',{attrs:{"label":"默认速度","prop":"speed"}},[_c('el-input-number',{ref:"speed",attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.speed),callback:function ($$v) {_vm.$set(_vm.form, "speed", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.speed"}}),_vm._v(" m/s ")],1),_c('el-form-item',{attrs:{"label":"默认偏航角","prop":"yaw"}},[_c('el-input-number',{ref:"yaw",attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.yaw),callback:function ($$v) {_vm.$set(_vm.form, "yaw", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.yaw"}}),_vm._v(" (°) ")],1),_c('el-form-item',{attrs:{"label":"默认俯仰角","prop":"pitch"}},[_c('el-input-number',{ref:"pitch",attrs:{"controls":false,"clearable":""},model:{value:(_vm.form.pitch),callback:function ($$v) {_vm.$set(_vm.form, "pitch", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.pitch"}}),_vm._v(" (°) ")],1),_c('el-form-item',{attrs:{"label":"绘制航点","required":""}},[_c('el-button',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"draw-btn",class:{
              active: _vm.isDraw,
            },on:{"click":_vm.editPoint}},[_c('div',{staticClass:"iconfont icon-shengchenghangxian"},[_vm._v(" "+_vm._s(_vm.pointsInfo.length)+" ")])])],1)],1)],1):_c('div',{staticClass:"content_box right"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_vm._m(0),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileRef",attrs:{"id":"inppp","type":"file","multiple":""},on:{"change":_vm.fileChange}}),_c('div',{staticClass:"hint"},[_vm._v("（支持上传json,kml格式航线）")])]),_c('el-button',{directives:[{name:"hover",rawName:"v-hover"}],on:{"click":_vm.uploadFile}},[_vm._v("+ 选择")])],1),_c('div',{staticClass:"fileList"},_vm._l((_vm.File),function(item,index){return _c('li',{key:index},[_c('i',{staticClass:"el-icon-document"}),_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',{staticClass:"footer"},[_c('button',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"left btn",on:{"click":_vm.sava}},[_vm._v("保存航线")]),_c('button',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"right btn",on:{"click":_vm.reset}},[_vm._v("重置")])])]),_c('div',[(_vm.isShowWaypointPopup)?_c('WaypointPopup',{attrs:{"uuid":_vm.uuid,"point-info":_vm.pointInfo,"points-info":_vm.pointsInfo,"current-point-num":_vm.currentPointNum},on:{"waypointPopupClose":_vm.waypointPopupClose,"changePointInfo":_vm.changePointInfo,"deletePointInfo":_vm.deletePointInfo}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v(" 选择航线 "),_c('span',{staticClass:"symbol"},[_vm._v("*")])])}]

export { render, staticRenderFns }