<template>
  <div class="wih100 ht100">
    <div class="" :class="!model ? 'maxStyle' : 'smallVideo'">
      <!-- <video
        id="videoPlayMap"
        class="video-js wih100 ht100 vjs-default-skin"
        preload="auto"
      >
        <source :src="videoList[videoIndex].playUrl" type="video/mp4" />
      </video> -->
      <!-- type="application/x-mpegURL" -->
    </div>
    <div
      class="cpt-cesium-layer-flight-log"
      :class="model ? 'maxStyle' : 'smallVideo'"
      @mouseenter="CesiumLayerMouseEnter"
      @mouseleave="CesiumLayerMouseLeave"
    >
      <div id="layer-container-flight-log" />
    </div>
    <div>
      <flightData :fly-time-data="flyTimeData" class="bottom0 pa" />
    </div>
    <!-- 视频按钮控制-->
    <div v-if="flyData.length" class="videoConroller cf pl10 pr10">
      <div class="jcsb progress-play">
        <div class="play-control">
          <img
            :src="isPlay ? require('./img/suspend.png') : require('./img/play.png')"
            @click="controlVideoEvent"
          />
        </div>
        <el-slider
          v-model="currentStep"
          style="width: 88%; margin-right: 5px"
          :max="flyData.length"
          :format-tooltip="covertTime"
        ></el-slider>
        <span>{{ currentDuration }}/{{ totalDuration }}</span>
      </div>
      <div class="wih100 jcsb">
        <div class="cp wih100 tc">
          <div>
            <div class="cf fr">
              倍数:
              <el-select v-model="currentMultiple" class="w70" placeholder="请选择">
                <el-option
                  v-for="item in multipleSelect"
                  :key="item"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <!--            <div-->
            <!--              class="icon-xinximoshi iconfont w50 h50 f22 fr"-->
            <!--              @click="model = !model"-->
            <!--            />-->
          </div>
        </div>
      </div>
    </div>
    <div class="pa bottom0 wih100 timeBtn" style="z-index: 10">
      <div
        v-for="(item, index) in timeArr"
        :key="index"
        class="timeStyle tc"
        :class="videoConroller.startTime == item.num ? 'active' : 'default'"
        @click="jumpTimeEvent(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <!-- 日志信息 -->
    <div class="w300 h700 flightLogBox cf top0 pa">
      <!--      <div class="btnBox">-->
      <!--        <JsonExcel-->
      <!--          :data="dataSourceList"-->
      <!--          :fetch="fetch"-->
      <!--          :fields="json_fields"-->
      <!--          name="统计.xls"-->
      <!--        >-->
      <!--          <div class="f14 btn mb20">日志导出</div>-->
      <!--        </JsonExcel>-->
      <!--        &lt;!&ndash; <div class="f14 btn mb20" @click="videoFn">视频导出</div> &ndash;&gt;-->
      <!--      </div>-->
      <div class="flightLogInfor f14">
        <div>
          任务名称: <span class="aqua">{{ trajectoryData.taskName }}</span>
        </div>
        <div>
          飞行开始时间: <span class="aqua">{{ trajectoryData.startTime }}</span>
        </div>
        <div>
          飞行结束时间: <span class="aqua">{{ trajectoryData.endTime }}</span>
        </div>
        <div>
          飞行架次号:<span class="aqua">{{ FilghtMapData.flightSortieName }}</span>
        </div>
        <div>
          经度: <span class="aqua">{{ flyTimeData.longitude }}</span>
        </div>
        <div>
          纬度: <span class="aqua">{{ flyTimeData.latitude }}</span>
        </div>
        <div>
          飞行速度:<span class="aqua">{{ isNANfn(flyTimeData.groundSpeed) }}</span
          ><span class="ml2">m/s</span>
        </div>
        <div>
          爬升率:<span class="aqua">{{ isNANfn(flyTimeData.climbRate) }}</span
          ><span class="ml2">m/s</span>
        </div>
        <div>
          偏航角度:<span class="aqua">{{ isNANfn(flyTimeData.yaw) }}</span
          ><span class="ml2">度</span>
        </div>
        <div>
          俯仰角度:<span class="aqua">{{ isNANfn(flyTimeData.pitch) }}</span
          ><span class="ml2">度</span>
        </div>
        <div>
          翻滚角度:<span class="aqua">{{ isNANfn(flyTimeData.roll) }}</span
          ><span class="ml2">度</span>
        </div>
        <div>
          飞行里程:
          <span class="aqua">{{ isNANfn(flyTimeData.flightDistance) }}</span
          ><span>/m</span>
        </div>
        <div>
          起点距离:
          <span class="aqua">{{ isNANfn(flyTimeData.distanceToHome) }}</span
          ><span>/m</span>
        </div>
        <div>
          飞行时长:<span class="aqua">{{ flyTimeDataFn(flyTimeData.flightTime) }}</span
          ><span class="ml2">/min</span>
        </div>
        <div>
          飞行高度:<span class="aqua">{{ isNANfn(flyTimeData.height) }}</span
          ><span class="ml2">/m</span>
        </div>
        <div>
          海拔高度: <span class="aqua">{{ isNANfn(flyTimeData.altitude) }}</span
          ><span>/m</span>
        </div>
        <div>
          当前电压:<span class="aqua">{{ isNANfn(flyTimeData.voltage) }}</span
          ><span class="ml4">v</span>
        </div>
        <div>
          电池电量: <span class="aqua">{{ flyTimeData.battaryRemain }}</span
          ><span class="ml4">%</span>
        </div>

        <div>信号值: <span class="aqua"></span></div>
        <div>
          当前时间:
          <span class="aqua">{{ moment(dateTime).format('YYYY-MM-DD HH:mm:ss') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

let aa;
const plate_data_source = null;
import { Utils } from '@/utils/cesium';
import { mapGetters, mapState } from 'vuex';
import flightData from './flightData/index.vue';
import { AirLine } from '@/api';
import videojs from 'video.js';
import 'videojs-contrib-hls';
import '@videojs/http-streaming';
import methods from './methods/index';
import dayjs from 'dayjs';
import JsonExcel from 'vue-json-excel';
const models = [];
let viewer;
export default {
  props: {
    cameraPosition: {
      type: String,
      default: ''
    },
    FilghtMapData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    moment() {
      return moment;
    },
    ...mapGetters(['my_layers_data']),
    ...mapState(['mapMode']),
    $uavLog() {
      return this.$store.state.uavApplications.uavLog;
    }
  },
  components: { flightData, JsonExcel },
  data() {
    return {
      imageryLayers: {
        night: null, // 夜景图
        satellite: null, // 卫星图
        text: null // 地名标记
      },
      model: true,
      dataSourceList: [],
      json_fields: {
        序号: 'id', // 常规字段
        // "无人机名称": "deviceName",
        '飞行架次号(次)': 'flightSortieName',
        飞行时间: 'flightDate',
        任务名称: 'taskTitle',
        '飞行里程(km)': 'flightDistance',
        飞行时长: 'flightTime'
        // "取证上传图片数": "photoCount",
        // "AI预警数": "aiCount",
      },
      timeArr: [],
      options: {
        autoplay: true, // 自动播放
        controls: true, // 用户可以与之交互的控件
        loop: false, // 视频一结束就重新开始
        muted: true, // 默认情况下将使所有音频静音
        playsinline: true,
        webkitPlaysinline: true,
        // aspectRatio:"16:9",//显示比率
        playbackRates: [0.5, 1, 1.5, 2],
        language: 'zh-CN',
        fullscreen: {
          options: { navigationUI: 'hide' }
        }
      },
      bs: 1,
      VUE_APP_IP: null,
      entity: null,
      percentage: 0,
      customColors: [
        { color: '#79CFFF', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      videoConroller: {
        startTime: '00:00',
        endTime: '00:00',
        status: {
          play: require('./img/play.png'),
          suspend: require('./img/suspend.png'),
          next: require('./img/next.png'),
          previous: require('./img/previous.png')
        }
      },
      startTime: '',
      type: 'play',
      // 模型
      modelData: {
        model: null,
        orientation: null,
        position: null,
        positions: [],
        entity_id: '', // modelid
        polyline_id: '' // 航线id
      },
      // 飞机关联时间的数据
      modelDataTime: {
        //   关联时间的无人机位置信息
        positionTime: [],
        // 关联时间的无人机姿态信息
        orientationTime: [],
        // 关联时间的飞行数据
        flyTimeData: []
      },
      flyTimeData: {},
      flyData: [],
      // 视频列表
      videoList: [
        {
          url: ''
        }
      ],
      // 視頻索引
      videoIndex: 0,
      markers: [],
      // 無人機視頻
      player: null,
      modeTimer: null,
      totalDuration: 0,
      currentDuration: 0,
      currentStep: 0,
      multipleSelect: [
        {
          label: 'x1',
          value: 1
        },
        {
          label: 'x2',
          value: 2
        }
      ],
      currentMultiple: 1,
      isPlay: true, // 播放还是暂停
      trajectoryData: {}
    };
  },
  watch: {
    mapMode(newVal) {
      console.log(this.imageryLayers);
      switch (newVal) {
        case 0:
          this.imageryLayers.satellite.show = true;
          this.imageryLayers.night.show = false;
          this.imageryLayers.text.show = true;
          break;

        case 1:
          this.imageryLayers.satellite.show = false;
          this.imageryLayers.night.show = true;
          this.imageryLayers.text.show = false;
          break;
      }
    }
  },
  mounted() {
    const { VUE_APP_IP } = process.env;
    this.VUE_APP_IP = VUE_APP_IP;
    this.init_viewer();
    // this.init_plate()
    this.filghtInit();
    // this.getHistoryStreamFn();
    this.excelFn();
  },
  beforeDestroy() {
    const entities = viewer.entities;
    const model = entities.getById(`flight_model_${this.FilghtMapData.deviceHardId}`);
    if (model) {
      viewer.entities.remove(model);
    }
    this.CesiumLayerMouseLeave();
    if (this.player != null) {
      this.player.dispose(); // dispose()会直接删除Dom元素
    }
    viewer && viewer.destroy();
    viewer = null;
    if (this.modeTimer) {
      clearInterval(this.modeTimer);
    }
  },
  methods: {
    ...methods,
    fetch() {
      const recordsAll = JSON.parse(JSON.stringify(this.dataSourceList));
      recordsAll.map((item) => {
        item.flightSortieName = `'${item.flightSortieName}'`;
      });
      return recordsAll;
    },
    videoFn() {
      // const a = document.createElement("a");
      // a.href = URL.createObjectURL(this.videoList[this.videoIndex].storage);
      // a.download = `${this.FilghtMapData.taskTitle}.mp4`;
      // a.click();
      // a.remove();

      fetch(this.videoList[this.videoIndex].storage)
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement('a');
          const objectUrl = window.URL.createObjectURL(blob);
          a.download = `飞行日志.mp4`;
          a.href = objectUrl;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        });
    },
    getMin(num) {
      if (num) {
        return (num / 60).toFixed(2);
      }
      return 0;
    },
    async excelFn() {
      this.dataSourceList = [
        {
          ...this.FilghtMapData
          // flightTime: this.getMin(this.FilghtMapData.flightTime)
        }
      ];
      console.log(this.dataSourceList, 'dataSourceListdataSourceListdataSourceList');
      // let parmas = {
      //     deviceHardId: this.FilghtMapData.deviceHardId,
      //     pageNum: 1,
      //     pageSize: 10000,
      // }
      // const res = await API.TASK.getSorties(parmas)
      // let _this = this
      // if (res.status == 1) {

      //     this.dataSourceList = res.data.data.sorties.map(item => {
      //         return (
      //             {
      //                 ...item,
      //                 flightTime: _this.getMin(item.flightTime)
      //             }
      //         )
      //     })

      // }
    },
    flyTimeDataFn(time) {
      if (time) {
        return Number(time / 60).toFixed(2) || 0;
      } else {
        return 0;
      }
    },
    dateAdd(d, num) {
      var d = new Date(
        d.substring(0, 4),
        d.substring(5, 7) - 1,
        d.substring(8, 10),
        d.substring(11, 13),
        d.substring(14, 16),
        d.substring(17, 19)
      );
      d.setTime(d.getTime() + num * 1000);
      return (
        d.getFullYear() +
        '-' +
        this.checkTime(d.getMonth() + 1) +
        '-' +
        this.checkTime(d.getDate()) +
        ' ' +
        this.checkTime(d.getHours()) +
        ':' +
        this.checkTime(d.getMinutes()) +
        ':' +
        this.checkTime(d.getSeconds())
      );
    },
    isNANfn(data) {
      if (data && data === data && data !== 'NaN') {
        return Number(data).toFixed(2) || 0;
      } else {
        return 0;
      }
    },
    checkTime(i) {
      if (i < 10) {
        i = '0' + i;
      }
      return i;
    },
    async getHistoryStreamFn() {
      // "2022-10-21 15:52:04"
      const { FilghtMapData } = this;
      const res = await AirLine.getSortiesHistoryVideo({
        id: FilghtMapData.id,
        deviceHardId: this.$uavLog.hardId,
        page: 1,
        pageSize: 10
      });
      if (res.code == 200) {
        this.videoList = res.data.records;
        console.log(this.videoList, 'this.videoList');
        this.init();
      }
      let time = null;
      try {
        const startTime = this.videoList[this.videoIndex].videoStartEnd;
        time = JSON.parse(startTime)[0].startTime;
        this.startTime = time;
      } catch (error) {}
    },
    async filghtInit() {
      const { FilghtMapData } = this;
      const res = await AirLine.getSortiesTrajectory({
        flightSortie: FilghtMapData.flightSortieName
      });
      if (res.code == 200) {
        try {
          this.trajectoryData = res.data;
          this.flyData = res.data.deviceLogs;
          this.totalDuration = this.covertTime(this.flyData.length);
          this.covertTimePeriod();
          if (this.flyData[0].longitude != 'nan') {
            const position = Cesium.Cartesian3.fromDegrees(
              this.flyData[0].longitude * 1,
              this.flyData[0].latitude * 1,
              this.flyData[0].altitude * 1
            );
            this.modelData.position = position;
            this.flyTimeData = this.flyData[0];
            for (let i = 0; i < this.flyData.length; i++) {
              const data = this.trans_mqtt_location_data(this.flyData[i]);
              const time = parseInt(this.flyData[i].dateTime / 1000);
              this.modelDataTime.positionTime[time] = data.position;
              this.modelDataTime.orientationTime[time] = data.orientation;
              this.modelDataTime.flyTimeData[time] = this.flyData[i];
              this.modelData.positions.push(data.position);
            }
            this.playVideo();
            this.init_Model(FilghtMapData.deviceHardId);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    covertTime(val) {
      return (
        (Math.floor(val / 60) >= 10 ? Math.floor(val / 60) : `0${Math.floor(val / 60)}`) +
        `:${val % 60 >= 10 ? val % 60 : `0${val % 60}`}`
      );
    },
    init_Model(deviceHardId) {
      const entities = viewer.entities;
      const model = entities.getById(`flight_model_${deviceHardId}`);
      const { modelData, flyData } = this;
      if (!model) {
        modelData.entity_id = `flight_model_${deviceHardId}`;
        const model = this.add_model(entities, modelData, flyData[0]);
        viewer.camera.flyTo({
          destination: model.position.getValue(Cesium.JulianDate.now()),
          maximumHeight: 100,
          complete: () => {
            // 锁定
            viewer.trackedEntity = model;
          }
        });
        model.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
        models.push(model);
      }
      // let polyline = entities.getById(`accident_polyline_${deviceHardId}`)
      // if (!polyline) {
      //     let line = this.add_polyline(entities, modelData)
      //     modelData.polyline_id = line
      // }
    },
    add_model(entities, data, item) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        position: new Cesium.CallbackProperty(() => data.position, false),
        // position: data.position,
        model: {
          uri: 'cesium/model/1800_20220816_no_effect283g2color.gltf',
          minimumPixelSize: 50,
          maximumScale: 20000,
          scale: 1
          // colorBlendMode: Cesium.ColorBlendMode['MIX']
        },
        longitude: {
          type: 28,
          lon: item.longitude == 'nan' ? '' : null,
          lat: item.latitude == 'nan' ? '' : null,
          height: 0
          // data: item
        },
        polyline: {
          positions: new Cesium.CallbackProperty(() => data.positions, false),
          material: Cesium.Color.GREEN.withAlpha(0.9),
          width: 5
        }
      });
    },
    add_polyline(entities, data) {
      return viewer.entities.add({
        id: data.polyline_id,
        parent: data.parent || null,
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return data.positions;
          }, false),
          width: 5,
          show: true,
          material: Cesium.Color.fromCssColorString('#6DD400'),
          ...(data.options ? data.options : {})
        }
      });
    },
    trans_mqtt_location_data(uav_data) {
      if (!uav_data) {
        return uav_data;
      }
      // 获取航线
      const position = Cesium.Cartesian3.fromDegrees(
        uav_data.longitude * 1,
        uav_data.latitude * 1,
        uav_data.altitude * 1
      );
      const positions = [];
      const yaw = Cesium.Math.toRadians(uav_data.yaw + 90);
      const pitch = Cesium.Math.toRadians(uav_data.pitch);
      const roll = Cesium.Math.toRadians(uav_data.roll);
      const hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll);
      const orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr);
      if (this.modelData.model) {
        const model = this.modelData.model;
        const curPos = model.position.getValue(Cesium.JulianDate.now());
        if (curPos) {
          const curCart = new Cesium.Cartesian3(curPos.x, curPos.y, curPos.z);
          const afterCart = new Cesium.Cartesian3(position.x, position.y, position.z);
          if (curCart.equals(afterCart)) {
          } else {
            // 航线数据
            positions.push(position);
          }
        } else {
        }
      }

      return {
        position,
        positions,
        orientation
      };
    },
    init_viewer() {
      // 设置相机初始位置为中国上空
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = Cesium.Rectangle.fromDegrees(90, -20, 110, 90);
      Cesium.Credential.CREDENTIAL = new Cesium.Credential(
        'r0G6d8tdvfgN_Aad1j28SGqj6ILgt1DNHpP9dK-FNVOVBPgcyaHiEs2z2qyv455bTHU8QaFTbt_Y_AdeAsPp-Q..'
      );

      viewer = new Cesium.Viewer('layer-container-flight-log', {
        animation: false, // 动画控制不显示
        shouldAnimate: true,
        timeline: false, // 时间线不显示
        fullscreenButton: false, // 全屏按钮不显示
        creditContainer: document.createElement('div'),
        geocoder: false,
        homeButton: false,
        sceneModePicker: false, // 切换球形和平面型
        baseLayerPicker: false,
        navigationHelpButton: false,
        flightLogBox: false,
        selectionIndicator: false,

        requestRenderMode: true, // 启用请求渲染模式
        scene3DOnly: false, // 每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, // 初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode

        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
        // }),
        imageryProvider:
          process.env.NODE_ENV === 'development'
            ? new Cesium.UrlTemplateImageryProvider({
                url: process.env.VUE_APP_BASE_DARK_MAP
              })
            : new Cesium.ArcGisMapServerImageryProvider({
                url: '/arcgis/rest/services/World_Imagery/MapServer'
              }),
        orderIndependentTranslucency: false,
        contextOptions: {
          webgl: {
            alpha: true
          }
        }
      });
      this.imageryLayers.text = viewer.imageryLayers.addImageryProvider(
        new Cesium.WebMapTileServiceImageryProvider({
          url: 'https://{s}.tianditu.gov.cn/cia_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=a5e0a8dcf07f40afe19b76bf8a32eef0',
          layer: 'cia',
          style: 'default',
          format: 'tiles',
          tileMatrixSetID: 'c',
          credit: new Cesium.Credit('天地图全球影像服务'),
          tilingScheme: new Cesium.GeographicTilingScheme(), // 采用经纬度投影
          subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
          tileMatrixLabels: [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18'
          ],
          maximumLevel: 18
        })
      );

      this.imageryLayers.satellite = viewer.imageryLayers.addImageryProvider(
        new Cesium.ArcGisMapServerImageryProvider({
          url: '/arcgis/rest/services/World_Imagery/MapServer'
        })
      );

      this.imageryLayers.night = viewer.imageryLayers.addImageryProvider(
        new Cesium.UrlTemplateImageryProvider({
          url: 'https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}'
        })
      );
      this.imageryLayers.night.show = false;
      const services = window.___s3m;
      services.forEach((item) => {
        viewer.scene.addS3MTilesLayerByScp(
          // `http://120.25.233.26:8090/iserver/services/${item}/rest/realspace/datas/Config/config`,
          `/iserver/services/${item}/rest/realspace/datas/Config/config`,
          {
            name: item
            // cullEnabled: false
          }
        );
      });

      // 关闭地图深度检测
      viewer.scene.globe.depthTestAgainstTerrain = false;

      // 根据环境设置相对应地图
      // if (this.VUE_APP_IP != "172.16.6.20") {
      //   if (this.my_layers_data === "night") {
      //     viewer.imageryLayers.addImageryProvider(
      //       new Cesium.UrlTemplateImageryProvider({
      //         url: "http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
      //       })
      //     );
      //   } else if (this.my_layers_data === "tian") {
      //     viewer.imageryLayers.addImageryProvider(
      //       new Cesium.TileMapServiceImageryProvider({
      //         url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
      //       })
      //     );

      //     viewer.imageryLayers.addImageryProvider(
      //       new Cesium.WebMapTileServiceImageryProvider({
      //         url:
      //           "http://{s}.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0" +
      //           "&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}" +
      //           "&style=default.jpg&tk=" +
      //           "0dabff806320955417bec4c93aa77c0b",
      //         layer: "cia_w",
      //         style: "default",
      //         format: "tiles",
      //         tileMatrixSetID: "GoogleMapsCompatible",
      //         subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"], //天地图8个服务器
      //         minimumLevel: 0,
      //         maximumLevel: 18,
      //       })
      //     );
      //   }
      // } else {
      //   let ip = null
      //   if (this.VUE_APP_IP === "172.16.6.20") {
      //     ip = '172.16.6.15:9000'
      //   } else {
      //     ip = '201.10.1.253'
      //   }
      //   viewer.imageryLayers.addImageryProvider(
      //     new Cesium.TileMapServiceImageryProvider({
      //       url: Cesium.buildModuleUrl(`http://${ip}/gis/mapservice2/HAIN`),
      //     })
      //   )
      //   viewer.imageryLayers.addImageryProvider(
      //     new Cesium.UrlTemplateImageryProvider({
      //       url: `http:/${ip}/gis/mapservice1/${this.my_layers_data}/v101/Layers/_alllayers/{selfZ}/{selfY}/{selfX}.png`,
      //       customTags: {
      //         selfZ: function (imageryProvider, x, y, level) {
      //           return "L" + `${level}`.padStart(2, "0");
      //         },
      //         selfX: function (imageryProvider, x, y, level) {
      //           var index = x.toString(16);
      //           return (
      //             "C" +
      //             "00000000".substring(0, 8 - index.length) +
      //             index.toUpperCase()
      //           );
      //         },
      //         selfY: function (imageryProvider, x, y, level) {
      //           var index = y.toString(16);
      //           return (
      //             "R" +
      //             "00000000".substring(0, 8 - index.length) +
      //             index.toUpperCase()
      //           );
      //         },
      //       },
      //     })
      //   );
      // }
      // 进入飞行控制页面的时候相机高度低一点
      if (this.cameraPosition) {
        viewer.camera.setView({
          // 设置相机位置
          destination: Cesium.Cartesian3.fromDegrees(112.207128, 34.306776, 122315.69798255507),
          orientation: {
            heading: 0.21185413651977214,
            pitch: -0.9405274414396962,
            roll: 6.28318351146242
          }
        });
      } else {
        viewer.camera.setView({
          destination: Cesium.Cartesian3.fromDegrees(112.207128, 34.306776, 122315.69798255507),
          orientation: {
            heading: 0.21185413651977214,
            pitch: -0.9405274414396962,
            roll: 6.28318351146242
          }
        });
      }
      this.init_events();
    },
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading;
      var pitch = viewer.scene.camera.pitch;
      var roll = viewer.scene.camera.roll;
      var info = { heading: head, pitch: pitch, roll: roll };
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic; // with longitude and latitude expressed in radians and height in meters.
      // 以下方式也可以获取相机位置只是返回的坐标系不一样
      // var position = viewer.scene.camera.position //cartesian3 空间直角坐标系
      // var ellipsoid = scene.globe.ellipsoid;
      // var position =ellipsoid.cartesianToCartographic(viewer.scene.camera.position)//
      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6);
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6);
      var height = position.height;
      return { lng: longitude, lat: latitude, h: height, mat: info };
    },
    // 开启鼠标点击与移动事件
    init_events() {
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        this.on_plate_left_click(movement);
      }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
      handler.setInputAction((movement) => {
        // 获取当前相机高度
        const height = Math.ceil(viewer.camera.positionCartographic.height);
        this.on_plate_wheel(height);
      }, Cesium.ScreenSpaceEventType.WHEEL);
    },
    on_plate_wheel(height) {
      if (height >= 1.4e7 && !plate_data_source.show) {
        // this.fly_to_plate()
      } else if (height > 80000) {
        // 首页板块封面代码
        // plate_data_source.entities.values.map(item => item.polygon.material = this.imgUrl)
        // plate_data_source.show = true
      }
    },
    CesiumLayerMouseEnter() {
      // 鼠标移入地球添加鼠标滚动效果
      document.addEventListener(`mousewheel`, this.mouseScoll);
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
    get_plate_by_click(moment) {
      const pickedObject = viewer.scene.pick(moment.position);
      if (pickedObject?.id?._name) {
        if (pickedObject && pickedObject.id && pickedObject.id._name.indexOf('plate_') != -1) {
          return pickedObject.id;
        } else {
          return null;
        }
      }
    },
    fly_to_globe(options) {
      viewer.trackedEntity && (viewer.trackedEntity = undefined);
      viewer.scene.globe.show = true;
      viewer.scene.skyAtmosphere.show = true;
      viewer.scene.globe.showGroundAtmosphere = true;
      // plate_data_source && (plate_data_source.show = false)
      this.location(options);
    },
    location(options = { destination: origin }, fly_type = 'flyTo') {
      viewer.camera[fly_type](options);
    },
    mouseScoll(e) {
      // 鼠标滚轮滚动效果
      /* 这里获取元素节点*/
      const oPanel = document.getElementById('app');
      const Ripples = document.getElementsByClassName('hwb-mouse-scrolling-effect');
      if (Ripples.length > 0) {
        return;
      }
      const oSpan = document.createElement(`div`);
      /* 浏览器兼容*/
      e = e || window.Event;
      /* 获取相关参数*/
      oSpan.style.left = e.clientX + `px`;
      oSpan.style.top = e.clientY + `px`;
      /* 给节点赋值参数数值*/
      oSpan.className = 'hwb-mouse-scrolling-effect';
      /* 在body中添加span标签*/
      oPanel.appendChild(oSpan);
      /* 设置定时器 间隔时间为2000毫秒*/
      setTimeout(() => {
        /* 清除ospan节点*/
        oSpan.remove();
      }, 600);
    },
    // 加载geo板块
    async init_plate() {
      //   let dataSource = await promise;
      //   let entities = dataSource.entities.values;
      //   for (let i = 0; i < entities.length; i++) {
      //     let entity = entities[i];
      //     entity._name = "plate" + "_" + entity._name;
      //     entity.polygon.outline = true;
      //     entity.polygon.outlineColor =
      //       Cesium.Color.fromCssColorString("#7ecaed");
      //     entity.polygon.material =
      //       Cesium.Color.fromCssColorString("#7ecaed").withAlpha(0.1);
      //     // this.getColorRamp([0.5, 0.3], false)
      //     // 首页板块封面代码
      //     //     // entity.polygon.material= this.imgUrl
      //     entity.polygon.extrudedHeight = Math.floor(1);
      //     let positions = entity.polygon.hierarchy._value.positions;
      //     // 边界线
      //     entity.polyline = {
      //       positions: positions.map((item) => {
      //         let c = Cesium.Cartesian3.clone(item);
      //         let wgs84 = Utils.transformCartesian2WGS84(c);
      //         wgs84.alt += 1;
      //         return Utils.transformWGS842Cartesian(wgs84);
      //       }),
      //       width: 4,
      //       material: Cesium.Color.fromCssColorString("#76d6f0"),
      //     };
      //     let center = entity.properties.centroid._value;
      //     center = Utils.transformWGS842Cartesian({
      //       lng: center[0],
      //       lat: center[1],
      //       alt: 1,
      //     });
      //     entity.position = center;
      //     // 板块乡镇字体
      //     entity.label = {
      //       text: entity._name.split("_")[1],
      //       show: true,
      //       showBackground: false,
      //       font: "16px monospace bolder",
      //       horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
      //       verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
      //       pixelOffset: new Cesium.Cartesian2(0, 0),
      //       disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //       fillColor: Cesium.Color.fromCssColorString("#FFF").withAlpha(0.5),
      //     };
      //     entity.billboard = {
      //       show: false,
      //       image: require("./img/next.png"),
      //       pixelOffset: new Cesium.Cartesian2(0, -30),
      //       width: 24,
      //       height: 24,
      //     };
      //     await viewer.dataSources.add(dataSource);
      //   }
      //   plate_data_source = dataSource
    },
    // 左击点击事件
    on_plate_left_click(movement) {
      // 首页板块封面代码
      // plate_data_source.entities.values.map(item => item.polygon.material = Cesium.Color.fromCssColorString('#00016c').withAlpha(0.2))
      const plate = this.get_plate_by_click(movement);
      if (plate) {
        // this.g_show_zysb(0, 0, 1)
        const center = plate.properties.center._value;
        this.fly_to_globe({
          destination: Utils.transformWGS842Cartesian({
            lng: center[0],
            lat: center[1],
            alt: 5000
          })
        });

        // viewer.camera.flyTo({
        //   destination: Cesium.Cartesian3.fromDegrees(120.625748, 30.369629, 34879.96979198625),
        //   orientation: {
        //     "heading": 0.031173336961421327,
        //     "pitch": -1.1699130213649074,
        //     "roll": 6.283184330860693
        //   }
        // })
      }
    },
    CesiumLayerMouseLeave() {
      // 鼠标移出地球添加鼠标滚动效果
      document.removeEventListener(`mousewheel`, this.mouseScoll);
    },
    // 视频播放
    playVideo() {
      this.modeTimer = setInterval(() => {
        this.flyTimeData = this.flyData[this.currentStep];
        const position = Cesium.Cartesian3.fromDegrees(
          this.flyData[this.currentStep].longitude * 1,
          this.flyData[this.currentStep].latitude * 1,
          this.flyData[this.currentStep].altitude * 1
        );
        this.modelData.position = position;
        this.currentStep += this.currentMultiple;
        if (this.currentStep >= this.flyData.length) {
          this.currentStep = this.flyData.length;
        }
        this.currentDuration = this.covertTime(this.currentStep);
        if (this.currentStep === this.flyData.length) {
          this.isPlay = false;
          clearInterval(this.modeTimer);
        }
      }, 1000);
    },
    // 控制播放
    controlVideoEvent() {
      this.isPlay = !this.isPlay;
      if (this.isPlay) {
        if (this.currentStep === this.flyData.length) {
          this.currentStep = 0;
        }
        this.playVideo();
      } else {
        clearInterval(this.modeTimer);
      }
    },
    //   转化时间区间
    covertTimePeriod() {
      const count = Math.floor(Math.floor(this.flyData.length / 60) / 5);
      for (let i = 1; i <= (count > 10 ? 10 : count); i++) {
        this.timeArr.push({ label: this.covertTime(i * 300), value: i * 300 });
      }
      this.timeArr.unshift({ label: this.covertTime(0), value: 0 });
      this.timeArr.push({
        label: this.covertTime(this.flyData.length),
        value: this.flyData.length
      });
    },
    //   跳转时间
    jumpTimeEvent(item) {
      this.currentStep = item.value;
      this.currentDuration = this.covertTime(this.currentStep);
      if (this.currentStep === this.flyData.length) {
        this.isPlay = false;
        clearInterval(this.modeTimer);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.aqua {
  color: aqua;
}

.smallVideo {
  width: 200px !important;
  height: 112px !important;
  position: absolute;
  // background: yellow;
  left: 14px !important;
  top: 24px !important;
  z-index: 10;
}

.maxStyle {
  width: 1352px !important;
  height: 100% !important;
}

.areaInforBox {
  top: 0;
  z-index: 14;
  width: 372px;
  height: 100%;
  background: rgba(21, 95, 192, 0.3);
  border: 1px solid #73a6d1;
  backdrop-filter: blur(2px);
}

.videoConroller {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: calc(100% - 20px);
  height: 142px;
  background: rgba(21, 95, 192, 0.3);
  border: 1px solid #73a6d1;
  backdrop-filter: blur(4px);
  .progress-play {
    padding: 0 10px;
    display: flex;
    align-items: center;
    .play-control {
      margin-right: 5px;
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}

.timeBtn {
  display: flex;
  justify-content: center;
  .timeStyle {
    width: 66px;
    height: 56px;
    line-height: 56px;
    cursor: pointer;
  }

  .default {
    color: #fff;
    background: linear-gradient(180deg, #5298df 0%, #25518f 60%, #0f3275 100%);
    box-shadow: 0px 2px 4px 0px rgba(3, 5, 49, 0.5), inset 0px 0px 23px 0px #69e6ff,
      inset 0px -2px 2px 0px #120d59, inset 0px 3px 4px 0px #a7d8ff;
    border: 2px solid;
    border-image: linear-gradient(180deg, rgba(219, 242, 255, 1), rgba(132, 210, 255, 0)) 2 2;
  }

  .active {
    color: #fdff00;
    background: linear-gradient(
      180deg,
      #ffca00 0%,
      rgba(255, 175, 0, 0.2) 51%,
      rgba(255, 226, 0, 0.5) 100%
    );
    box-shadow: 0px 2px 4px 0px rgba(49, 20, 3, 0.5), inset 0px 0px 23px 0px #ffe400,
      inset 0px -2px 2px 0px #59330d, inset 0px 3px 4px 0px #ffd8a7;
    border: 2px solid;
    border-image: linear-gradient(180deg, rgba(255, 239, 188, 1), rgba(255, 229, 132, 0)) 2 2;
  }
}

.cpt-cesium-layer-flight-log {
  // position: absolute;

  // left: 0;
  // bottom: 0;
  // right: 0;
  // top: 0;

  #layer-container-flight-log {
    // background: url("~@/assets/home/bj.png") no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 555px;
    box-sizing: border-box;
  }

  .video {
  }
}

.flightLogBox {
  background: #131518;
  border: 1px solid #73a6d1;
  backdrop-filter: blur(2px);
  z-index: 80;
  padding: 20px;
  box-sizing: border-box;
  right: -309px;
  color: #b3bbc5;
  .btnBox {
    display: flex;

    .btn {
      margin-right: 20px;
      /* width: 86px;
      height: 30px;
      line-height: 30px;
      background: rgba(38, 71, 238, 0.71);
      box-shadow: inset 0px 1px 3px 0px #ffffff,
        inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
      border-radius: 2px;
      border: 1px solid;
      text-align: center;
      cursor: pointer;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        )
        1 1; */
      cursor: pointer;
      width: 92px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #3a4252;
      border-radius: 4px;
      border: 1px solid #3bc1e5;
      &:hover {
        background: #1890ff;
      }
    }
  }

  .flightLogInfor {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 95%;
    div {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
