<template>
  <div class="waypoint-popup">
    <div v-interact class="header">
      <span class="title">{{ `航点（${currentPointNum}）` }}</span>
      <span v-hover class="iconfont icon-guanbi" @click="close()" />
    </div>
    <el-form ref="form" class="form_box" :model="form" :rules="rules">
      <el-form-item class="form_item_box">
        <span class="label">经度</span>
        <el-input-number v-model="form.longitude" :controls="false" clearable readonly />
      </el-form-item>
      <el-form-item class="form_item_box">
        <span class="label">纬度</span>
        <el-input-number v-model="form.latitude" :controls="false" clearable readonly />
      </el-form-item>
      <el-form-item class="form_item_box" prop="alt">
        <span class="label">高度</span>
        <el-input-number
          v-model="form.alt"
          :controls="false"
          clearable
          @input="(val) => handleChangeField('alt', val)"
        />m
      </el-form-item>
      <el-form-item class="form_item_box" prop="speed">
        <span class="label">速度</span>
        <el-input-number
          v-model="form.speed"
          :controls="false"
          clearable
          @input="(val) => handleChangeField('speed', val)"
        />m/s
      </el-form-item>
      <el-form-item class="form_item_box" prop="yawAngle">
        <span class="label">偏航角</span>
        <el-input-number
          v-model="form.yawAngle"
          :controls="false"
          clearable
          @input="(val) => handleChangeField('yawAngle', val)"
        />(&deg;)
      </el-form-item>
      <el-form-item class="form_item_box" prop="pitchAngle">
        <span class="label">俯仰角</span>
        <el-input-number
          v-model="form.pitchAngle"
          :controls="false"
          clearable
          @input="(val) => handleChangeField('pitchAngle', val)"
        />(&deg;)
      </el-form-item>

      <div class="form_item_box last">
        <span class="label">动作</span>
        <el-dropdown trigger="click">
          <div v-hover class="btn_add">+ 增加</div>
          <el-dropdown-menu slot="dropdown" class="cpt-home-waypoint-popup-el-dropdown">
            <el-dropdown-item v-for="item in actionList" :key="item.id">
              <div class="action" @click="addAction(item)">
                {{ item.label }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-form>
    <div class="action_box">
      <div v-for="(item, n) in selectActionList" :key="item.id" class="action_item_box">
        <div class="label cp"  :title='item.text'> {{ item.label }}</div>
        <div v-if='isFeatureLabel(item.label)'>
        <el-switch   v-model="item.checked" @change="(val) => handleChangeAction(item.key, val)" />
        <el-input   v-model="item.value" @input="(val) => handleChangeAction(item.key, val)" />
        <span   class="unit">{{ item.unit }}</span>
        </div>
        <span v-hover class="iconfont icon-shanchu" @click="deleteAction(n)" />
      </div>
    </div>
    <div class="btns">
      <!-- <div v-hover class="save btn" @click="save_point">结束编辑</div> -->
      <!-- <div v-hover class="save btn" @click="close">确认航点</div> -->
      <div v-hover class="save btn" @click="close">确定</div>
      <!-- <div v-hover class="clear btn" @click="clear_point">清空</div> -->
      <div v-hover class="delete btn" @click="deletePoint">删除航点</div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['change_active'],
  props: {
    uuid: {
      type: String,
      default: ''
    },
    pointInfo: {
      type: Object,
      default: () => {}
    },
    pointsInfo: {
      type: Array,
      default: () => []
    },
    currentPointNum: {
      type: String,
      default: '1'
    }
  },
  data() {
    const validateAltitude = (rule, value, callback) => {
      const isnum = /^[0-9]+.{0,1}[0-9]{0,2}$/.test(value);
      if (!value) {
        callback(new Error('请输入高度！'));
      } else if (value < 50 || value > 150) {
        callback(new Error('高度限制50m-150m！'));
      } else if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    const validateSpeed = (rule, value, callback) => {
      const isnum = /^[0-9]+.{0,1}[0-9]{0,2}$/.test(value);
      if (!value) {
        callback(new Error('请输入速度！'));
      } else if (value > 26 || value < 3) {
        callback(new Error('速度限制3m/s-26m/s！'));
      } else if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    const validateYawAngle = (rule, value, callback) => {
      const isnum = /^[+-]?\d+(\.\d+)?$/.test(value);
      if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    const validatePitchAngle = (rule, value, callback) => {
      const isnum = /^[+-]?\d+(\.\d+)?$/.test(value);
      if (!isnum) {
        callback(new Error('请输入正确格式！'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        alt: [{ validator: validateAltitude, trigger: ['change'] }],
        yawAngle: [{ validator: validateYawAngle, trigger: ['change'] }],
        pitchAngle: [{ validator: validatePitchAngle, trigger: ['change'] }],
        speed: [{ validator: validateSpeed, trigger: ['change'] }]
      },
      actionList: Object.freeze([
        {
          id: 1,
          label: '悬停',
          key: 'hover',
          checked: true,
          value: 1,
          unit: 's',
          text:"飞机悬停时间"
        },
        {
          id: 2,
          label: '拍照',
          key: 'photo',
          checked: true,
          value: 1,
          unit: '张',
          text:"开始拍摄照片"
        },
        {
          id: 3,
          label: '开始录像',
          key: 'START_RECORD',
          checked: true,
          value: 1,
          unit: 's',
          text:"飞机开始录像"

        },
        {
          id:3,
          label: '结束录像',
          key: 'STOP_RECORD',
          checked: true,
          value: 1,
          text:"飞机结束录像"

        },
        {
          id:4,
          label: '变焦',
          key: 'STOP_RECORD',
          checked: true,
          value: '1',
          unit: '倍',
          text:"相机变焦参数为变焦倍数"
        },
        {
          id:5,
          label: '间隔拍照',
          key: 'TRIGGER',
          checked: true,
          value: '1',
          unit: 'm',
          text:"飞机间隔拍照，以m为单位"

        },
        {
          id:6,
          label: '偏航',
          key: 'ROTATE_AIRCRAFT',
          checked: true,
          value: '10',
          unit: '度',
          text:"旋转飞机的 偏航，参数取值范围(-180 ~ 180)"
        },
        {
          id:7,
          label: '俯仰',
          key: 'GIMBAL_PITCH',
          checked: true,
          value: '10',
          unit: '度',
          text:"旋转云台的俯仰，参数取值范围(-90 ~ 30)"

        }
      ]),
      selectActionList: this.pointInfo.actions || [],
      form: {
        ...this.pointInfo
        // longitude: null,
        // latitude: null,
        // altitude: this.pointInfo.height,
        // yawAngle: this.pointInfo.yaw,
        // pitchAngle: this.pointInfo.pitch,
        // speed: this.pointInfo.speed,
        // actions: [],
      }
    };
  },
  watch: {
    pointInfo: {
      handler(newVal) {
        this.form = {
          ...newVal
        };
        this.selectActionList = newVal.actions;
      },
      deep: true
    }
  },
  updated() {},
  methods: {
    isFeatureLabel(label) {
    const featureRegex = /悬停|变焦|间隔拍照|俯仰|偏航/;
    return featureRegex.test(label);
  },
    addAction(e) {
      const isSelect = this.selectActionList.find((item) => item.id == e.id);
      if (!isSelect) {
        this.selectActionList.push(e);
      }
      this.updatePointInfo();
    },
    deleteAction(e) {
      this.selectActionList.splice(e, 1);
      this.updatePointInfo();
    },
    updatePointInfo() {
      const pointInfo = {
        ...this.form,
        actions: [...this.selectActionList]
      };
      this.$emit('changePointInfo', pointInfo);
    },
    deletePoint() {
      this.$emit('deletePointInfo', this.pointInfo.uuid);
    },
    transform_lat_lng(viewer, event) {
      const earthPosition = viewer.camera.pickEllipsoid(
        event.position,
        viewer.scene.globe.ellipsoid
      );
      const cartographic = Cesium.Cartographic.fromCartesian(
        earthPosition,
        viewer.scene.globe.ellipsoid,
        new Cesium.Cartographic()
      );
      const latitude = Cesium.Math.toDegrees(cartographic.latitude);
      const longitude = Cesium.Math.toDegrees(cartographic.longitude);
      const altitude = cartographic.height;
      return { latitude, longitude, altitude };
    },
    close() {
      this.$emit('waypointPopupClose');
    },
    handleChangeField(key, val) {
      const pointInfo = {
        ...this.form,
        [key]: val,
        actions: [...this.selectActionList]
      };
      this.$emit('changePointInfo', pointInfo);
    },
    handleChangeAction(key, val) {
      const index = this.selectActionList.findIndex((item) => item.key === key);
      if (key == 'photo') {
        this.selectActionList[index].value = 1;
      } else {
        this.selectActionList[index].value = val;
      }
      const pointInfo = {
        ...this.form,
        actions: [...this.selectActionList]
      };
      this.$emit('changePointInfo', pointInfo);
    }
  }
};
</script>

<style lang="scss">
.cpt-home-waypoint-popup-el-dropdown {
  .el-dropdown-menu__item {
    padding: 0;
    .action {
      width: 68px;
      text-align: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.waypoint-popup {
  width: 323px;
  height: 610px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  position: fixed;
  top: 200px;
  left: 530px;
  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    // background: #111111;
    border-radius: 4px 4px 0 0;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }
    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
    }
  }
  .form_box {
    height: 366px;
    border-bottom: 1px solid #494949;
    margin: 15px 15px 0 15px;
    box-sizing: border-box;
    .form_item_box {
      display: flex;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      margin-bottom: 14px;
      align-items: center;
      .label {
        display: inline-block;
        width: 48px;
      }
      .el-input {
        width: 187px;
        margin-right: 8px;
      }
    }
    .form_item_box.last {
      justify-content: space-between;
      margin-top: 15px;
      .btn_add {
        width: 56px;
        height: 32px;
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        font-size: 14px;
        color: #f8f8f8;
        text-align: center;
        line-height: 32px;
      }
    }
  }
  .action_box {
    height: 123px;
    border-bottom: 1px solid #494949;
    margin: 15px 15px 0 15px;
    box-sizing: border-box;
    overflow-y: auto;
    .action_item_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      margin-bottom: 7px;
      .el-input {
        width: 159px;
      }
      .unit {
        display: inline-block;
        width: 10px;
        text-align: left;
      }
      .iconfont {
        color: #f27076;
      }
    }
  }
  .btns {
    display: flex;
    justify-content: space-between;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    .btn {
      width: 92px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      text-align: center;
      line-height: 32px;
      margin: 15px 15px 0;
    }
    .btn.delete {
      border: 1px solid #f46269;
      color: #f46269;
    }
  }
  &::v-deep {
    .el-form-item__error {
      padding-top: 0px;
      position: absolute;
      bottom: -12px;
      margin-left: 20%;
    }
    .el-input__inner {
      width: 96%;
      text-align: left;
    }
  }
}
</style>
