<template>
  <div class="tb-box">
    <div class="three-dimensionalv2_tabs" :class="{ preventClick: isPreventClick_ }">
      <div
        v-for="item in list"
        :key="item.id"
        :class="{ activeColor: active == item.id, tableItem: true }"
        @click="active = item.id"
      >
        {{ item.title }}
        <div :class="{ dimensionalv2_active: active == item.id }" />
      </div>
    </div>
    <!-- v-if="active == 1 && linkage" -->
    <div v-if="active === 1 || active == 2" class="linkage">
      分屏对比
      <div class="cp" @click="totalMapsChange(2)">
        <img v-if="totalMaps == 2" src="./img/5ON.png" alt="" />
        <img v-else src="./img/5.png" alt="" />
      </div>
      <div class="cp" @click="totalMapsChange(3)">
        <img v-if="totalMaps == 3" src="./img/6ON.png" alt="" />
        <img v-else src="./img/6.png" alt="" />
      </div>
      <div class="cp" @click="totalMapsChange(4)">
        <img v-if="totalMaps == 4" src="./img/7ON.png" alt="" />
        <img v-else src="./img/7.png" alt="" />
      </div>
    </div>
    <Layer v-if="active === 1" />
    <Tagging v-if="active === 2" />
    <Tag v-if="active === 3" />
  </div>
</template>

<script>
import Layer from './layer';
import Tag from './Tag';
import Tagging from './tagging';
import { mapMutations, mapState } from 'vuex';

export default {
  components: { Layer, Tag, Tagging },
  data() {
    return {
      activelinkage: false,
      active: 1,
      oldnum: null,
      list: [
        {
          title: '图层',
          id: 1
        },
        {
          title: '标注',
          id: 2
        },
        {
          title: '标签',
          id: 3
        }
      ]
    };
  },
  watch: {
    active: {
      handler(value) {
        if (value > 2 || value < 1) {
          this.$store.commit('subplatform/SET_LINK_AGE', this.activelinkage);
          if (!this.activelinkage) {
            this.SET_TOTAL_MAPS(1);
          }
        } else {
          if (this.$store.state.active === 117) {
            this.totalMapsChange(2);
          }
        }
      }
    }
  },
  computed: {
    ...mapState('subplatform', ['totalMaps', 'linkage']),
    isPreventClick_() {
      return this.$store.state.uavApplications.isPreventClick;
    }
  },
  methods: {
    ...mapMutations('subplatform', ['SET_TOTAL_MAPS']),
    totalMapsChange(num) {
      if (num == this.oldnum) {
        this.oldnum = null;
        this.SET_TOTAL_MAPS(1);
        return;
      }
      this.SET_TOTAL_MAPS(num);
      this.oldnum = num;
    },
    getItemList() {
      this.$bus.$on('handleItem', (list, show, e) => {
        this.navId = e.id;
        this.sideList = list;
        this.navIndex = show;
      });
    },
    handleClick() {}
  },
  mounted() {
    // 分期对比默认打开分屏
    if (this.$store.state.active === 117) {
      this.totalMapsChange(2);
    } else if (this.$store.state.active === 118) {
      this.active = 2;
    } else {
      this.activelinkage = !this.activelinkage;
      this.$store.commit('subplatform/SET_LINK_AGE', this.activelinkage);
      if (!this.activelinkage) {
        this.SET_TOTAL_MAPS(1);
      }
    }
    this.getItemList();
  },
  beforeDestroy() {
    this.$store.commit('subplatform/SET_LINK_AGE', this.activelinkage);
    if (!this.activelinkage) {
      this.SET_TOTAL_MAPS(1);
    }
  }
};
</script>

<style lang="scss" scoped>
// @import '~@/styles/list-common.scss';

.three-dimensionalv2_tabs {
  display: flex;
  color: #fff;
  gap: 29px;
  justify-content: center;
  position: absolute;
  left: calc(50% - 82px);
  top: -28px;
}

.linkage {
  display: flex;
  justify-content: space-evenly;
  line-height: 26px;
  margin-top: 30px;
  color: #b3bbc5;
  width: 87%;
  font-size: 14px;
}

.activeColor {
  color: #a4ff64;
}

.dimensionalv2_active {
  width: 100%;
  margin-top: 3px;
  height: 3px;
  background-image: linear-gradient(
    to right,
    transparent,
    transparent 25%,
    #a4ff64 25%,
    #a4ff64 75%,
    transparent 75%,
    transparent
  );
  background-clip: content-box;
  border-bottom: 3px solid transparent;
}

.tableItem {
  cursor: pointer;
  padding-bottom: 4px;
}

.tb-box {
  padding: 0;

  .tb-hd-box {
    .tb-hd {
      overflow: hidden;
    }
  }
}
</style>
