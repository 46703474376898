var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flight-task-list"},[(_vm.target === 1)?_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.taskType),callback:function ($$v) {_vm.taskType=$$v},expression:"taskType"}},[_c('el-tab-pane',{attrs:{"label":"常态任务","name":"0"}}),_c('el-tab-pane',{attrs:{"label":"定时任务","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"周期任务","name":"2"}})],1):_vm._e(),_c('div',{staticClass:"search_box task"},[_c('el-button',{directives:[{name:"hover",rawName:"v-hover"},{name:"pms",rawName:"v-pms",value:(['飞行任务', '创建']),expression:"['飞行任务', '创建']"}],on:{"click":_vm.goTaskCreate}},[_vm._v(" 创建任务 ")]),_c('el-select',{staticStyle:{"font-size":"12px"},attrs:{"placeholder":"任务状态","clearable":""},on:{"change":_vm.selectSearch},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-input',{attrs:{"placeholder":"任务名称","prefix-icon":"el-icon-search"},on:{"change":_vm.goSearch},model:{value:(_vm.taskText),callback:function ($$v) {_vm.taskText=$$v},expression:"taskText"}})],1),_c('div',{staticClass:"tb-box"},[_vm._m(0),_c('div',{staticClass:"tb-bd-box"},_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"tb-tr"},[_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(item.taskName || "暂无")+" ")]),_c('div',{staticClass:"td",style:({ color: _vm.taskTypeColor(item) })},[_vm._v(" "+_vm._s(_vm.taskTypeName(item) || "暂无")+" ")]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(item.orgName || "暂无")+" ")]),_c('div',{staticClass:"td",style:({
            opacity: item.deviceStatus == 1 ? '1' : '0.5',
            color: item.deviceStatus == 1 ? '#a4ff64' : '',
          }),attrs:{"alt":item.uavName}},[_vm._v(" "+_vm._s(item.uavName || "暂无")+" ")]),_c('div',{staticClass:"td",staticStyle:{"flex":"none","width":"103px !important"}},[_c('el-tooltip',{directives:[{name:"hover",rawName:"v-hover"},{name:"pms",rawName:"v-pms",value:(['飞行任务', '详情']),expression:"['飞行任务', '详情']"}],attrs:{"effect":"dark","content":"详情","placement":"top"}},[_c('img',{staticStyle:{"color":"#a16869","width":"26px","height":"26px"},attrs:{"src":require("@/assets/image/icons/details.png")},on:{"click":function($event){return _vm.detailsItem(item)}}})]),(item.approveStatus === 1 && item.userId === _vm.user_info.id)?_c('el-tooltip',{directives:[{name:"hover",rawName:"v-hover"},{name:"pms",rawName:"v-pms",value:(['飞行任务', '编辑']),expression:"['飞行任务', '编辑']"}],attrs:{"effect":"dark","content":"编辑","placement":"top"}},[_c('div',{staticClass:"iconfont icon-bianji",on:{"click":function($event){return _vm.goEdit(item)}}})]):(
              item.approveStatus === 3 && item.userId === _vm.user_info.id
            )?_c('el-tooltip',{directives:[{name:"hover",rawName:"v-hover"},{name:"pms",rawName:"v-pms",value:(['飞行任务', '重新发起']),expression:"['飞行任务', '重新发起']"}],attrs:{"effect":"dark","content":"重新发起","placement":"top"}},[_c('img',{staticStyle:{"color":"#a16869","width":"26px","height":"26px"},attrs:{"src":require("@/assets/image/icons/reload.png")},on:{"click":function($event){return _vm.reloadItem(item)}}})]):_vm._e(),(!item.approveButton && item.approveStatus === 1)?_c('el-tooltip',{directives:[{name:"pms",rawName:"v-pms",value:(['飞行任务', '审批']),expression:"['飞行任务', '审批']"}],attrs:{"effect":"dark","content":"审批","placement":"top"}},[_c('img',{directives:[{name:"hover",rawName:"v-hover"}],class:{ disable: item.taskStatus != 1 },staticStyle:{"color":"#a16869","width":"26px","height":"26px"},attrs:{"src":require("@/assets/image/icons/examine.png")},on:{"click":function($event){return _vm.examineItem(item)}}})]):_vm._e(),(
              item.userId === _vm.user_info.id &&
              (item.approveStatus === 1 ||
                item.approveStatus === 2 ||
                item.approveStatus === 3)
            )?_c('el-tooltip',{directives:[{name:"pms",rawName:"v-pms",value:(['飞行任务', '删除']),expression:"['飞行任务', '删除']"}],attrs:{"effect":"dark","content":"删除","placement":"top"}},[_c('div',{directives:[{name:"hover",rawName:"v-hover"}],staticClass:"iconfont icon-shanchu",class:{ disable: item.taskStatus != 1 },staticStyle:{"color":"#a16869"},on:{"click":function($event){return _vm.deleteItem(item)}}})]):_vm._e()],1)])}),0)]),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-count":_vm.current,"page-size":_vm.page.size},on:{"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tb-hd-box"},[_c('div',{staticClass:"tb-hd"},[_vm._v("任务名称")]),_c('div',{staticClass:"tb-hd"},[_vm._v("任务状态")]),_c('div',{staticClass:"tb-hd"},[_vm._v("责任单位")]),_c('div',{staticClass:"tb-hd"},[_vm._v("无人机")]),_c('div',{staticClass:"tb-hd",staticStyle:{"flex":"none","width":"103px !important"}},[_vm._v(" 操作 ")])])}]

export { render, staticRenderFns }