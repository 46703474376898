<template>
  <div class="tack-tooptip">
    <div v-interact class="header">
      <span class="title">提示</span>
      <span class="iconfont icon-guanbi" @click="change_active(1)" />
    </div>
    <div class="body">
      <span class="tip">{{ uavOnlineState ? tip1 : tip2 }}</span>
    </div>
    <div class="form_btn">
      <div class="btn_cancel" @click="change_active(1)">取消</div>
      <el-button
class="btn_send"
type="primary" :loading="isLoading" @click="confirm"
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mapMutations } from 'vuex';
import { flightTaskAPI } from '@/api';

export default {
  inject: ['change_active', 'get_flight_info', 'tooptip_flight_info', 'c_dashBoard'],
  name: 'FlightTaskTooptip',
  data() {
    return {
      tip1: '确定执行当前任务？',
      tip2: '任务发送失败，请检查当前无人机是否在线！',
      task_info: {},
      isLoading: false
    };
  },

  computed: {
    ...mapState(['uavOnlineState'])
  },

  methods: {
    ...mapMutations(['SET_ISFORMAIRLINE_STATUS']),
    async confirm() {
      if (this.uavOnlineState) {
        this.isLoading = true;
        try {
          const item = this.tooptip_flight_info();
          const data = await flightTaskAPI.taskStart({
            id: item.id
          });
          if (data.code == 200) {
            item.taskStatus = 2; // 更改状态为执行中
            this.get_flight_info(item); // 传到控制台
            this.c_dashBoard.isShow = true;
          } else {
            this.$message.error(data.message || '执行任务失败！');
          }
        } catch (e) {
        } finally {
          this.isLoading = false;
        }
      } else {
        this.get_flight_info(''); // 不传
      }
      // this.$store.state.deviceName2 = this.$store.state.deviceName1;
      // this.$store.state.routeName2 = this.$store.state.routeName1;
      // this.$store.state.taskName2 = this.$store.state.taskName1;
      this.change_active(1);
    }
  }
};
</script>

<style lang="scss" scoped>
.tack-tooptip {
  width: 323px;
  height: 170px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  box-sizing: content-box;
  position: absolute;
  top: calc(50% - 85px);
  left: calc(50% - 161px);
  z-index: 999;
  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }
    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .body {
    height: calc(100% - 85px);
    width: 317px;
    margin: 0 auto;
    box-sizing: border-box;
    border-bottom: 1px solid #494949;
    border-top: 1px solid #494949;
    display: flex;
    justify-content: center;
    align-items: center;
    .tip {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #b3bbc5;
      letter-spacing: 0;
      font-weight: 400;
      letter-spacing: 1px;
    }
  }
  .form_btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    position: absolute;
    bottom: 10px;
    width: 317px;
    .btn_cancel,
    .btn_send {
      box-sizing: border-box;
      height: 32px;
      padding: 0 20px 0 20px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
