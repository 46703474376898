<template>
  <div class="cpt-nest-logger" :class="{ chang: true }">
    <div v-interact class="title-box">
      <div class="title pl20">
        <!-- <img src="~@/assets/images/mount/mount_head.png" /> -->
        <div class="font">运行监控日志</div>
      </div>
      <div style="display: flex">
        <div class="icon-box" @click="clearMsg">
          <span class="iconfont icon-qingchushuju" />
          <!-- <span class="icon-text pr20">清除数据</span> -->
        </div>
        <!-- <div class="icon-box" @click="$emit('step')">
        <span class="icon-text">运行流程</span>
      </div> -->
        <div class="cf ml20 mr10 c70d" @click="$emit('close')">关闭</div>
      </div>
    </div>
    <div v-if="$uav.nestId" class="mb24 mt14 pl20 pr20 jcsb liucBox">
      <div :class="folderFn(folder)">
        <span class="w10 mr4 h10 radius" />固定无人机
      </div>
      <div :class="coverFn(cover)">
        <span class="w22 line" />
        <span class="w10 mr4 h10 radius" />打开舱盖
      </div>
      <div :class="liftsFn(lifts)">
        <span class="w22 line" />
        <span class="w10 mr4 h10 radius" />升起平台
      </div>
      <div :class="folderFn3(folder)">
        <span class="w22 line" />
        <span class="w10 mr4 h10 radius" />释放无人机
      </div>
      <div :class="rktFn(wrjType)">
        <span class="w22 line" />
        <span class="w10 mr4 h10 radius" />无人机RTK定位
      </div>
    </div>
    <div class="ctx-box">
      <div class="list-box">
        <div v-for="(item, index) in msg_list" :key="index" class="item-box mt7">
          <div class="text-box pl100">
            <div
              class="type-box"
              :class="{ emerg: item.type === 1, ordinary: item.type === 8 }"
            >
              <span class="type">AUTO</span>
            </div>
            <span class="text" v-html="item.text" />
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="jiantou" :class="{ zhaun: dakai == true }" @click="jiantou">
      <img :src="jian" style="width: 100%; height: 100%" alt="" />
    </div> -->
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    wrjType: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      rtkPower: 0,
      folder: 0,
      lifts: 0,
      cover: 0,
    };
  },
  computed: {
    msg_list() {
      return this.$store.state.uavApplications.msg_list;
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    currentEagle() {
      return this.$store.state.uavApplicationsEagleNest.currentEagle;
    },
    mqttDataSet() {
      return this.$store.state.MMCMQTT?.dataSet?.[this.currentEagle?.hardId];
    },
  },
  watch: {
    list: function (vla, old) {
      vla.forEach((item) => {
        if (item.text == "[回收流程][11-7]关闭舱盖...") {
          console.log("..............");
        }
      });
      // console.log(vla,'运行日志');
    },
    // 鹰巢状态
    mqttDataSet: function (val) {
      if (val[2065]) {
        const data = val[2065].data;
        if (data.rtkPower) {
          this.rtkPower = data.rtkPower;
        }
        if (data.folder) {
          this.folder = data.folder;
        }
        if (data.rtkPower) {
          this.rtkPower = data.rtkPower;
        }
        if (data.lifts) {
          this.lifts = data.lifts;
        }
        if (data.cover) {
          this.cover = data.cover;
        }
      }
      if (val[2063]){
        const newData = {
          type: "2063",
          text: val[2063].text,
          time: dayjs().format('YYYY-MM-DD HH:mm:ss')
        };
        const firstTwoItems = this.msg_list.slice(0,5);
        const shouldStopLoading = firstTwoItems.some((item) => {
          return  item.text === newData.text;
        });
        if (!shouldStopLoading) {
          this.$store.state.uavApplications.msg_list.unshift(newData);
        }
      }
      if (val[3]){
        console.log(val[3],'val[3]');
        const newData = {
          type: "2063",
          text: val[3].body,
          time: val[3].dateTime
        };
        // .slice(0, 2);
        const firstTwoItems = this.msg_list
        const shouldStopLoading = firstTwoItems.some((item) => {
          return  item.time === newData.time;
        });
        if (!shouldStopLoading) {
          this.$store.state.uavApplications.msg_list.unshift(newData);
        }
      }
    },
    deep: true,
  },
  methods: {
    clearMsg() {
      this.$store.commit("uavApplications/setState", {
        key: "msg_list",
        value: [],
      });
    },
    folderFn(folder) {
      // 已降落 夹紧中
      if (this.lifts == 1 && folder == 3) {
        return "current";
      } else if (this.lifts == 1 && folder == 2) {
        return "default";
      } else {
        return "end";
      }
    },
    // 是否出仓
    coverFn(cover) {
      switch (cover) {
        case 0:
          return "default";
          break;
        case 4:
          return "current";
          break;
        case 2:
          return "end";
          break;
        default:
          return "default";

          break;
      }
    },
    // 升降
    liftsFn(lifts) {
      switch (lifts) {
        case 0:
          return "default";
          break;
        case 4:
          return "current";
          break;
        case 2:
          return "end";
          break;
        default:
          return "default";
          break;
      }
    },
    // 释放无人机
    folderFn3(folder) {
      // 已升起 放松中
      if (this.lifts == 2 && folder == 4) {
        return "current";
      } else if (this.lifts == 2 && folder == 2) {
        return "end";
      } else {
        return "default";
      }
    },
    rktFn(data) {
      if (data.code == 1000 && this.folder == 2 && this.lifts == 2) {
        return "end";
      } else if (this.folder == 2 && this.lifts == 2) {
        if (data.code != 1000) {
          return "current";
        } else {
          return "end";
        }
      } else {
        return "default";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.yidong {
  left: 52% !important;
}
.cpt-nest-logger {
  position: fixed;
  width: 670px;
  height: 106px;
  top: 21%;
  left: 33%;
  display: flex;
  opacity: 0.9;
  // overflow-y: auto;
  // background: rgba(9, 32, 87, 0.7);
  // // border: 1px solid #70daf9;
  // border-radius: 10px 10px 0 0;
  background: rgb(19, 21, 24);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 8px;
  flex-direction: column;

  .liucBox {
    .default {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #afdcff;
      letter-spacing: 1px;
      font-weight: 400;
      .radius {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #afdcff;
        margin-left: 5px;
      }
      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border-top: 0.2px dashed #afdcff;
      }
    }

    .current {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #43deff;
      letter-spacing: 1px;
      font-weight: 400;
      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border-top: 1px solid #43deff;
        background: #43deff;
      }
      .radius {
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        background: #43deff;
        border: 1px solid #43deff;
      }
    }
    .end {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: rgba(67, 222, 255, 0.6);
      letter-spacing: 1px;
      font-weight: 400;
      .radius {
        display: inline-block;
        border-radius: 50%;
        margin-left: 5px;
        background: rgba(67, 222, 255, 0.7);
      }
      .line {
        height: 0.5px;
        display: inline-block;
        margin-bottom: 4px;
        border: 1px solid #afdcff;
        background: #afdcff;
      }
    }
  }

  .title-box {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    border: 1px solid #000000;

    .title {
      display: flex;
      align-items: center;
      .font {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      cursor: pointer;

      .iconfont {
        font-size: 18px;
        color: #00ffff;
        margin-right: 4px;
      }

      .icon-text {
        font-family: MicrosoftYaHeiUI;
        font-size: 14px;
        color: #ccedff;
        font-weight: 400;
      }
    }
  }

  .ctx-box {
    flex: 1;
    overflow: hidden;

    .list-box {
      height: 100%;
      overflow: auto;
      overflow-x: hidden;
      box-sizing: border-box;

      .item-box {
        display: flex;
        align-items: center;
        color: #fff;

        .text-box {
          display: flex;
          align-items: center;

          .type-box {
            width: 38px;
            height: 20px;
            transform-origin: center;
            transform: scale(0.6);
            padding: 2px 6px;
            border-radius: 30px;
            text-align: center;
            display: flex;
            align-items: center;

            &.ordinary {
              background-color: #298ad3;
            }

            &.emerg {
              background-color: #ff3c3c;
            }

            .type {
              font-size: 10px;
            }
          }

          .text {
            font-size: 12px;
            color: #bacfea;
          }
        }
      }
    }
  }

  .xb {
    transform: rotate(-180deg);
    background: rgba(1, 10, 46, 0.63);
    border-radius: 10px 10px 0 0;
  }
}
.jiantou {
  width: 22px;
  height: 22px;
  transform: rotate(90deg);
  cursor: pointer;
  margin: 0 auto;
}
.chang {
  height: 250px !important;
  top: 233px;
  z-index: 1;
}
.zhaun {
  transform: rotate(270deg) !important;
}
.c70d {
  color: #70daf9;
}
</style>
