<template>
  <div class="flight-line-list">
    <!-- <div class="search_box line">
      <el-input
        v-model="search.name"
        placeholder="请输入航线名称"
        prefix-icon="el-icon-search"
        @change="handelSearch"
      />
    </div> -->
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">资源类型</div>
        <div class="tb-hd">资源名称</div>
        <div class="tb-hd">资源地址</div>
        <div class="tb-hd">操作栏</div>
      </div>
      <div class="tb-bd-box">
        <div v-for="(item, index) in list" :key="index" class="tb-tr">
          <div class="td">
            {{ item.propertyTypeName || "暂无" }}
          </div>
          <div class="td">
            {{ item.propertyName || "暂无" }}
          </div>
          <div class="td">
            {{ item.place || "暂无" }}
          </div>

          <div class="td" @click="showMark(item)">查看</div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="totalPage"
        :page-size="search.size"
        :current-page.sync="search.num"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { projectAPI } from "@/api";
import { mapMutations } from "vuex";
import { permissionIds } from "@/utils/permission";
let markerArr = [];
let viewer = null;
import { createPoint } from "@/utils/CesiumFunction/CreateLabel";
import Bubble from "./com/bubbleModel/index.js";
import icon2 from "@/assets/image/icons/2.png";
import icon3 from "@/assets/image/icons/3.png";
import icon4 from "@/assets/image/icons/4.png";
import icon5 from "@/assets/image/icons/5.png";
import icon6 from "@/assets/image/icons/6.png";
import icon7 from "@/assets/image/icons/7.png";
import icon8 from "@/assets/image/icons/8.png";

const iconMap = new Map([
  ["飞手", icon2],
  ["服务网点", icon3],
  ["培训网点", icon4],
  ["展示网点", icon5],
  ["资源管理", icon6],
  ["无人机", icon7],
  ["合作网点", icon8],
]);
let bubblesList = [];
export default {
  inject: [
    "change_active",
    "get_route_info",
    "edit_point_info",
    "send_to_line_tooptip",
  ],
  data() {
    return {
      status: null,
      list: [],
      search: {
        name: "",
        num: 1,
        size: 20,
      },
      totalPage: null,
      pointArr: [],
      isEditting: false,
      point_item_info: {},
      bubbles: null,
      point_positions: {},
      id: null,
      listNav: null, //数据类型状态
      init_lng: 112.576667,
      init_lat: 23.175833,
      init_alt: 7300000,
    };
  },
  async mounted() {
    viewer = window.viewer;
    this.init_events();
  },
  watch: {
    "$store.state.uavApplications.getPageBasicPropertyInfosById": {
      async handler(value) {
        if (value) {
          if (markerArr.length > 0) {
            markerArr.forEach((entities) => {
              window.viewer.entities.remove(entities);
            });
          }
          if (bubblesList.length > 0) {
            bubblesList.forEach((element) => {
              element.windowClose();
            });
            bubblesList = [];
          }
          markerArr = [];
          if (!this.listNav) {
            await this.getPageBasicPropertyTypes();
          }
          this.id = value;
          this.getList(1);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  //
  methods: {
    showMark(mark) {
      const entity = viewer.entities.getById("mark_" + mark.id);
      if (entity) {
        viewer.flyTo(entity);
        if (markerArr.indexOf(entity) > -1) {
          if (!document.getElementById(entity._id)) {
            let id = entity._id;
            this.bubble(id);
          }
        }
      }
    },
    async getPageBasicPropertyTypes() {
      let res = await projectAPI.getPageBasicPropertyTypes();
      if (res.code == 200) {
        if (res.data.items && res.data.items.length > 0) {
          this.listNav = res.data.items.map((item) => {
            return {
              ...item,
              isShow: true,
            };
          });
        }
      }
    },
    permissionFn(pid) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    ...mapMutations([
      "SET_ISFORMAIRLINE_STATUS",
      "SET_SIDEBAR_STATUS",
      "SET_CESIUM_STATE",
      "SET_OPEN_RIGHT_NAV_SILDER",
    ]),
    // 获取航线列表
    async getList(num) {
      this.$store.commit("uavApplications/setState", {
        key: "getPageBasicPropertyInfosById",
        value: null,
      });
      viewer.scene.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          this.init_lng,
          this.init_lat,
          this.init_alt
        ),
      });
      this.search.num = num;
      const param = {
        page: num,
        pageSize: this.search.size,
        // name: this.search.name ? this.search.name : undefined,
        propertyTypeId: this.id,
      };
      // 资源列表
      let res = await projectAPI.getPageBasicPropertyInfos(param);
      this.list = res.data.items;
      this.search = {
        num: res.data.currentPage,
        size: res.data.pageSize,
      };
      this.totalPage = res.data.totalCount;
      if (this.list.length > 0) {
        const item = this.list[0];
        let icon = null;
        if (iconMap.get(item.propertyTypeName)) {
          icon = iconMap.get(item.propertyTypeName);
        } else {
          const iconItme = this.listNav.filter(
            (k) => k.id == item.propertyType
          );
          icon = iconItme[0].propertyUrl;
        }
        for (let j = 0; j < this.list.length; j++) {
          const element = this.list[j];
          if (element.lat && element.lng) {
            this.createPointFn(window.viewer, { ...element, icon });
          }
        }
      }
    },
    init_events() {
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.height = height;
      }, Cesium.ScreenSpaceEventType.WHEEL);
      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          if (markerArr.indexOf(pickedLabel.id) > -1) {
            if (!document.getElementById(pickedLabel.id.id)) {
              let id = pickedLabel.id.id;
              this.bubble(id);
            } else {
              if (this.bubbles) {
                this.bubbles.windowClose();
              }
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    bubble(id) {
      if (bubblesList.length > 0) {
        bubblesList.some((v, i) => {
          if (v.vmInstance.id === id) {
            v.windowClose();
            bubblesList.splice(i, 1);
            return true; // 返回 true 中断循环
          }
        });
      }
      let aa = markerArr.find((item) => item.id === id);
      this.bubbles = new Bubble(
        Object.assign(aa, {
          viewer,
        })
      );
      let b = this.bubbles;
      bubblesList.push(b);
    },
    createPointFn(viewer, data) {
      const entities = createPoint(viewer, {
        id: "mark_" + data.id,
        position: Cesium.Cartesian3.fromDegrees(data.lng, data.lat, 10),
        image: data.icon,
        width: data.propertyName == "无人机" ? 48 : 72,
        height: data.propertyName == "无人机" ? 48 : 63,
        title: data.propertyName,
        markInfo: data,
        scale: data.propertyName == "无人机" ? 1 : 0.7,
      });
      markerArr.push(entities);
    },
    // 页码
    handleCurrentChange(val) {
      this.getList(val);
    },
    async urlToBase64(url) {
      // 使用fetch函数获取URL的内容
      const response = await fetch(url);
      const blob = await response.blob();

      // 创建一个FileReader对象来读取Blob数据
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
  },

  beforeDestroy() {
    if (bubblesList.length > 0) {
      bubblesList.forEach((element) => {
        element.windowClose();
      });
      bubblesList = [];
    }
    markerArr.forEach((element) => {
      viewer.entities.remove(element);
    });
    markerArr = [];
  },
  destroyed() {
    // 清空窗口状态
    this.SET_SIDEBAR_STATUS(null);
  },
};
</script>

<style lang="scss" scoped>
.flight-line-list {
  @import "~@/styles/list-common.scss";
  height: calc(100% + 38px);

  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;

    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;

      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    &::v-deep {
      .el-input {
        width: 223px;
        margin: 0px 8px;
        border: 1px solid #233a58;
        border-radius: 10px;

        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }

  .pagination {
    width: 100%;
    height: 30px;
    position: absolute;
    right: 6px;
    bottom: -34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tl-box {
    height: 100%;

    .task-create-box {
      height: calc(100% - 36px);
    }

    .task-edit-box {
      height: calc(100% - 36px);
    }

    .line-create-box {
      height: calc(100% - 36px);
    }

    .line-edit-box {
      height: calc(100% - 36px);
    }
  }
}
</style>
