<template>
  <div class="ball_box">
    <!--    姿态球-->
    <div class="ball">
      <div
        class="img4"
        :style="{
          transform: `scale(2)rotate(${uavRealTimeData.attitude.roll}deg)translate(0,${uavRealTimeData.attitude.pitch}px)`
        }"
      />
      <img
        class="img"
        src="./assets/ball3.png"
        :style="{ transform: `rotate(${uavRealTimeData.attitude.yaw}deg)` }"
      />
      <!-- <img class="img2" src="@/assets/img/ball2.png" /> -->
      <img class="img1" src="./assets/ball1.png" />
      <div class="keducon">
        <img class="kedu" src="./assets/kedu1.png" />
      </div>
      <div class="ball_boxLeft f8 cf">
        <div class="tc mt10">
          <div>
            <span class="middle mr2">
              {{ uavRealTimeData.groundSpeed ? uavRealTimeData.groundSpeed.toFixed(1) : '-' }}</span
            >M/s
            <div class="f12">飞行速度</div>
          </div>
          <div class="mt16">
            <span class="middle mr2">
              {{
                uavRealTimeData.distanceToHome ? uavRealTimeData.distanceToHome.toFixed(0) : '-'
              }}</span
            >M
            <div class="f12">飞行里程</div>
          </div>
        </div>
      </div>
      <div class="ball_boxRight cf">
        <div class="tc mt10 f8">
          <div>
            <span class="middle mr2">
              {{
                uavRealTimeData.gps.relativeAlt ? uavRealTimeData.gps.relativeAlt.toFixed(0) : '-'
              }}</span
            >M
            <div class="f12">飞行高度</div>
          </div>
          <div class="mt16">
            <span class="middle mr2">
              {{
                uavRealTimeData.flyTime ? Moment(uavRealTimeData.flyTime).format('m:s') : '-'
              }}</span
            >Min
            <div class="f12">飞行时间</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Moment from 'moment';
export default {
  props: {},
  data() {
    return { Moment };
  },
  computed: {
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    }
  },
  methods: {}
};
</script>
<style scoped lang="scss">
@font-face {
  font-family: 'UnidreamLED';
  src: url('~@/styles/font/UnidreamLED/UnidreamLED.woff');
}

.ball_box {
  box-sizing: border-box;
  width: 415px;
  height: 222px;
  margin-left: 12px;
  display: flex;
  position: relative;
  justify-content: space-between;
  overflow: hidden;

  .middle {
    font-family: UniDreamLED;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }

  .ball_boxLeft {
    width: 71px;
    position: absolute;
    left: -85px;
    top: 60px;
    z-index: 1;
    height: 109px;
    background-color: #161d2b;
  }

  .ball_boxRight {
    width: 71px;
    position: absolute;
    right: -89px;
    top: 60px;
    z-index: 1;
    height: 109px;
    background-color: #161d2b;
  }

  .ball {
    height: 100%;
    position: relative;
    border-radius: 50%;
    margin: 0 auto;
    z-index: 9999;

    .img {
      height: 93%;
      position: inherit;
      transition: 1s;
      margin-top: 11px;
    }

    .img4 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      background: url('./assets/ball4v2.png');
      background-size: 100% 100%;
    }

    .keducon {
      height: 80%;
      width: 80%;
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      left: 10%;
      top: 10%;
    }

    .kedu {
      height: 65%;
      width: 65%;
      position: absolute;
      border-radius: 50%;
      overflow: hidden;
      left: 16%;
      top: 18%;
    }

    .img2 {
      position: absolute;
      left: -10px;
      width: 5px;
      height: 10px;
    }

    .img1 {
      height: 20px;
      width: 72px;
      position: absolute;
      z-index: 3;
      top: calc(50% - 20px);
      left: calc(50% - 36px);
    }
  }

  .position {
    height: 100%;
    width: 58.1%;
    margin-left: 4.6%;
    background: #296d97;
    box-shadow: 0 2px 8px 1px rgba(11, 48, 77, 0.7);
    border-radius: 10px;

    .first {
      display: flex;
      height: 50%;
      align-items: center;

      svg {
        margin-left: 2rem /* 32/16 */;
      }

      .text {
        margin-left: 2rem /* 32/16 */;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #d9ebff;
        letter-spacing: 0;
        text-align: left;
        font-weight: 400;
      }
    }

    .divider {
      border-top: 1px solid #0e4465;
      height: 1px;
      width: 100%;
    }
  }
}
</style>
