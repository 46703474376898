<template>
  <div class="flight-task-list">
    <div class="search_box task">
      <div v-hover class="btn_create" @click="change_active(4)">
        <span class="iconfont icon-xinzengtaizhang" />
        <span>任务创建</span>
      </div>
      <el-input v-model="taskText" placeholder="请输入任务名称" prefix-icon="el-icon-search" />
      <el-select v-model="status" placeholder="请选择执行状态">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <div v-hover class="btn_search" @click="goSearch">查询</div>
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">任务名称</div>
        <div class="tb-hd">航线名称</div>
        <div class="tb-hd">执行状态</div>
        <div class="tb-hd">责任单位</div>
        <div class="tb-hd">创建时间</div>
        <div class="tb-hd">无人机</div>
        <div class="tb-hd">操作</div>
      </div>
      <div class="tb-bd-box">
        <div v-for="item in list" :key="item.id" class="tb-tr">
          <div class="td">
            {{ item.taskName || '暂无' }}
          </div>
          <div class="td">
            {{ item.routeName || '暂无' }}
          </div>
          <div class="td" :style="{ color: item.taskStatus == 2 ? '#FA8100 ' : '#C6CCD4' }">
            {{ taskTypeName(item.taskStatus) || '暂无' }}
          </div>
          <div class="td">
            {{ item.organizationName || '暂无' }}
          </div>
          <div class="td">
            {{ item.createWhen || '暂无' }}
          </div>
          <div
            class="td"
            :style="{
              opacity: item.deviceStatus == 1 ? '1' : '0.5',
              color: item.deviceStatus == 1 ? '#a4ff64' : ''
            }"
          >
            {{ item.deviceName || '暂无' }}
          </div>
          <div class="td">
            <el-tooltip v-hover effect="dark" content="编辑" placement="top">
              <div class="iconfont icon-bianji" @click="goEdit(item)" />
            </el-tooltip>
            <el-tooltip v-hover effect="dark" content="详情" placement="top">
              <div
                v-if="item.taskStatus == 2 || item.taskStatus == 3"
                class="iconfont icon-chakan"
                :style="{
                  cursor:
                    item.taskStatus == 2 || item.taskStatus == 3 ? 'pointer' : ['not-allowed'],
                  opacity: item.taskStatus == 2 || item.taskStatus == 3 ? '1' : '0.5'
                }"
                @click="goControlBar(item)"
              />
            </el-tooltip>
            <el-tooltip v-hover effect="dark" content="执行" placement="top">
              <div
                v-if="item.taskStatus == 1"
                class="iconfont icon-zhihang"
                :style="{
                  cursor: item.taskStatus == 1 ? 'pointer' : ['not-allowed'],
                  opacity: item.taskStatus == 1 ? '1' : '0.5'
                }"
                @click="goExecute(item)"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top">
              <div
                v-hover
                class="iconfont icon-shanchu"
                :class="{ disable: item.taskStatus != 1 }"
                @click="deleteItem(item)"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      background
      layout="prev, pager, next, jumper"
      :total="pageTotal"
      :page-size="page.size"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { flightTaskAPI } from '@/api';
import { mapMutations } from 'vuex';

export default {
  inject: ['change_active', 'c_dashBoard', 'get_flight_info', 'send_to_task_tooptip'],
  data() {
    return {
      options: Object.freeze([
        { label: '待执行', value: 1 },
        { label: '执行中', value: 2 },
        { label: '已完成', value: 3 }
      ]),
      status: '',
      taskText: '',
      list: [],
      pageTotal: 0, // 总条数
      page: {
        num: 1, // 页码
        size: 10, // 页面条数
        status: null, // 飞行状态
        taskName: null // 任务名称
      },
      typeGroup: [
        {
          id: 1,
          name: '待执行'
        },
        {
          id: 2,
          name: '执行中'
        },
        {
          id: 3,
          name: '已完成'
        }
      ]
    };
  },

  methods: {
    ...mapMutations(['SET_UAVSTATE']),
    taskTypeName(type) {
      this.type = type;
      for (const obj of this.typeGroup) {
        if (obj.id == type) {
          return obj.name;
        }
      }
    },

    deleteItem(item) {
      flightTaskAPI.deleteTask(item.id).then((resp) => {
        if (resp.code == 200) {
          this.$message({
            type: 'success',
            message: resp.msg,
            duration: 1000
          });
          setTimeout(() => {
            this.getFlightTaskList(this.page.num);
          }, 500);
        } else {
          this.$message({
            type: 'error',
            message: resp.msg,
            duration: 1000
          });
        }
      });
    },

    // 跳转至编辑页
    goEdit(item) {
      if (item.taskStatus == 1) {
        this.get_flight_info(item);
        this.change_active(6);
      } else {
        this.$message({
          type: 'warning',
          message: '请检查任务状态是否为待执行!',
          duration: 1000
        });
      }
    },
    // 获取任务列表
    getFlightTaskList(num) {
      const param = {
        num: num,
        size: this.page.size,
        status: this.status,
        taskName: this.taskText
      };
      const forms = new FormData();
      forms.append(param);
      flightTaskAPI.getFlightList(forms).then((resp) => {
        this.list = resp.data.data;
        this.pageTotal = resp.data.totalNums;
      });
    },

    // 点击页码
    handleCurrentChange(val) {
      this.page.num = val;
      this.getFlightTaskList(val);
    },
    // 搜索任务
    goSearch() {
      this.getFlightTaskList(1);
    },

    // 查看详情
    goControlBar(item) {
      if (item.taskStatus == 2 || item.taskStatus == 3) {
        this.change_active(0);
        this.c_dashBoard.isShow = true;
        this.get_flight_info(item);
      }
    },

    // 执行
    goExecute(item) {
      // this.$store.state.deviceName1 = item.deviceName;
      // this.$store.state.routeName1 = item.routeName;
      // this.$store.state.taskName1 = item.taskName;
      if (item.taskStatus == 1) {
        this.change_active(7);
        //* **判断状态 */
        if (item.deviceStatus == 1) {
          this.SET_UAVSTATE(true); // 在线
          this.send_to_task_tooptip(item);
        } else {
          this.SET_UAVSTATE(false); // 不在线
          this.send_to_task_tooptip('');
        }
      }
    }
  },
  mounted() {
    this.getFlightTaskList(this.page.num);
    const that = this;
    this.$bus.$on('refreshList', () => {
      that.getFlightTaskList(this.page.num);
    });
  }
};
</script>

<style lang="scss" scoped>
.flight-task-list {
  @import '~@/styles/list-common.scss';
  height: calc(100% - 64px);
  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;
    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;
      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }
    &::v-deep {
      .el-input {
        width: 455px;
        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
      .el-input__inner {
        color: #fff;
      }
    }
  }
  .search_box.task {
    &::v-deep {
      .el-input {
        width: 211px;
        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }

  &.disable {
    cursor: not-allowed;
  }

  .forbidden {
    color: red;
  }

  #taskType_bg1 {
    color: white;
  }
  #taskType_bg2 {
    color: orange;
  }
  #taskType_bg3 {
    color: grey;
  }
}
</style>
