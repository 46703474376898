<script>
import * as echarts from 'echarts';
import HeaderSearch from '../header-search/index.vue';
import StatisticsCard from '../statistics-card/index.vue';
import GasWarningInfoModal from '../gas-warning-info-modal/index.vue';
import { EarlyWarningAPI } from '@/api';
import moment from 'moment/moment';
import { saveAs } from 'file-saver';
export default {
  name: 'Index',
  components: {
    HeaderSearch,
    StatisticsCard,
    GasWarningInfoModal
  },
  data() {
    return {
      statisticsList: [
        {
          id: 1,
          label: '总挂载数量',
          value: 0,
          unit: '次',
          key: 'mountTotal'
        },
        {
          id: 2,
          label: '总检测次数',
          value: 0,
          unit: '次',
          key: 'detectTotal'
        },
        {
          id: 3,
          label: '总检测合格次数',
          value: 0,
          unit: '次',
          key: 'passCnt'
        },
        {
          id: 4,
          label: '总预警数量',
          value: 0,
          unit: '次',
          key: 'alertCnt'
        }
      ],
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      },
      tableData: [],
      gasWarningInfoModal: false,
      dateDictionary: [
        {
          key: 'month',
          value: {
            startTime: moment().startOf('month').format('YYYY-MM-DD') + ' 00:00:00',
            endTime: moment().endOf('month').format('YYYY-MM-DD') + ' 23:59:59'
          }
        },
        {
          key: 'week',tabPosition
        },
        {
          key: 'today',
          value: {
            startTime: moment().format('YYYY-MM-DD') + ' 00:00:00',
            endTime: moment().format('YYYY-MM-DD') + ' 23:59:59'
          }
        }
      ],
      tabPosition: 'month',
      currentDate: undefined,
      chartData: [
        { value: 0, name: 'SO2预警次数占比', key: 'so2AlertCnt' },
        { value: 0, name: 'CO预警次数占比', key: 'coAlertCnt' },
        { value: 0, name: 'NO2预警次数占比', key: 'no2AlertCnt' },
        { value: 0, name: 'O3预警次数占比', key: 'oxAlertCnt' },
        { value: 0, name: 'PM1.0预警次数占比', key: 'pm1dot0AlertCnt' },
        { value: 0, name: 'PM2.5预警次数占比', key: 'pm2dot5AlertCnt' },
        { value: 0, name: 'PM10预警次数占比', key: 'pm10AlertCnt' }
      ],
      currentGasObj: undefined
    };
  },
  mounted() {
    const dateObj = this.dateDictionary.find((v) => v.key === this.tabPosition);
    if (dateObj) {
      this.currentDate = dateObj.value;
      this.getMarkGasWarringChart(dateObj.value);
      this.getGasGroupByMount();
    }
  },
  methods: {
    initPie() {
      const chartDom = document.getElementById('echarts-1');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'center',
          right: '10',
          orient: 'vertical',
          textStyle: {
            color: '#fff'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              normal: {
                color: (params) => {
                  const colorList = [
                    '#50FFB5',
                    '#FFF250',
                    '#FF7B50',
                    '#5B50FF',
                    '#FF5050',
                    '#50ABFF',
                    '#B050FF'
                  ];
                  return colorList[params.dataIndex];
                }
              }
            },
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: this.chartData.map((v) => ({ name: v.name, value: v.value }))
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },
    initHistogram() {
      const chartDom = document.getElementById('echarts-2');
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: 'item'
        },
        xAxis: {
          type: 'category',
          data: ['SO2', 'CO', 'NO2', 'O3', 'PM1.0', 'PM2.5', 'PM10'],
          axisLabel: {
            color: '#fff'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          }
        },
        yAxis: {
          name: '预警次数',
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#fff'
            }
          },
          axisLabel: {
            color: '#fff'
          },
          alignTicks: true
        },
        series: [
          {
            data: this.chartData.map((v) => v.value),
            type: 'bar',
            barWidth: '13',
            label: {
              show: true,
              position: 'top',
              textStyle: {
                color: '#fff'
              }
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: 'rgba(0,58,255)' },
                { offset: 1, color: '#159AFF' }
              ])
            }
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize();
      });
    },
    // 气体预警统计数据
    getMarkGasWarringChart(dateObj) {
      EarlyWarningAPI.getMarkGasWarringChart(dateObj).then((res) => {
        if (res.data) {
          this.chartData.forEach((v) => {
            if (Object.keys(res.data).includes(v.key)) {
              v.value = res.data[v.key];
            }
          });
          this.statisticsList.forEach((v) => {
            if (Object.keys(res.data).includes(v.key)) {
              v.value = res.data[v.key].toLocaleString();
            }
          });
          this.initPie();
          this.initHistogram();
        }
      });
    },
    tabChangeEvent(value) {
      this.tabPosition = value;
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      const dateObj = this.dateDictionary.find((v) => v.key === value);
      if (dateObj) {
        this.currentDate = dateObj.value;
        this.getMarkGasWarringChart(dateObj.value);
        this.getGasGroupByMount();
      }
    },
    searchSubmit({ startDate, endDate }) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = 10;
      this.currentDate = { startTime: startDate + ' 00:00:00', endTime: endDate + ' 23:59:59' };
      this.getMarkGasWarringChart(this.currentDate);
      this.getGasGroupByMount();
      this.tabPosition = '';
    },
    // 气体预警-分页
    getGasGroupByMount() {
      EarlyWarningAPI.getGasGroupByMount({
        pageNum: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        ...this.currentDate
      }).then((res) => {
        this.tableData = res.data.records || [];
        this.pagination.totalCount = res.data.total || 0;
      });
    },
    // 表格数据-分页
    handleCurrentChange(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getGasGroupByMount();
    },
    //   查看预警
    checkWarningInfoClick(row) {
      this.currentGasObj = row;
      this.gasWarningInfoModal = true;
    },
    gasWarningModalClose() {
      this.gasWarningInfoModal = false;
    },
    //   导出预警
    exportGasWarning(row) {
      EarlyWarningAPI.exportMarkGasWarring({
        ...this.currentDate,
        mountHardId: row.mountHardId,
        pageNum: 1,
        pageSize: 99999
      }).then((res) => {
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        saveAs(blob, `${row.mountHardId}.xls`);
      });
    }
  }
};
</script>

<template>
  <div class="gas-panels animate__animated animate__bounceInLeft">
    <header-search
      :tab-position="tabPosition"
      @tabChangeEvent="tabChangeEvent"
      @searchSubmit="searchSubmit"
    />
    <div class="echarts-wrap">
      <div id="echarts-1" class="echarts-1"></div>
      <div id="echarts-2" class="echarts-2"></div>
    </div>
    <statistics-card :statistics-list="statisticsList" />
    <div class="statistics-table">
      <el-table style="width: 100%" height=" calc(100% - 50px)" :data="tableData" border>
        <el-table-column label="序号" align="center">
          <template v-slot="{ $index }">{{ $index + 1 }}</template>
        </el-table-column>
        <el-table-column label="气体检测挂载ID" align="center" prop="mountHardId">
        </el-table-column>
        <el-table-column label="更新时间" align="center" prop="lastUpdateTime"> </el-table-column>
        <el-table-column label="检测次数" align="center" prop="detectTotal"> </el-table-column>
        <el-table-column label="合格次数" align="center" prop="passCount"> </el-table-column>
        <el-table-column label="预警数量" align="center" prop="alertCount"> </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="查看预警" placement="top">
              <img
                v-hover
                src="~@/assets/home/subplatForm/icon/find.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="checkWarningInfoClick(scope.row)"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="导出预警" placement="top">
              <img
                v-hover
                src="~@/assets/image/icons/export.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="exportGasWarning(scope.row)"
              />
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total,prev, pager, next"
          :total="pagination.totalCount"
          :page-size="pagination.pageSize"
          :current-page.sync="pagination.pageNo"
          :page-sizes="[10, 20, 30, 50]"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!--查看预警-->
    <gas-warning-info-modal
      v-if="gasWarningInfoModal"
      :show="gasWarningInfoModal"
      :current-gas-obj="currentGasObj"
      :current-date="currentDate"
      @close="gasWarningModalClose"
    />
  </div>
</template>

<style scoped lang="scss">
.gas-panels {
  position: fixed;
  top: 54px;
  left: 450px;
  right: 60px;
  height: calc(100vh - 60px);
  background-color: #000;
  border-radius: 5px;
  .echarts-wrap {
    height: 358px;
    display: flex;
    .echarts-1,
    .echarts-2 {
      flex: 1;
    }
  }
  .statistics-table {
    margin-top: 8px;
    height: calc(100% - 488px);
    padding: 0 20px;
    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
}
</style>
