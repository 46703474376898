<!-- 右侧控制栏 -->
<template>
  <div v-if="LoginState" class="cpt-control-tab">
    <div ref="tabBox" class="tab-box">
      <div
        v-if="permissionFn(460)"
        id="tab_AI tab_item"
        class="tab_AI tab_item"
        :class="{ active: activeDom == 'AI' }"
      >
        <div v-hover class="tab_item_icon" @click="active_ai">
          <span class="icon icon--AI">&#xe69a;</span>
        </div>
        <div class="tab_title">识别</div>
        <el-collapse-transition class="showAi">
          <div
            v-show="activeDom == 'AI'"
            class="ailist-wrap"
            :class="{ hangar: currentEagle }"
          >
            <div class="ailist" style="height: 130px">
              <div
                v-for="(item, index) in AiList"
                :key="index"
                class="img_items"
              >
                <div
                  ref="back_img"
                  class="img_item"
                  @click="active_ailist_item(item, index, $event)"
                >
                  <div class="img_item_text">
                    <span
                      class="item_text_img"
                      :class="{
                        selected_text: index === currentIndex,
                      }"
                      >{{ item.name }}</span
                    >
                  </div>
                  <img
                    crossorigin="anonymous"
                    :src="item.icon_url"
                    alt
                    :class="{
                      selected: index === currentIndex,

                      images_item: index != currentIndex,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>
      <!-- 鹰巢 -->
      <div
        v-if="permissionFn(554) && currentEagle"
        class="tab_uav_control tab_item eagle-tab"
        :class="{ active: activeDom == 'NEST' }"
      >
        <div v-hover class="tab_item_icon" @click="active_eagleNest_uav">
          <div class="icon icon--eagle" />
        </div>
        <div class="tab_title">机巢</div>
        <el-collapse-transition>
          <div v-show="activeDom == 'NEST'" class="eagle-operate">
            <div class="eagle-operate-header">
              <div class="title">机巢</div>
            </div>
            <div class="eagle-operate-list">
              <div
                v-for="(v, i) in eagleOperateList"
                :key="v.id"
                class="operate-item"
                :class="{ 'operate-item-active': v.isSelect }"
                @click="eagleOperateEvent(v, i)"
              >
                <div class="item-icon">
                  <img :src="v.icon" />
                </div>
                <div class="item-name">{{ v.name }}</div>
              </div>
            </div>
            <!--运行监测-->
            <el-collapse-transition>
              <eagle-monitoring v-show="eagleMonitoringShow" />
            </el-collapse-transition>
          </div>
        </el-collapse-transition>
      </div>
      <div
        v-if="permissionFn(456)"
        class="tab_uav_control tab_item"
        :class="{ active: activeDom == 'UAV' }"
        @click="active_uav"
      >
        <div v-hover class="tab_item_icon">
          <span class="icon icon--uav">&#xe69c;</span>
        </div>
        <div class="tab_title">无人机</div>
      </div>
      <div
        v-show="permissionFn(462)"
        class="tab_uav_control tab_item tab_mounts"
        :class="{ active: activeDom == 'MOUNTE' }"
      >
        <div @click="selectMountFn">
          <div v-hover class="tab_item_icon">
            <span class="icon icon--mount">&#xe69c;</span>
          </div>
          <div class="tab_title">挂载</div>
        </div>
        <!-- <el-collapse-transition class="showAi"> -->
        <div v-if="activeDom == 'MOUNTE'" class="mounts-wrap">
          <div class="modal-header">
            <div class="modal-header__title">挂载</div>
          </div>
          <div class="mount-list">
            <div
              v-for="item in mountList"
              :key="item.mountId"
              class="mount-item"
              :class="{
                active:
                  (selectMount && selectMount.gimbalName) === item.gimbalName,
              }"
              @click="onOpenMount(item)"
            >
              <div class="mount-icon">
                <img :src="item.icon" width="100%" height="100%" />
              </div>
            </div>
          </div>
        </div>
        <!-- </el-collapse-transition> -->
        <component
          :is="selectMount.gimbalName"
          v-if="selectMount"
          class="mount-panel"
          @directive="mountDirective"
          @take_photo="mountTakePhoto"
          @close="mountClose"
        />
        <!-- 挂载调试 -->
        <!-- <mountDom v-if="mountDomFlag" @close="mountDomFlag = false"></mountDom> -->
      </div>
      <div
        v-if="permissionFn(457)"
        class="tab_video tab_item tab_mounts"
        :class="{ active: activeDom == 'VIEWLIBRARY' }"
        @click="activeDom = activeDom == 'VIEWLIBRARY' ? null : 'VIEWLIBRARY'"
      >
        <div v-hover class="tab_item_icon">
          <span class="icon icon--lib">&#xe69b;</span>
        </div>
        <div class="tab_title">视图库</div>
      </div>
      <!--鹰巢日志-->
      <EagleLog v-if="eagleLogModalShow" @close="eagleLogModalClose" />
    </div>
    <div v-show="ad" class="advertisement">
      <div v-interact class="header">
        <span class="title">提示</span>
        <span class="iconfont icon-guanbi" @click="ad = false" />
      </div>
      <div class="body">
        <div class="block">
          您好！当前认证为M0！，如需使用可联系专属客服进行认证升级，
          <br />联系电话：
          <span style="color: #1eb9fa">400-808-2021</span>
        </div>
        <div class="image">
          <img src="../../assets/home/qrcode.png" alt />
        </div>
      </div>
      <span class="footer">
        <div class="cancel_btn" @click="ad = false">取 消</div>
        <div type="primary" class="confirm_btn" @click="ad = false">确 定</div>
      </span>
    </div>
    <OperationMonitoring  @close="closeOperationLog" v-if="showOperationLog"></OperationMonitoring>
  </div>
</template>

<script>
import { AI, Tg } from "@/api";
import OperationMonitoring from "@/components/observe1/operationMonitoring";
import { mapState, mapMutations } from "vuex";
import Qs from "qs";
import imgDemo from "@/assets/basic-tools/demo.png";
import imgControl from "@/assets/basic-tools/control.png";
import imgLive from "@/assets/basic-tools/live.png";
import imgAI from "@/assets/basic-tools/AI.png";
import mount from "@/components/mount";
import { permissionIds } from "@/utils/permission";
import { deviceStatusDictionary } from "@/utils/dictionary";
import EagleLog from "../../components/observe1/operationMonitoring/index.vue";
import EagleMonitoring from "@/components/eagle-nest/eagle-monitoring/index.vue";
// 挂载调试
import mountDom from "@/components/mount/MMC_Gimbal_HT40R/index.vue";
// 所有的挂载组件
const mountComponents = {};
mount.list.forEach((item) => {
  mountComponents[item.name] = item.component;
});

export default {
  components: {
    ...mountComponents,
    EagleLog,
    EagleMonitoring,
    mountDom,
    OperationMonitoring
  },
  data() {
    return {
      mountDomFlag: true,
      images_item: "images_item",
      // isshow: {
      //   old: "images_item",
      //   new: "",
      // },
      // images_item: { images_item },
      isSelected: false,
      ad: false,
      pay_list: false,
      // active_list: false,
      AiList: [],
      aiArr: [],
      currentIndex: -1,
      taskName: null, // 航线名称
      LoginState: false,
      imgDemo,
      imgControl,
      imgLive,
      imgAI,
      uavName: null,
      showMount: false,
      eagleOperateList: [
        {
          id: 1,
          name: "出舱",
          icon: require("@/assets/uavApplications/eagle/egress.png"),
          cmdControlType: 2059,
          isSelect: false,
          connectIndex: 1,
        },
        {
          id: 2,
          name: "入舱",
          icon: require("@/assets/uavApplications/eagle/end-charge.png"),
          cmdControlType: 2073,
          isSelect: false,
          connectIndex: 0,
        },
        {
          id: 3,
          name: "开始充电",
          icon: require("@/assets/uavApplications/eagle/start-charge.png"),
          cmdControlType: 2060,
          isSelect: false,
          connectIndex: 3,
        },
        {
          id: 4,
          name: "结束充电",
          icon: require("@/assets/uavApplications/eagle/end-charge.png"),
          cmdControlType: 2071,
          isSelect: false,
          connectIndex: 2,
        },
        {
          id: 5,
          name: "预热",
          icon: require("@/assets/uavApplications/eagle/preheat.png"),
          cmdControlType: 2072,
          isSelect: false,
          connectIndex: 5,
        },
        {
          id: 6,
          name: "休眠",
          icon: require("@/assets/uavApplications/eagle/hibernate.png"),
          cmdControlType: 2061,
          isSelect: false,
          connectIndex: 4,
        },
        // {
        //   id: 7,
        //   name: "运行日志",
        //   icon: require("@/assets/uavApplications/eagle/log.png"),
        //   isSelect: false,
        // },
        {
          id: 8,
          name: "退出流程",
          icon: require("@/assets/uavApplications/eagle/exit.png"),
          cmdControlType: 100001,
          isSelect: false,
        },
        {
          id: 9,
          name: "运行监测",
          icon: require("@/assets/uavApplications/eagle/monitor.png"),
          isSelect: false,
        },
      ], // 鹰巢可操作列表
      preOperateIndex: -1, // 前置操作流程
      userInfo: JSON.parse(window.localStorage.getItem("user_info")),
      eagleLogModalShow: false,
      eagleMonitoringShow: false,
      activeDom: null,
      showOperationLog:false,
    };
  },
  inject: [
    "c_demo",
    "c_dashBoard",
    "c_player",
    "active_ailist",
    "c_player_ai",
    "flight_info",
  ],
  computed: {
    ...mapState(["ai_url_obj", "showDemo", "active"]),
    getTaskName() {
      return this.flight_info();
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    currentEagle() {
      return this.$store.state.uavApplicationsEagleNest.currentEagle;
    },
    // 是否显示视频
    showVideo() {
      return this.$store.state.uavApplications.showVideo;
    },
    // 挂载列表
    mountList() {
      return this.$store.state.uavApplications.mountList;
    },
    // 选择的挂载
    selectMount: {
      get() {
        return this.$store.state.uavApplications.selectMount;
      },
      set(newVal) {
        this.$store.commit("uavApplications/setState", {
          key: "selectMount",
          value: newVal,
        });
      },
    },
    mqttDataSet() {
      return this.$store.state.MMCMQTT?.dataSet?.[this.currentEagle?.hardId];
    },
  },
  watch: {
    "$store.state.uavApplications.showOperationLog": {
      handler(newval) {
      this.showOperationLog =newval
      },
      deep: true,
    },
    // 清除选中样式
    "$store.state.uavApplications.showMenu"(val) {
      if (!val) {
        if (this.activeDom == "UAV") {
          this.activeDom = null;
        }
      }
    },
    "$store.state.uavApplications.showViewLibrary"(val) {
      if (!val) {
        if (this.activeDom == "MOUNTE") {
          // this.activeDom = null;
        }
      }
    },
    activeDom(val) {
      // 是否打开鹰巢控制栏
      this.$store.commit("uavApplicationsEagleNest/setState", {
        key: "showMenu",
        value: val == "NEST",
      });
      // 是否打开无人机控制栏
      this.$store.commit("uavApplications/setState", {
        key: "showMenu",
        value: val == "UAV",
      });
      // 是否打开视图库
      this.$store.commit("uavApplications/setState", {
        key: "showViewLibrary",
        value: val == "VIEWLIBRARY",
      });
    },
    getTaskName(val) {
      this.taskName = val.taskName;
    },
    showVideo(newVal) {
      if (!newVal && this.activeDom == "AI") {
        this.activeDom = null;
      }
    },
    mqttDataSet(value) {
      // console.log('鹰巢数据-->', value);
    },
  },
  created() {},
  mounted() {
    
    console.log(this.mountList, "mountList");
    this.getLoginState();
    // this.getuser();
    this.getAiList();
    this.$bus.$on("clickCloseAi", () => {
      this.currentIndex = -1;
    });
    this.$bus.$on("handleControlBar", (e) => {
      this.clickConTrolBar(e);
    });
    this.$bus.$on("changeUavName", (e) => {
      this.uavName = e;
    });
  },
  methods: {
    ...mapMutations(["SET_SHOW_DEMO"]),
    closeOperationLog() {
      this.$store.commit("uavApplications/setState", {
                  key: "showOperationLog",
                  value: false,
                });
    },
    selectMountFn() {
      this.$nextTick(function () {
        this.selectMount = null;
        this.activeDom = this.activeDom == "MOUNTE" ? null : "MOUNTE";
      });
    },
    permissionFn(pid) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    active_uav() {
      if (this.$uav) {
        // this.showMenu = !this.showMenu;
        this.activeDom = this.activeDom == "UAV" ? null : "UAV";
        this.$bus.$emit("clickUavTab");
      } else {
        this.$message.warning("当前未打开无人机，无法操作飞行!");
      }
    },
    active_eagleNest_uav() {
      if (this.currentEagle) {
        if (this.activeDom != "NEST") {
          this.activeDom = "NEST";
        } else {
          this.activeDom = null;
        }
        this.eagleMonitoringShow = false;
        this.eagleOperateList = this.eagleOperateList.map((v) => ({
          ...v,
          isSelect: false,
        }));
        // this.$bus.$emit('clickUavTab');
      } else {
        this.$message.warning("当前未打开鹰巢，无法操作鹰巢!");
      }
    },
    async active_ailist_item(item, index) {
      if (this.currentIndex > -1) {
        AI.destroyAi(
          Qs.stringify({
            detectortype: this.currentIndex,
            videourl: this.ai_url_obj.vUrl,
          })
        );
      }
      this.currentIndex = index;
      this.$emit("changeAi", item, this.currentIndex);
    },

    handleClose() {
      this.ad = false;
    },
    // 激活广告
    active_ad() {
      this.ad = true;
    },
    active_ai() {
      if (this.$uav) {
        if (this.showVideo) {
          this.activeDom = this.activeDom == "AI" ? null : "AI";
        } else {
          this.$message.warning("请先打开直播,选择播放源后再开启AI");
        }
      } else {
        this.$message.warning("当前未打开无人机，无法查看视频！");
      }
    },
    // 激活一键演示
    active_demo() {
      this.SET_SHOW_DEMO(true);
    },
    getuser() {},

    async getAiList() {
      const data = await AI.getAiList();
      data.data.forEach((item) => {
        this.AiList.push({
          ...item,
          isChange: false,
        });
      });
      // 演示专用 无ai效果
      this.AiList.unshift({
        name:"裸土识别",
        icon_url:require("./assets/裸土识别_ON.svg" ),
        isChange: false,
      })
      this.AiList.unshift({
        name:"头盔识别",
        icon_url:require("./assets/头盔识别_ON.svg" ),
        isChange: false,
      })
    },
    clickConTrolBar(e) {
      if (window.localStorage.getItem("tmj_token") && this.$refs.tabBox) {
        if (e) {
          this.$refs.tabBox.style.right = "362px";
        } else {
          this.$refs.tabBox.style.right = "4px";
        }
      }
    },
    getLoginState() {
      this.LoginState = !!window.localStorage.getItem("tmj_token");
    },
    /**
     * 打开挂载
     */
    onOpenMount(item) {
      console.log("onOpenMount");
      if (find) {
        this.selectMount = item;
        this.activeDom = null;
      }
    },
    /**
     * 挂载指令事件
     */
    mountDirective(buffer) {
      this.$store.dispatch("uavApplications/mountDirective", {
        buffer,
        mountId: this.selectMount.mountId,
      });
    },
    /**
     * 挂载拍照
     */
    mountTakePhoto(name) {
      console.log("挂载拍照 ", name);
    },
    /**
     * 挂载窗口关闭
     */
    mountClose() {
      this.selectMount = null;
    },
    // 鹰巢操控点击
    eagleOperateEvent(item, index) {
      if (item.cmdControlType) {
        if (!this.$uav.currentOperator) {
          const messageValue = {
            title: "安全确认",
            message: `请确认是否接管${this.currentEagle.name}且进行${item.name}操作？`,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            fun: async () => {
              const res = await Tg.setUavControlOn({
                force: false,
                id: this.$uav.id,
                uid: this.userInfo.id,
              });
              if (res.code === 200) {
                this.$message.success(`已成功接管${this.$uav.hardId}`);
                this.$store.commit("uavApplications/setState", {
                  key: "uav",
                  value: {
                    ...this.$uav,
                    currentOperator: this.userInfo.id,
                  },
                });
                this.controlEagleEvent(item, index);
              }
            },
          };
          this.messageBox(messageValue);
        } else {
          this.controlEagleEvent(item, index);
        }
      }
      //   鹰巢运行日志
      if (item.id === 7) {
        item.isSelect = true;
        this.eagleLogModalShow = true;
      }
      // 鹰巢运行监测
      if (item.id === 9) {
        item.isSelect = !item.isSelect;
        this.eagleMonitoringShow = !this.eagleMonitoringShow;
      }
    },
    controlEagleEvent(item, index) {
      if (!item.isSelect) {
        const that = this;
        // 取消退出流程选中
        this.eagleOperateList[7].isSelect = false;
        // 清除前置流程选中
        if (this.preOperateIndex !== -1 && item.id === 8) {
          this.eagleOperateList[this.preOperateIndex].isSelect = false;
        }
        // 判断当前机库是否空闲(退出流程可执行)
        if (
          [0, 8].includes(this.mqttDataSet["2065"].data.processStatus) ||
          item.id === 8
        ) {
          // 前置流程index
          this.preOperateIndex = index;
          item.isSelect = true;
          if (item.connectIndex !== undefined) {
            this.eagleOperateList[item.connectIndex].isSelect = false;
          }
          this.$store.dispatch("MMCMQTT/publish", {
            topic: "PROCESS/OBTAIN/" + this.currentEagle.hardId,
            data: {
              cmdControlType: item.cmdControlType,
            },
            callback() {
              that.$message.success("操作成功");
            },
          });
        } else {
          // 获取当前机库状态
          const statusItem = deviceStatusDictionary.find(
            (item) => item.value === this.mqttDataSet["2065"].data.processStatus
          );
          this.$message.warning(statusItem?.label || "");
        }
      }
    },
    messageBox({ title, message, confirmButtonText, cancelButtonText, fun }) {
      if (!title || !message) return console.log("请输入提示标题以及提示内容");
      const value = {
        title,
        message,
        center: true,
      };
      if (confirmButtonText) {
        value.confirmButtonText = confirmButtonText;
      }
      if (cancelButtonText) {
        value.cancelButtonText = "取消";
        value.showCancelButton = true;
      }
      this.$msgbox({
        ...value,
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {
        fun && fun();
      });
    },
    //   鹰巢日志弹窗
    eagleLogModalClose() {
      this.eagleLogModalShow = false;
      this.eagleOperateList[6].isSelect = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.cpt-control-tab {
  .ailist {
    display: flex;
    height: 288px;
    overflow-y: scroll;
    align-items: center;
    align-items: center;
    flex-wrap: wrap;
    width: 193px;
    padding-bottom: 5px;
    box-sizing: border-box;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border: 1px solid #3bc1e5;
    border-radius: 4px;

    .img_item {
      margin-left: 12px;
      margin-bottom: 6px;
      display: flex;
      width: 48px;
      height: 60px;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;

      font-family: MicrosoftYaHei;

      letter-spacing: 0;
      text-align: center;

      .img_item_text {
        color: #b1bbc6;
        display: flex;
        text-align: center;
        font-size: 12px !important;
        width: 48px;
        height: 8px;
        margin-bottom: 10px;

        .item_text_img {
          transform: scale(0.7);
          width: 48px;
          word-break: keep-all;
          white-space: nowrap;
          margin-top: 5px;
        }
      }

      .images_item {
        width: 48px;
        height: 48px;
        background: url("../../assets/ai/AI.png");
        cursor: pointer;
      }

      .images_item:hover {
        background: url("../../assets/ai/AI_hover.png");
        cursor: pointer;
      }
    }

    .selected {
      width: 48px;
      height: 48px;
      background: url("../../assets/ai/AI_Selected.png");
    }

    .selected_text {
      color: #50efff;
    }
  }

  .tab-box {
    box-sizing: border-box;
    width: 52px;
    // height: 256px;
    position: absolute;
    right: 4px;
    top: 15%;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border: 1px solid #3bc1e5;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6px 0;
    transition: right 0.2s;
    z-index: 1000;

    .tab_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 14px;

      &:first-child {
        margin-top: 0;
      }

      // &:hover {
      .tab_item_icon {
        .icon {
          font-size: 0;
          width: 24px;
          height: 24px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .icon--demo {
          background-image: url("./assets/demo.png");
        }

        .icon--control {
          background-image: url("./assets/control.png");
        }

        .icon--live {
          background-image: url("./assets/live.png");
        }

        .icon--AI {
          background-image: url("./assets/ai.png");
        }

        .icon--uav {
          background-image: url("./assets/uav.png");
        }

        .icon--lib {
          background-image: url("./assets/lib.png");
        }

        .icon--mount {
          background-image: url("./assets/mount.png");
        }
        .icon--eagle {
          background-image: url("./assets/eagle.png");
          width: 28px;
          height: 19px;
        }
      }

      // }

      .tab_title {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }

      .tab_item_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        margin-bottom: 2px;
        color: #ffffff;

        .iconfont {
          font-size: 24px;
          // text-align: center;
          line-height: 40px;
        }

        img {
          height: 24px;
          width: 24px;
        }
      }

      &.active {
        .tab_item_icon {
          background: rgba(69, 83, 125, 1);
        }
      }
    }

    .tab_AI {
      position: relative;

      .ailist-wrap {
        position: absolute;
        right: 65px;
        top: 0px;
        z-index: 1;
        &.hangar {
          // top: -59px;
        }
      }
    }

    .tab_mounts,
    .eagle-tab {
      position: relative;
      .mounts-wrap,
      .eagle-operate {
        position: absolute;
        right: 65px;
        top: 0;
        z-index: 1;
        background: rgba(19, 21, 24, 0.7);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
          inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
          inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
          inset 0px 1px 4px 0px #2e63dc;
        border-radius: 10px;
        border: 1px solid rgba(52, 223, 255, 1);
        backdrop-filter: blur(4px);
        padding: 4px;
      }
      .mounts-wrap {
        // top: 52px;
      }
    }
    .eagle-operate {
      width: 385px;
      &-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background: #0a0b0d;
        gap: 12px;
        padding: 8px;
        box-sizing: border-box;
        .operate-item {
          width: 64px;
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #3a4252;
          border-radius: 4px;
          .item-icon {
            img {
              width: 30px;
              height: 30px;
              vertical-align: top;
            }
          }
          .item-name {
            font-size: 12px;
            color: #f8f8f8;
            line-height: 16px;
            margin-top: 7px;
          }
          &-active {
            background-image: url("./assets/active-bk.png");
            background-size: 100% 100%;
            background-color: rgba(30, 32, 38, 1);
          }
        }
      }
    }
  }

  .advertisement {
    position: absolute;
    top: calc(50% - 196px);
    left: calc(50% - 167px);
    width: 434px;
    height: 393px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border-radius: 8px;
    border: 1px solid #34dfff;

    .header {
      height: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      border-radius: 4px 4px 0 0;

      .title {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #ebf5ff;
      }

      .iconfont {
        font-size: 18px;
        color: #b3bbc5;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .body {
      height: calc(100% - 95px);
      width: calc(100% - 8px);
      margin: 0 auto;
      box-sizing: border-box;
      padding: 4px 6px;
      border-bottom: 1px solid #494949;
      border-top: 1px solid #494949;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .block {
        margin-bottom: 12px;
        font-family: MicrosoftYaHei-Bold;
        font-size: 14px;
        color: #ebf5ff;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;
      }

      .images {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .footer {
      margin-top: 12px;
      display: flex;
      justify-content: space-evenly;

      .cancel_btn {
        background: #3a4252;
        border: 1px solid #3bc1e5;
      }

      .confirm_btn {
        background: #1890ff;
        border: 1px solid #3bc1e5;
      }

      .cancel_btn,
      .confirm_btn {
        width: 92px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #f8f8f8;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}

.gray {
  opacity: 0.5;
  filter: gray;
}

.mount-panel {
  position: absolute;
  left: -490px !important;
  top: 0 !important;
}

.mount-list {
  box-sizing: border-box;
  min-width: 84px;
  min-height: 80px;
  gap: 12px;
  display: flex;
  background: #0a0b0d;
  border-radius: 0px 4px 4px 4px;
  padding: 8px;
  flex-wrap: wrap;
  width: 308px;

  .mount-item {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3a4252;
    border-radius: 4px;
    flex-shrink: 0;

    &.active {
      background-image: url("./assets/active-bk.png");
      background-size: 100% 100%;
      background-color: rgba(30, 32, 38, 1);
    }

    .mount-icon {
      height: 40px;
      flex-shrink: 0;
    }

    .mount-title {
      word-break: break-all;
      width: 100%;
      color: #fff;
      font-size: 12px;
    }
  }
}

.modal-header,
.eagle-operate-header {
  height: 29px;
  border-radius: 9px 9px 0px 0px;

  .modal-header__title,
  .title {
    width: 80px;
    height: 29px;
    line-height: 29px;
    text-align: center;
    background: #0a0b0d;
    font-size: 14px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #a4ff64;
    border-radius: 4px 4px 0 0;
  }
}
// ::v-deep {
//   .el-icon-close {
//     right: 12px !important;
//     top: 20px !important;
//   }
// }
</style>
