<template>
  <div>
    <!-- 网点展示详情 -->
    <div class="popup-branch-body">
      <div v-interact class="popup-branch-header">
        <span class="title">网点详情</span>
        <span v-hover class="iconfont icon-guanbi" @click="close()" />
      </div>
      <div class="popup-branch-content">
        <div class="content-left">
          <div class="up">
            <img src="../../../assets/image/company.png" alt="uav" width="100%" height="100%" />
          </div>
          <div class="down">
            <div class="btn-box">
              <div class="btn" style="background: #1eb9fa">主展区(大门)</div>
              <div class="btn" />
              <div class="btn" />
              <div class="btn" />
            </div>
          </div>
        </div>
        <div class="content-right">
          <span>详情: </span>
          <div class="content">
            <div class="tb-box">
              <div class="tb-hd-box">
                <div class="tb-hd">网点名称</div>
                <div class="tb-hd" style="height: 138px">网点业务描述</div>
                <div class="tb-hd">联系人</div>
                <div class="tb-hd">联系人手机</div>
              </div>
              <div class="tb-bd-box">
                <div class="td">
                  {{ list.name || '暂无' }}
                </div>
                <div class="td" style="height: 141px">
                  {{ list.address || '暂无' }}
                </div>
                <div class="td">
                  {{ '暂无' }}
                </div>
                <div class="td">
                  {{ '暂无' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemoAbout',
  inject: ['side_list_item', 'change_active'],
  data() {
    return {
      list: {}
    };
  },

  mounted() {
    // this.list = this.side_list_item()
    // console.log("网点详情....", this.list);
    this.setnetworkDataLils(0);
  },

  methods: {
    setnetworkDataLils() {
      this.$bus.$on('networkDataLils', (data) => {
        console.log('1111111111', data);
        this.list = data;
      });
    },
    close() {
      this.change_active(0);
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-branch-body {
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;
  width: 728px !important;
  height: 383px !important;
  position: absolute;
  text-align: left;
  padding: 0;
  margin: 0;
  top: calc(50% - 198px);
  left: calc(50% - 354px);
  .popup-branch-header {
    font-size: 14px;
    padding-left: 10px;
    height: 48px;
    line-height: 48px;
    color: #a4ff64;
    border-bottom: 1px solid #494949;
    .title {
      color: #a4ff64;
    }
    .iconfont {
      float: right;
      height: 48px;
      line-height: 48px;
      margin-right: 14px;
      color: white;
      font-size: 18px;
    }
  }
  .popup-branch-content {
    width: 100%;
    height: 83.5%;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    border-radius: 2px;
    .content-left {
      height: 100%;
      width: 413px;
      background: rgba(0, 0, 0, 0.95);
      border-radius: 2px;
      .up {
        height: 262px;
        background: white;
      }
      .down {
        height: 48px;
        margin-top: 2px;
        .btn-box {
          height: 100%;
          display: flex;
          padding: 3px;
          .btn {
            flex: 1;
            margin: 0px 2px;
            // opacity: 0.3;
            background: #1e2026;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }
    .content-right {
      height: 100%;
      width: 44%;
      margin-left: 8px;
      display: flex;
      flex-direction: column;
      color: #fff;
      position: relative;
      span {
        margin-left: 8px;
        height: 28px;
        line-height: 28px;
        font-weight: 600;
        font-size: 16px;
      }
      .content {
        height: 100%;
        .tb-box {
          margin-top: 4px;
          box-sizing: border-box;
          height: 100%;
          border-collapse: collapse;
          display: flex;
          .tb-hd-box {
            flex: 1;
            height: calc(100% - 6px);
            background: #1e2026;
            // background-image: linear-gradient(
            //   180deg,
            //   rgba(255, 255, 255, 0.05) 0%,
            //   rgba(255, 255, 255, 0) 100%
            // );
            // box-shadow: inset 1px 1px 0 0 #000000;
            // display: flex;
            // flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            .tb-hd {
              height: 48px;
              line-height: 48px;
              text-align: center;
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #b3bbc5;
              letter-spacing: 0;
              font-weight: 400;
              border-left: 1px solid #020310;
              border-bottom: 1px solid #020310;
            }
            // border-right: 1px solid #000;
          }

          .tb-bd-box {
            flex: 1;
            height: calc(100% - 6px);
            // background: #020310;
            background-image: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0.05) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            box-shadow: inset 1px 1px 0 0 #000000;
            box-sizing: border-box;
            flex-direction: column;
            justify-content: space-between;
            .td {
              border-left: 1px solid #000;
              border-right: 1px solid #000;
              height: 48px;
              line-height: 48px;
              border-bottom: 1px solid #000;
              text-align: center;
              word-break: break-word;
              box-sizing: border-box;
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #c6ccd4;
              letter-spacing: 0;
              font-weight: 400;
              padding: 0 6px;
              overflow: hidden;
              // border: 1px solid;
            }
            &:nth-child(odd) {
              background: #14151a;
            }
          }
        }
      }
    }
  }
}
</style>
