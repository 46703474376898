<template>
  <div>
    <!-- 无人机详情页 -->
    <div class="popup-new-body">
      <div v-interact class="popup-new-header">
        <span class="title">无人机详情</span>
        <span v-hover class="iconfont icon-guanbi" @click="close()" />
      </div>
      <div class="popup-new-content">
        <div class="popup-new-content-left">
          <div class="up">
            <img :src="img" alt="uav" width="100%" height="30%" />
          </div>
          <div class="down" />
        </div>
        <div class="popup-new-content-right">
          <div class="popup-new-content-right-up">
            <span class="title">详情: </span>
            <div class="content">
              <div class="tb-box">
                <div class="tb-hd-box">
                  <div class="tb-hd">无人机名称</div>
                  <div class="tb-hd">无人机id</div>
                  <div class="tb-hd">无人机序列号</div>
                  <div class="tb-hd">无人机型号</div>
                </div>
                <div class="tb-bd-box">
                  <div class="td">
                    {{ uavItem.name || '暂无' }}
                  </div>
                  <div class="td">
                    {{ uavItem.hardId || '暂无' }}
                  </div>
                  <div class="td overflow-omission">
                    <el-tooltip class="item" effect="dark" :content="uavItem.sn" placement="top">
                      <span> {{ uavItem.sn || '暂无' }}</span>
                    </el-tooltip>
                  </div>
                  <div class="td overflow-omission">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="uavItem.modelName"
                      placement="top"
                    >
                      <span> {{ uavItem.modelName || '暂无' }}</span>
                    </el-tooltip>
                  </div>
                </div>
                <div class="tb-hd-box">
                  <div class="tb-hd">无人机类型</div>
                  <div class="tb-hd">联网状态</div>
                  <div class="tb-hd" />
                  <!-- <div class="tb-hd">飞行状态</div> -->
                  <div class="tb-hd" />
                </div>
                <div class="tb-bd-box">
                  <div class="td">
                    {{ uavItem.cateName || '暂无' }}
                  </div>
                  <!-- <div class="td">
                    {{ uavItem.flyInfo.flyStatus || "暂无" }}
                  </div> -->
                  <div class="td">
                    <!-- {{ uavItem.ipId ? "已联网" : "未联网" || "暂无" }} -->
                    {{ uavItem.online ? '在线' : '离线' || '暂无' }}
                  </div>
                  <div class="td">&nbsp;</div>
                  <div class="td">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-new-content-right-down">
            <span class="title">健康状态: </span>
            <div class="content">
              <div class="tb-box">
                <div class="tb-hd-box">
                  <div class="tb-hd">陀螺仪状态</div>
                  <div class="tb-hd">加速度计状态</div>
                  <div class="tb-hd">罗盘状态</div>
                  <div class="tb-hd">气压计状态</div>
                  <div class="tb-hd">相对气压差状态</div>
                  <div class="tb-hd">定位模块状态</div>
                  <div class="tb-hd">光流模组状态</div>
                  <div class="tb-hd">机载计算机状态</div>
                  <div class="tb-hd">激光定位模块状态</div>
                  <div class="tb-hd">定高功能模块状态</div>
                </div>
                <div class="tb-bd-box tb-hd-box2">
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                </div>
                <div class="tb-hd-box">
                  <div class="tb-hd">角速度控制状态</div>
                  <div class="tb-hd">角度控制状态</div>
                  <div class="tb-hd">航向控制状态</div>
                  <div class="tb-hd">垂直位置状态</div>
                  <div class="tb-hd">水平位置控制状态</div>
                  <div class="tb-hd">动力状态</div>
                  <div class="tb-hd">遥控器状态</div>
                  <div class="tb-hd">冗余陀螺仪状态</div>
                  <div class="tb-hd">冗余加速度计状态</div>
                  <div class="tb-hd">冗余罗盘状态</div>
                </div>
                <div class="tb-bd-box tb-hd-box2">
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                </div>
                <div class="tb-hd-box">
                  <div class="tb-hd">电子围栏功能状态</div>
                  <div class="tb-hd">姿态解算功能状态</div>
                  <div class="tb-hd">仿地飞行功能状态</div>
                  <div class="tb-hd">电机状态</div>
                  <div class="tb-hd">日志记录功能状态</div>
                  <div class="tb-hd">电池及电源状态</div>
                  <div class="tb-hd">距离检测功能状态</div>
                  <div class="tb-hd">卫星通信状态</div>
                  <div class="tb-hd">起飞检测状态</div>
                  <div class="tb-hd">避障功能模块状态</div>
                </div>
                <div class="tb-bd-box tb-hd-box2">
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                  <div class="td">{{ state || '正常' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemoHome',
  inject: ['side_list_item', 'change_active'],
  data() {
    return {
      state: null,
      uavItem: {
        carNo: '',
        device_name: '',
        img_type: '',
        flyInfo: {
          id: '',
          flySeriaNum: '',
          flySeriaNum: '',
          flySeriaNum: '',
          flyModel: '',
          flyStatus: '',
          onlineStatus: ''
        }
      },
      img: null
    };
  },

  mounted() {
    // this.uavItem = this.side_list_item()
    this.setUavDataDetails();
  },

  methods: {
    close() {
      this.change_active(0);
    },
    setUavDataDetails() {
      this.$bus.$on('uavDataDetails', (data) => {
        console.log('1111111111', data);
        // this.img = require(`@/assets/dataImage/${data.img_type}.png`);
        this.img = data.modelIMage;
        this.uavItem = data;
      });
    }
    // async getUavList () {
    //   let res = await Tg.getUavList({ accountId: 1 })
    //   console.log("飞机数据....", res);
    //   let list = res.data
    //   list.forEach(obj => {
    //     if (obj.flyInfo.flyName == this.uavItem.carNo) {
    //       this.uavItem = obj
    //       console.log("uavjinalile");
    //     }
    //   })
    //   console.log("this.uavItem...", this.uavItem);
    // },
  }
};
</script>

<style lang="scss" scoped>
.popup-new-body {
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5), inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;
  width: 900px !important;
  height: 555px !important;
  position: absolute;
  text-align: left;
  padding: 0;
  margin: 0;
  top: calc(50% - 277px);
  left: calc(50% - 400px);
  .popup-new-header {
    font-size: 14px;
    padding-left: 10px;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid #494949;
    .title {
      color: #a4ff64;
    }
    .iconfont {
      float: right;
      height: 48px;
      line-height: 48px;
      margin-right: 14px;
      color: white;
      font-size: 18px;
    }
  }
  .popup-new-content {
    height: 89%;
    margin: 6px;
    // width: 100%;
    display: flex;
    border-radius: 2px;
    .popup-new-content-left {
      height: 100%;
      width: 32%;
      background: rgba(0, 0, 0, 0.95);
      border-radius: 2px;
      .up {
        img {
          margin-top: 100px;
        }
      }
    }
    .popup-new-content-right {
      height: 100%;
      width: 68%;
      display: flex;
      flex-direction: column;
      color: #fff;
      position: relative;
      overflow: auto;
      .popup-new-content-right-up {
        height: 35%;
        .title {
          font-size: 12px;
          margin-left: 8px;
        }
        .content {
          .tb-box {
            margin-top: 4px;
            box-sizing: border-box;
            height: 100%;
            padding: 0 8px;
            border-collapse: collapse;
            display: flex;
            .tb-hd-box {
              // flex: 1;
              width: 25%;
              height: calc(100% - 36px);
              background: #1e2026;
              background-image: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.05) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              box-shadow: inset 1px 1px 0 0 #000000;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .tb-hd {
                width: 100%;
                height: 36px;
                line-height: 36px;
                text-align: center;
                font-family: MicrosoftYaHei;
                font-size: 12px;
                color: #b3bbc5;
                letter-spacing: 0;
                font-weight: 400;
                border-left: 1px solid #020310;
                border-bottom: 1px solid #020310;
              }
              // border-right: 1px solid #000;
            }

            .tb-bd-box {
              // flex: 1;
              width: 25%;
              height: calc(100% - 36px);
              background: #020310;
              box-sizing: border-box;
              flex-direction: column;
              justify-content: space-between;
              // 溢出隐藏
              .overflow-omission {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .td {
                // border-left: 1px solid #000;
                border-bottom: 1px solid #000;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 1px;
                text-align: center;
                word-break: break-word;
                box-sizing: border-box;
                font-family: MicrosoftYaHei;
                font-size: 12px;
                color: #c6ccd4;
                letter-spacing: 0;
                font-weight: 400;
                .iconfont {
                  font-size: 16px;
                  color: #b3bbc5;
                  margin-left: 8px;
                }
                border-left: 1px solid #000;
              }
              &:nth-child(odd) {
                background: #14151a;
              }
            }
          }
        }
      }
      .popup-new-content-right-down {
        height: 67%;
        width: 100%;
        margin-top: 6px;
        // position: absolute;
        // bottom: 0px;
        .title {
          font-size: 12px;
          margin-left: 8px;
        }
        .content {
          .tb-box {
            margin-top: 6px;
            box-sizing: border-box;
            height: 100%;
            padding: 0 8px;
            border-collapse: collapse;
            display: flex;
            .tb-hd-box {
              flex: 1;
              height: calc(100% - 36px);
              background: #1e2026;
              background-image: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.05) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              box-shadow: inset 1px 1px 0 0 #000000;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .tb-hd {
                height: 36px;
                line-height: 36px;
                text-align: center;
                font-family: MicrosoftYaHei;
                font-size: 12px;
                color: #b3bbc5;
                letter-spacing: 0;
                font-weight: 400;
                border-left: 1px solid #020310;
                border-bottom: 1px solid #020310;
              }
              // border-right: 1px solid #000;
            }

            .tb-bd-box {
              flex: 0.6;
              height: calc(100% - 36px);
              background: #020310;
              box-sizing: border-box;
              flex-direction: column;
              justify-content: space-between;
              .td {
                // border-left: 1px solid #000;
                background: #0e1b16;
                border-bottom: 1px solid #000;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 1px;
                text-align: center;
                word-break: break-word;
                box-sizing: border-box;
                font-family: MicrosoftYaHei;
                font-size: 12px;
                color: #68dd76;
                letter-spacing: 0;
                font-weight: 400;
                .iconfont {
                  font-size: 16px;
                  color: #b3bbc5;
                  margin-left: 8px;
                }
                border-left: 1px solid #000;
              }
              &:nth-child(odd) {
                background: #14151a;
              }
            }
          }
        }
      }
    }
  }
}
</style>
