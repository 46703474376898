<template>
  <div class="flight-task-create">
    <div v-interact class="header">
      <span class="title">任务创建</span>
      <span class="iconfont icon-guanbi" @click="change_active(1)" />
    </div>
    <div class="body">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="任务名称" prop="taskName">
          <div class="required">*</div>
          <el-input v-model="form.taskName" placeholder="请输入任务名称" clearable />
        </el-form-item>
        <el-form-item label="航线名称" prop="routeName">
          <div class="required">*</div>
          <el-select v-model="form.routeName" placeholder="请选择航线名称" filterable>
            <el-option
              v-for="item in filename_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="责任单位" prop="organizationName">
          <div class="required">*</div>
          <el-input v-model="form.organizationName" placeholder="请输入责任单位名称" readonly />
        </el-form-item>
        <el-form-item label="无人机" prop="deviceName">
          <div class="required">*</div>
          <el-select v-model="form.uav" placeholder="选择无人机" filterable>
            <el-option
              v-for="item in uav_options"
              :key="item.value"
              :label="item.label"
              :value="item"
              value-key
            />
          </el-select>
        </el-form-item>
        <el-form-item label="任务描述" style="margin-top: 15px">
          <el-input
            v-model="form.textarea"
            type="textarea"
            :rows="5"
            placeholder="任务描述"
            :show-word-limit="true"
            maxlength="64"
            :autosize="{ minRows: 5, maxRows: 5 }"
          />
        </el-form-item>
        <div class="form_btn">
          <div class="btn_create" @click="addTask">创建</div>
          <div class="btn_cancel" @click="change_active(1)">取消</div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { flightTaskAPI } from '@/api';
import { AirLine } from '@/api';
export default {
  inject: ['change_active'],
  data() {
    const validateTaskName = (rule, value, callback) => {
      const regEn = /[`~!@#$%^&*_+<>?:"{},.\/;'[\]]/im.test(value);
      if (!value || !value.trim()) {
        callback(new Error('请输入任务名！'));
      } else if (regEn) {
        callback(new Error('不能输入特殊符号！'));
      } else {
        callback();
      }
    };
    const validateRouteName = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error('请输入航线名！'));
      } else {
        callback();
      }
    };
    const validateOrganizationName = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error('请选择责任单位！'));
      } else {
        callback();
      }
    };
    const validateDeviceName = (rule, value, callback) => {
      if (!this.form.uav) {
        if (!this.form.organizationName) {
          callback(new Error('请选择责任单位！'));
        } else {
          callback(new Error('请选择设备！'));
        }
      } else {
        callback();
      }
    };

    return {
      rules: {
        taskName: [{ validator: validateTaskName, trigger: ['change'] }],
        routeName: [{ validator: validateRouteName, trigger: ['change'] }],
        deviceName: [{ validator: validateDeviceName, trigger: ['change'] }],
        organizationName: [{ validator: validateOrganizationName, trigger: ['change'] }]
      },
      form: {
        taskName: '',
        routeName: '',
        organizationName: '科比特',
        uav: '',
        textarea: ''
      },
      filename_options: [],
      company_options: [],
      uav_options: [{}],
      isDeviceHaveTask: false,
      organizationId: null
    };
  },
  async created() {
    await this.getFlightList();
    // await this.getCompanyList()
    await this.getUavList();
  },

  methods: {
    async getFlightList() {
      const param = {
        num: 1,
        size: 9999
      };
      const resp = await AirLine.lineList(param);
      const fileArr = resp.data.data.map((item) => {
        return { value: item.name, label: item.name };
      });
      this.filename_options = fileArr;
    },

    async getCompanyList() {
      const resp = await flightTaskAPI.getCompany();
      const companyArr = [
        {
          value: resp.data.name,
          label: resp.data.name
        }
      ];
      this.form.organizationName = resp.data.name;
      this.organizationId = resp.data.id;
      this.company_options = companyArr;
    },

    async getUavList() {
      const params = {
        organizationId: this.organizationId
      };
      const resp = await flightTaskAPI.getUnbindUav(params);
      let uavArr = {};
      if (resp.code == 200) {
        uavArr = resp.data.map((item) => {
          return {
            value: item.id, // 整个对象
            label: item.deviceName,
            obj: item
          };
        });
      } else {
        this.$message({
          type: 'error',
          message: '获取设备列表失败！',
          duration: 1000
        });
        uavArr = {};
      }
      this.uav_options = uavArr;
    },

    // 设备状态
    async getDeviceHaveTask(uavId) {
      const formdata = new FormData();
      formdata.append('id', uavId);
      let state = false;
      await flightTaskAPI.isDeviceHaveTask(formdata).then((resp) => {
        if (resp.code == 500) {
          state = false;
        } else {
          state = true;
        }
      });
      return state;
    },

    addTask() {
      const param = {
        deviceHardId: this.form.uav.obj.deviceHardId,
        deviceId: this.form.uav.value,
        deviceName: this.form.uav.label,
        deviceStatus: 1,
        organizationName: this.form.organizationName,
        remark: this.form.textarea,
        routeName: this.form.routeName,
        taskName: this.form.taskName
      };
      this.$refs['form'].validate((validate) => {
        if (validate) {
          flightTaskAPI.addFlight({ ...param }).then((resp) => {
            if (resp.code == 200) {
              this.change_active(1);
              this.$message({
                showClose: true,
                type: 'success',
                message: resp.msg,
                duration: 1000
              });
            } else {
              this.change_active(4);
              this.$message({
                showClose: true,
                type: 'error',
                message: resp.msg,
                duration: 1000
              });
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.flight-task-create {
  width: 323px;
  height: 441px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  position: absolute;
  top: 200px;
  right: 700px;

  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }
    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .body {
    height: calc(100% - 85px);
    width: 317px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 10px;
    border-bottom: 1px solid #494949;
    border-top: 1px solid #494949;

    .el-form {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      letter-spacing: 0;
      font-weight: 400;
      &::v-deep {
        .el-form-item__error {
          padding-top: 0px;
          position: absolute;
          bottom: -12px;
          margin-left: 15px;
        }
        .el-input__count {
          background: none;
          position: absolute;
          right: 16px;
          bottom: -7px;
        }
        .el-form-item {
          margin-bottom: 16px;
          .required {
            color: #ed6a6a;
            width: 4px;
            position: absolute;
            left: -29%;
          }
          .el-form-item__label {
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #b3bbc5;
            width: 100px;
          }
          .el-input {
            width: 227px;
            height: 32px;
            .el-input__inner {
              height: 100%;
              width: 100%;
              border-radius: 8px;
              border: none;
              font-family: PingFangSC-Regular;
              font-size: 12px;
              letter-spacing: 0;
              font-weight: 400;
              color: #c6ccd4;
              background: #16181b;
            }
            .el-input__icon {
              line-height: 32px;
              color: #1eb9fa;
            }
          }
          .el-textarea__inner {
            margin-top: 4px;
            width: 227px;
            height: 100px;
            max-height: 100px;
            min-height: 100px;
            background: #16181b;
            border-radius: 8px;
            border: none;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #c6ccd4;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
      }
    }

    .form_btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-family: MicrosoftYaHei;
      position: absolute;
      bottom: 10px;
      width: 317px;
      .btn_create,
      .btn_cancel {
        box-sizing: border-box;
        width: 92px;
        height: 32px;
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
