<script>
import MessageModal from "../../common/message-modal/index.vue";
import MessageNotifications from "./components/message-notifications/index.vue";
import TaskNotifications from "./components/task-notifications/index.vue";
import WarningModel from "./components/warning/index.vue";
import { taskStatusDictionary } from "@/utils/dictionary";
import { MessageAPI } from "@/api";
import moment from "moment";
import { filterObjAttr } from "@/utils";
import { permissionIds } from "@/utils/permission";

export default {
  name: "Index",
  components: {
    MessageModal,
    MessageNotifications,
    TaskNotifications,
    WarningModel,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tabList: [
        {
          id: 1,
          label: "任务通知",
          name: "task",
        },
        {
          id: 2,
          label: "AI预警提示",
          name: "AiWaring",
        },
      ],
      currentTab: "task",
      messageNotificationsShow: false, // 任务通知
      pagination: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0,
      },
      messageList: [],
      taskStatusDictionary,
      searchForm: {
        search: undefined,
        start: undefined,
        end: undefined,
        type: undefined,
        time: undefined,
      },
      currentTaskItem: undefined,
    };
  },
  created() {
    console.log(this.isPurview(801),'this.isPurview(801)');
    if (!this.isPurview(801)) {
      this.tabList = this.tabList.pop();
    }
  },
  mounted() {
    this.getMessageList();
  },
  methods: {
    isPurview(pid) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    // 获取消息列表
    getMessageList(query) {
      MessageAPI.getMsgList({
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        ...query,
      }).then(({ data }) => {
        this.messageList = data.records;
        this.pagination.totalCount = data.total;
      });
    },
    // 分页
    paginationChange({ pageNo, pageSize }) {
      this.pagination.pageNo = pageNo;
      this.pagination.pageSize = pageSize;
      this.getMessageList(filterObjAttr(this.searchForm, ["time"]));
    },
    // 筛选
    searchSubmit() {
      if (this.searchForm.time?.length && Array.isArray(this.searchForm.time)) {
        this.searchForm.start =
          moment(this.searchForm.time.at(0)).format("YYYY-MM-DD") + " 00:00:00";
        this.searchForm.end =
          moment(this.searchForm.time.at(-1)).format("YYYY-MM-DD") +
          " 23:59:59";
      } else {
        this.searchForm.start = undefined;
        this.searchForm.end = undefined;
      }
      this.getMessageList(filterObjAttr(this.searchForm, ["time"]));
    },
    close() {
      this.$emit("close");
    },
    // 任务通知弹窗
    messageNotificationsClose() {
      this.messageNotificationsShow = false;
    },
    messageNotificationsShowClick(taskItem, index) {
      this.currentTaskItem = taskItem;
      this.messageNotificationsShow = true;
      if (!taskItem.read) {
        this.messageList.splice(index, 1, { ...taskItem, read: 1 });
        this.$bus.$emit("refreshMsgCount");
      }
    },
    //  刷新消息列表
    refreshMsgList() {
      this.getMessageList(filterObjAttr(this.searchForm, ["time"]));
    },
  },
};
</script>
<!--任务通知-->
<template>
  <div class="message-center">
    <message-modal :show="show" title="消息中心" :width="890" @close="close">
      <div class="message-center-info">
        <div class="info-content">
          <el-tabs tab-position="left" v-model="currentTab">
            <el-tab-pane
              v-for="v in tabList"
              :key="v.id"
              :label="v.label"
              :name="v.name"
            />
          </el-tabs>
          <div class="info-content-table" v-if="currentTab == 'task'">
            <div class="table-search">
              <el-form :model="searchForm" label-width="80px" :inline="true">
                <el-form-item>
                  <el-input
                    v-model="searchForm.search"
                    placeholder="请输入任务名称搜索"
                    prefix-icon="el-icon-search"
                  />
                </el-form-item>
                <el-form-item>
                  <el-date-picker
                    v-model="searchForm.time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 250px"
                  />
                </el-form-item>
                <el-form-item>
                  <el-select
                    v-model="searchForm.type"
                    placeholder="消息类型"
                    style="width: 100px"
                    clearable
                  >
                    <el-option
                      v-for="item in taskStatusDictionary"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button @click="searchSubmit">查询</el-button>
                </el-form-item>
              </el-form>
            </div>
            <!--任务通知-->
            <task-notifications
              :message-list="messageList"
              :pagination="pagination"
              @messageNotificationsShowClick="messageNotificationsShowClick"
              @paginationChange="paginationChange"
            />
          </div>
          <div v-else class="info-content-table">
            <WarningModel></WarningModel>
          </div>
        </div>
      </div>
    </message-modal>
    <!--任务通知详情-->
    <message-notifications
      v-if="messageNotificationsShow"
      :show="messageNotificationsShow"
      :current-task-item="currentTaskItem"
      @close="messageNotificationsClose"
      @refreshMsgList="refreshMsgList"
    />
  </div>
</template>

<style scoped lang="scss">
.message-center {
  &-info {
    padding: 8px;
    .info-content {
      display: flex;
      background-color: #2c313b;
      padding-bottom: 8px;
      &-table {
        height: 500px;
        background: #0a0b0d;
        border-radius: 4px;
        margin: 0 8px;
        .table-search {
          padding: 8px;
        }
      }
    }
  }
}
::v-deep {
  .el-input {
    height: 41px;
  }
  .el-tabs__header {
    padding: 10px;
  }
  .el-tabs--left .el-tabs__item.is-left {
    text-align: left;
  }
  .el-tabs--left {
    overflow: initial !important;
  }
}
</style>
