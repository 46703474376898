<template>
  <div class="flyDataBOX">
    <div class="item">
      <span class="title">海拔高度:</span
      ><span class="data">
        {{
          uavRealTimeData.gps.absoluteAlt ? uavRealTimeData.gps.absoluteAlt.toFixed(0) : '-'
        }}m</span
      >
    </div>
    <div class="item">
      <span class="title">起点距离:</span
      ><span class="data">
        {{ uavRealTimeData.distanceToHome ? uavRealTimeData.distanceToHome.toFixed(0) : '-' }}
        m</span
      >
    </div>
    <div class="item">
      <span class="title">爬升率:</span
      ><span class="data">
        {{ uavRealTimeData.velocityZ ? uavRealTimeData.velocityZ.toFixed(0) : '-' }}
        m/s</span
      >
    </div>
    <div class="item">
      <span class="title">地速:</span
      ><span class="data">
        {{ uavRealTimeData.groundSpeed ? uavRealTimeData.groundSpeed.toFixed(0) : '-' }}
        m/s</span
      >
    </div>
    <div class="item">
      <span class="title">卫星数目:</span
      ><span class="data">
        {{
          uavRealTimeData.gps.satelliteCount ? uavRealTimeData.gps.satelliteCount.toFixed(0) : '-'
        }}
      </span>
    </div>
    <div class="item">
      <span class="title">飞行速度:</span
      ><span class="data">
        {{ uavRealTimeData.groundSpeed ? uavRealTimeData.groundSpeed.toFixed(1) : '-' }}m/s
      </span>
    </div>
    <div class="item">
      <span class="title">电压:</span
      ><span class="data">
        {{ uavRealTimeData.voltage ? uavRealTimeData.voltage.toFixed(0) : '-' }}V
      </span>
    </div>
    <div class="item">
      <span class="title">电池:</span
      ><span class="data">
        {{ uavRealTimeData.chargeRemaining ? uavRealTimeData.chargeRemaining.toFixed(0) : '-' }}
        %
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          name: '海拔高度',
          data: 123
        },
        {
          name: '起点距离',
          data: 123
        },
        {
          name: '爬升率',
          data: 123
        },
        {
          name: '地速',
          data: 123
        },
        {
          name: '卫星数目',
          data: 123
        },
        {
          name: '电压',
          data: 123
        },
        {
          name: '电压2',
          data: 123
        },
        {
          name: '电流值',
          data: 123
        }
      ]
    };
  },
  computed: {
    // 实时数据
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    }
  }
};
</script>
<style lang="scss" scoped>
.flyDataBOX {
  color: #fff;
  background: #101215;
  display: flex;
  height: 18vh;
  width: 93%;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 5px;
  margin-left: 12px;
  margin-top: 16px;
  position: fixed;
  z-index: 2;
  right: 0;

  .item {
    margin: 5px;
    width: 195px;
    display: flex;
    justify-content: space-between;

    .title {
      color: #959ca5;
    }

    .data {
      display: inline-block;
      width: 121px;
      height: 22px;
      text-align: center;
      background-color: #16181b;
      border: 1px solid #050506;
    }
  }
}
</style>
