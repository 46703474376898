<template>
  <div class="project-list">
    <!-- <CreateProejct
      v-if="projectItem"
      :edit-data="projectItem"
      readonly
      style="margin-top: 0; box-sizing: border-box"
      @back="onBack"
    /> -->
    <template>
      <div class="search-box">
        <!--        <div-->
        <!--          v-hover-->
        <!--          v-pms="['项目列表', '项目创建']"-->
        <!--          class="btn-create"-->
        <!--          @click="projectCreate"-->
        <!--        >-->
        <!--          <span>项目创建</span>-->
        <!--        </div>-->
        <el-input
          v-model="projectText"
          prefix-icon="el-icon-search"
          placeholder="请输入项目名称"
          clearable
          @input="handelSearch"
        />
        <!-- <div v-hover
            class="btn_search"
            @click="handelSearch">查询</div> -->
      </div>
      <div class="tb-box">
        <div class="tb-hd-box">
          <div class="tb-hd" style="flex: 2">项目名称</div>
          <div class="tb-hd" style="flex: 1">操作</div>
        </div>
        <div v-if="projectList.length != 0" class="tb-bd-box">
          <div v-for="(item, index) in projectList" :key="index" class="tb-tr">
            <div class="td" style="flex: 2">
              {{ item.projectName || "暂无" }}
            </div>

            <div class="td btn">
              <span
                v-hover
                v-pms="['项目列表', '进入']"
                class="enter"
                @click="toProject(item)"
                >进入</span
              >
              <!--              <el-dropdown trigger="click" placement="bottom-start" @click.native.stop>-->
              <!--                <div class="more">-->
              <!--                  ...-->
              <!--                </div>-->
              <!--                <el-dropdown-menu slot="dropdown">-->
              <!--                  &lt;!&ndash; <el-dropdown-item @click.native="editItem(item)">编辑</el-dropdown-item> &ndash;&gt;-->
              <!--                  <el-dropdown-item class="font-red" @click.native="deleteItem(item)">删除</el-dropdown-item>-->
              <!--                </el-dropdown-menu>-->
              <!--              </el-dropdown>-->
            </div>
          </div>
        </div>
        <div v-else class="no-data">暂无数据</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { projectAPI, Control_API } from "@/api";
import { Utils } from "@/utils/cesium";
import CreateProejct from "./create-project";

export default {
  components: {
    CreateProejct,
  },

  data() {
    return {
      projectText: "",
      list: null,
      isShow: "",
      urlList: [
        "http://pipe.rtk.mmcuav.cn/electric-education/#/login",
        "http://elec.rtk.mmcuav.cn/electric-education/#/login",
        "http://39.103.170.213:10006/javaweb/#/home",
        "http://112.124.32.65/#/login",
        "https://mspace.mmcuav.cn/",
      ],
    };
  },
  inject: ["getAppProjectList", "getProjectDataList"],
  computed: {
    ...mapState(["projectData"]),
    projectItem: {
      get() {
        return this.projectData;
      },
      set(v) {
        this.SET_PROJECT_DATA(v);
      },
    },
    appProjectList() {
      return this.getAppProjectList();
    },
    projectList() {
      return this.getProjectDataList();
    },
  },

  mounted() {
    // this.getProjectList();
    this.$bus.$on("roloadProjectList", () => {
      this.reloadList();
    });
  },

  methods: {
    ...mapMutations([
      "SET_SIDEBAR_STATUS",
      "SET_PROJECT_DATA",
      "SET_EDIT_PROJECT_DATA",
    ]),
    handelSearch(e) {
      // const userInfo = JSON.parse(window.localStorage.getItem('user_info'));
      // const param = {
      //   accountId: userInfo.id,
      //   projectName: this.projectText
      // };
      // projectAPI.getProjectList({ ...param }).then((res) => {
      //   console.log(res);
      //   this.list = res.data;
      // });
      this.$bus.$emit("searchProjectByName", e);
    },
    changeBtn(item) {
      const { isShow } = this;
      if (isShow) {
        this.isShow = "";
      } else {
        this.isShow = item.id;
      }
    },
    flyToProject(item) {
      const viewer = window.viewer;
      if (item) {
        const position = Utils.transformWGS842Cartesian({
          lng: item.longitude,
          lat: item.latitude,
          alt: 80000,
        });
        viewer.camera.flyTo({
          destination: position,
        });
      }
    },
    projectCreate() {
      this.SET_SIDEBAR_STATUS(5);
      this.$bus.$emit("openSideList");
      this.$bus.$emit("closeListNav");
    },
    deleteItem(item) {
      const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
      const param = {
        accountId: userInfo.id,
        id: item.id,
      };
      projectAPI.deleteProject({ ...param }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "删除项目成功！",
            duration: 1000,
          });
          this.reloadList();
        }
      });
    },
    editItem(item) {
      this.SET_EDIT_PROJECT_DATA(item);
      this.projectCreate(); // 打开右侧编辑面板
    },
    reloadList() {
      setTimeout(() => {
        this.getProjectList();
      }, 500);
    },
    async getProjectList() {
      const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
      const param = {
        accountId: userInfo.id,
      };
      const res = await projectAPI.getProjectList({ ...param });
      this.list = res.data;

      if (!this.projectItem) {
        // 标记地图上的所有项目
        this.$bus.$emit("clickHeaderNav", {
          index: 5,
          isShow: true,
          list: this.list,
        });
      }
    },
    onBack() {
      this.projectItem = null;
    },
    // 项目跳转
    async toProject(item) {
      switch (item.type) {
        case 1:
          // 内部
          // this.$message({
          //   type: 'info',
          //   message: '子平台待开发中，敬请期待',
          //   duration: 1000
          // });
          // 子平台权限接口
          const res = await projectAPI.getSubPlatformPermission({
            projectId: item.projectId,
          });

          this.SET_PROJECT_DATA(item);
          if (res.code === 200) {
            // 存储子平台权限
            localStorage.setItem(
              "subplatform-permission",
              JSON.stringify(res.data)
            );
            // 存储子平台具体内容
            localStorage.setItem("subplatformInfo", JSON.stringify(item));
            // 先显示飞行任务
            this.$store.commit("subplatform/SET_SUBPLAT_FORM_ACTIVE", 1);
            this.$store.commit("CHANGE_ACTYIVE", 10);
            // 左侧模块-左侧菜单栏 0为子平台
            this.$store.commit("SET_LEFT_MENU_ACTION", 0);
            // 子平台显示隐藏
            this.$store.commit("subplatform/SET_SUBPLAT_FORM_STATUS", true);
          }
          break;
        case 2:
          if (
            // 城市之鹰-公安
            item.url.includes("https://hawk.mmcuav.cn/hawk_web/#/login") ||
            // 海宁政务
            item.url.includes("http://112.124.56.203/#/login")
          ) {
            const data = await this.getSonToken(item);
            const k = {
              u: data.username,
              p: data.password,
            };
            this.goToNewWindow(`${item.url}?token=${btoa(JSON.stringify(k))}`);
            return;
          }
          // if (this.urlList.includes(item.manageurl)) {
          let url = "";
          const token = await this.getSonToken(item, 1);
          // const url = `${item.url}/#/tm?token=${token}`
          if (item.url.includes("https://mspace.mmcuav.cn")) {
            // 智慧城管
            url = `http://webmspace.mmcuav.cn/#/tm?token=${token}`;
          } else if (item.url.includes("http://39.103.170.213:10006/javaweb")) {
            // 智慧公安
            url = `http://sh.flying.mmcuav.cn/javaweb/#/tm?token=${localStorage.getItem(
              "tmj_token"
            )}`;
          } else {
            url = `${item.url}/#/tm?token=${token}`;
          }
          if (this.isPath) this.goToNewWindow(url);
          // }
          // 外部
          // window.open(item.url, "_blank")
          break;
        case 3:
          // 推广
          break;
      }
    },
    async getSonToken(item, type) {
      const tmj_token = window.localStorage.getItem("tmj_token");
      const loginInfo = {
        token: tmj_token,
        appId: item.appid,
        url: item.manageurl,
      };
      const res = await Control_API.login({ loginInfo });
      if (res.msg) {
        if (res.msg.indexOf("过期") !== -1) {
          this.isPath = false;
          this.$message({
            type: "error",
            message: "账号已过期，请联系管理员",
            duration: 2000,
          });
        } else {
          this.isPath = true;
        }
      }
      if (res.data) {
        if (type == 1) {
          return (
            res?.data?.token || res?.data?.result || res?.data["mmc-identity"]
          );
        } else {
          return res?.data;
        }
      }
    },
    goToNewWindow(url) {
      if (this.isElectron) {
        const electron = window.electron;
        const ipcRenderer = electron?.ipcRenderer;
        ipcRenderer && ipcRenderer.send("add", url);
      } else {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/list-common.scss";

.project-list {
  // height: calc(100% + 38px);
  height: 100%;

  .search-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;

    .btn-create,
    .btn_search {
      flex-shrink: 0;
      box-sizing: border-box;
      padding: 5px 19px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;

      .iconfont {
        font-size: 14px;
        margin-right: 3px;
      }
    }

    &::v-deep {
      .el-input {
        box-sizing: border-box;
        margin: 0px 8px;
        border: 1px solid #233a58;
        border-radius: 10px;

        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }

  .delete_box {
    width: 48px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    background-color: #14151a;
    position: absolute;
    right: -10px;
    // border: 1px solid #1eb9fa;
  }

  .btn {
    display: flex;
    flex: 1;

    .more {
      line-height: 1;
      color: #fff;
      padding-bottom: 6px;
      margin-left: 8px;
    }

    .enter {
      color: #52cdff;
      height: 20px;
      line-height: 20px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      letter-spacing: 0;
      margin-left: 6px;
      text-align: center;
      font-weight: 400;
    }

    .delete {
      color: #e4696a;
      border-radius: 2px;

      &:hover {
        color: #f8f8f8;
        background: #1890ff;
      }
    }
  }

  .no-data {
    color: #fff;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
