<template>
  <div class="flight-line-list">
    <div class="search_box line">
      <el-button
        v-show="permissionFn(464)"
        v-hover
        v-pms="['航线管理', '航线规划']"
        @click="goLineCreate"
      >
        新增航线
      </el-button>
      <el-input
        v-model="search.name"
        placeholder="请输入航线名称"
        prefix-icon="el-icon-search"
        @change="handelSearch"
      />
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <!-- <div class="tb-hd">航线ID</div> -->
        <div class="tb-hd">航线名称</div>
        <div class="tb-hd">航点数量</div>
        <!-- <div class="tb-hd">航线长度(km)</div> -->
        <!-- <div class="tb-hd">预计时间(min)</div> -->
        <!-- <div class="tb-hd">保存时间</div> -->
        <div class="tb-hd">操作</div>
      </div>
      <div class="tb-bd-box">
        <div v-for="(item, index) in list" :key="index" class="tb-tr">
          <!-- <div class="td">
            {{ item.id || "暂无" }}
          </div> -->
          <div class="td">
            {{ item.name || '暂无' }}
          </div>
          <div class="td">
            {{
              (JSON.parse(item.content).content && JSON.parse(item.content).content.length) ||
              '暂无'
            }}
          </div>
          <!-- <div class="td">
            {{ item.kmOfRoute.toFixed(3) || "暂无" }}
          </div> -->
          <!-- <div class="td">
            {{ min(item.expectTakeMinute.toFixed(2)) || "暂无" }}
          </div> -->
          <!-- <div class="td">
            {{ time(item.createWhen) || "暂无" }}
          </div> -->
          <div class="td">
            <el-tooltip v-pms="['航线管理', '编辑']" effect="dark" content="编辑" placement="top">
              <div
                v-show="permissionFn(466)"
                v-hover
                class="iconfont icon-bianji"
                @click="goEdit(item)"
              />
            </el-tooltip>
            <el-tooltip v-pms="['航线管理', '预览']" effect="dark" content="预览" placement="top">
              <div v-hover class="iconfont icon-wurenji-hangxian" @click="showRoute(item)" />
            </el-tooltip>
            <el-tooltip v-pms="['航线管理', '删除']" effect="dark" content="删除" placement="top">
              <div
                v-show="permissionFn(465)"
                v-hover
                class="iconfont icon-shanchu"
                style="color: #a16869"
                @click="deleteItem(item)"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="totalPage"
        :page-size="search.size"
        :current-page.sync="search.num"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import { Utils } from '@/utils/cesium';
import { AirLine, Airspace } from '@/api';
import { mapMutations } from 'vuex';
import { permissionIds } from '@/utils/permission';

const polygonEntity = [];

let handler = null;
const data_srouce = null;

const airline_entitys = []; // 航线实体

const point_index = null;
const point_uuid = null;
const point_uuid_next = null;

let point_entity = null;
let label_entity = null;

let positions;

export default {
  inject: ['change_active', 'get_route_info', 'edit_point_info', 'send_to_line_tooptip'],
  data() {
    return {
      status: null,
      list: [],
      search: {
        name: '',
        num: 1,
        size: 20
      },
      totalPage: null,
      pointArr: [],
      isEditting: false,
      point_item_info: {},
      point_positions: {}
    };
  },
  async mounted() {
    this.getRouteList(1);
    this.$bus.$on('reloadLineList', () => {
      this.reloadList();
    });
  },

  methods: {
    permissionFn(pid) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    ...mapMutations([
      'SET_ISFORMAIRLINE_STATUS',
      'SET_SIDEBAR_STATUS',
      'SET_CESIUM_STATE',
      'SET_OPEN_RIGHT_NAV_SILDER'
    ]),
    reloadList() {
      setTimeout(() => {
        this.getRouteList(this.search.num);
      }, 500);
    },
    goLineCreate() {
      // 清除航线
      this.clear_airline_entitys();
      this.SET_SIDEBAR_STATUS(3);
      // this.SET_OPEN_RIGHT_NAV_SILDER(true)
      this.$bus.$emit('openSideList');
    },
    min(val) {
      const str = String(val).split('').reverse().join('');
      const a = str.substring(0, 2);
      let b = str.substring(3).split('').reverse().join('');
      let c = Math.ceil((a * 60) / 100);
      const bArr = String(b).split('');
      const cArr = String(c).split('');
      if (cArr.length == 1) cArr.unshift(0);
      if (bArr.length == 1) bArr.unshift(0);
      b = bArr.join('');
      c = cArr.join('');
      const min = b + ':' + c;
      return min;
    },
    time(val) {
      var a = val.substring(0, 4);
      var b = val.substring(4, 6);
      var c = val.substring(6, 8);
      var time = a + '-' + b + '-' + c;
      return time;
    },

    handelSearch() {
      this.getRouteList(1);
    },

    // 获取航线列表
    async getRouteList(num) {
      this.search.num = num;
      const param = {
        page: num,
        pageSize: this.search.size,
        name: this.search.name ? this.search.name : undefined
      };
      await AirLine.lineList({ ...param }).then((resp) => {
        this.list = resp.data.records;
        this.totalPage = resp.data.total || resp.data.records.length;
      });
    },

    // 页码
    handleCurrentChange(val) {
      this.getRouteList(val);
    },
    // 编辑
    async goEdit(item) {
      this.clear_airline_entitys();
      this.get_route_info(item);
      // this.change_active(9);
      this.SET_SIDEBAR_STATUS(4);
      this.SET_CESIUM_STATE(true);
      // this.SET_OPEN_RIGHT_NAV_SILDER(true)
      this.$bus.$emit('openSideList');
      // this.$bus.$emit('closeListNav');
    },
    // 删除
    async deleteItem(item) {
      const res = await AirLine.routeDelete(item.id);
      if (res.code == 200) {
        this.getRouteList(1);
        this.SET_OPEN_RIGHT_NAV_SILDER(false);
        this.SET_SIDEBAR_STATUS('');
        this.$bus.$emit('closeSideList');
        this.SET_CESIUM_STATE(true);
        this.clear_airline_entitys();
        this.$message({
          type: 'success',
          message: '删除成功！'
        });
      }
      // this.change_active(8);
      // this.send_to_line_tooptip(item);
      // this.SET_ISFORMAIRLINE_STATUS(true);
    },
    // 预览
    async showRoute(item) {
      this.$bus.$emit('closeSideList');
      // this.$bus.$emit('closeListNav');
      this.SET_SIDEBAR_STATUS('');
      this.SET_CESIUM_STATE(true);
      this.SET_OPEN_RIGHT_NAV_SILDER(false);
      const param = { id: item.id };
      await AirLine.routeDetail(param).then((resp) => {
        this.pointArr = JSON.parse(resp.data.content).content;
      });
      const polyline = this.pointArr;
      const viewer = window.viewer;
      this.clear_airline_entitys();

      let pointPositions = [];
      const label_arr = [];
      const _this = this;
      polyline.forEach((item, index) => {
        pointPositions.push(item.longitude, item.latitude, item.alt);
        label_arr.push(Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude, item.alt));
        point_entity = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(item.longitude, item.latitude, item.alt),
          name: 'show_airline_point',
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            // color: Cesium.Color.fromCssColorString('#1890FF'),
            // fillColor: Cesium.Color.RED,
            outlineWidth: 2,
            outlineColor: Cesium.Color.fromCssColorString('#FFF')
            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          },
          label: {
            // text: String(item.altitude) + 'm',
            text: String(index + 1),
            scale: 0.5,

            font: 'bold 24px Microsoft YaHei',
            // fillColor: Cesium.Color.BLUE,
            fillColor: Cesium.Color.fromCssColorString('#fff'),
            horizontalOrigin: Cesium.VerticalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            outlineWidth: 0
            // showBackground: true,
            // backgroundColor: Cesium.Color.fromCssColorString("#1890FF")
            // pixelOffset: new Cesium.Cartesian2(25, -10),
            // backgroundPadding: new Cesium.Cartesian2(10, 10)
          },
          id: JSON.stringify({
            ...item,
            type: 'flight_point',
            index: index
          })
        });
        airline_entitys.push(point_entity);
        if (label_arr.length > 1 && !this.isEditting) {
          const before = label_arr[label_arr.length - 2];
          const after = label_arr[label_arr.length - 1];
          _this.create_label(before, after);
        }
      });

      pointPositions = Cesium.Cartesian3.fromDegreesArrayHeights(pointPositions);

      const redLine = viewer.entities.add({
        name: 'Red line on terrain',
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return pointPositions;
          }, false),
          width: 4,
          // clampToGround: true,
          // zIndex: -99,
          material: Cesium.Color.fromCssColorString('#1890FF')
          // material: ({
          //   // material: Cesium.Color.fromCssColorString('#1890FF'),
          //   color: Cesium.Color.RED,
          //   outlineWidth: 2,
          //   outlineColor: Cesium.Color.fromCssColorString('#FFF')
          // })
          // // clampToGround: true,
          // material: new Cesium.PolylineOutlineMaterialProperty({
          //   color: Cesium.Color.fromCssColorString('#1890FF'),

          //   // outlineWidth: 2,
          //   // outlineColor: Cesium.Color.fromCssColorString('#FFF'),
          // }),
        }
      });

      // positions = pointPositions;

      viewer.flyTo(redLine);
      airline_entitys.push(redLine);
    },
    // 两点距离
    create_label(before, after) {
      const viewer = window.viewer;
      const before_wgs84 = Utils.transformCartesian2WGS84(before);
      const after_wgs84 = Utils.transformCartesian2WGS84(after);

      const center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      const cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      const alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      const position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt
      });

      const text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: 'bold 30px Microsoft YaHei',
          fillColor: Cesium.Color.fromCssColorString('#fff'),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10)
        }
      });
      airline_entitys.push(label_entity);
    },

    // 清除航线
    clear_airline_entitys() {
      const viewer = window.viewer;
      airline_entitys.forEach((item) => {
        viewer.entities.remove(item);
      });

      if (handler) {
        handler && handler.destroy() && (handler = null);
        handler = null;
      }
    },

    // 文本位置
    update_label(uuid, before, after) {
      const entities = data_srouce.entities;
      const id = `label_${uuid}`;
      const entity = entities.getById(id);

      const before_wgs84 = Utils.transformCartesian2WGS84(before);
      const after_wgs84 = Utils.transformCartesian2WGS84(after);

      const center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      const cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      const alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      const position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt + 10
      });
      const text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      entity.position = position;
      entity.label.text = text;
    },

    click_flight_point() {
      const viewer = window.viewer;
      const scene = viewer.scene;
      const _this = this;
      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async (movement) => {
        const pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(movement.position.x, movement.position.y);
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          const cartesian = viewer.scene.globe.pick(viewer.camera.getPickRay(pick), viewer.scene);
          if (pickedLabel.id) {
            try {
              const data = JSON.parse(pickedLabel.id._id);
              this.point_item_info = data; // 航点信息

              if (data.type == 'flight_point') {
                handler.setInputAction((event) => {
                  const cartesian3 = Utils.getCartesian3FromPX(viewer, event.endPosition);
                  positions[data.index] = cartesian3;
                  pickedLabel.id._position._value = cartesian3;
                  this.point_positions = positions[data.index]; // 实时位置
                }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
                handler.setInputAction((event) => {
                  handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
                  this.changePointPosition(positions, data.index);
                }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
              }
            } catch (error) {}
          } else {
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },

    changePointPosition(positions, index) {
      const points = positions.map((item) => {
        const cartographic = Cesium.Cartographic.fromCartesian(item);
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        const lng = Cesium.Math.toDegrees(cartographic.longitude);
        const alt = cartographic.height;
        return {
          latitude: lat,
          longitude: lng,
          altitude: alt
        };
      });
      this.pointArr[index].latitude = points[index].latitude;
      this.pointArr[index].longitude = points[index].longitude;
      this.pointArr[index].altitude = points[index].altitude;
      this.edit_point_info(this.pointArr);
    }
  },
  destroyed() {
    this.clear_airline_entitys();
    // 清空窗口状态
    this.SET_SIDEBAR_STATUS(null);
    this.$bus.$emit('closeSideList');
  }
};
</script>

<style lang="scss" scoped>
.flight-line-list {
  @import '~@/styles/list-common.scss';
  height: calc(100% + 38px);

  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;

    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;

      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    &::v-deep {
      .el-input {
        width: 223px;
        margin: 0px 8px;
        border: 1px solid #233a58;
        border-radius: 10px;

        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }

  .pagination {
    width: 100%;
    height: 30px;
    position: absolute;
    right: 6px;
    bottom: -34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tl-box {
    height: 100%;

    .task-create-box {
      height: calc(100% - 36px);
    }

    .task-edit-box {
      height: calc(100% - 36px);
    }

    .line-create-box {
      height: calc(100% - 36px);
    }

    .line-edit-box {
      height: calc(100% - 36px);
    }
  }
}
</style>
