<template>
  <div class="flight-task-create">
    <div class="header">
      <!-- v-interact -->
      <div class="left">
        <span class="title">任务创建</span>
      </div>
      <span v-hover class="iconfont icon-guanbi" @click="cancel" />
      <!-- <span class="iconfont icon-guanbi" @click="change_active(1)"></span> -->
    </div>
    <div class="body">
      <el-form ref="form" :rules="rules" :model="form" label-width="100px">
        <el-form-item label="任务名称" prop="taskName">
          <!-- <div class="required">*</div> -->
          <el-input
            v-model="form.taskName"
            placeholder="请输入任务名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="任务场景" prop="sceneType">
          <el-select
            v-model="form.sceneType"
            placeholder="请选择任务场景"
            filterable
          >
            <el-option
              v-for="item in sceneTypeList"
              :key="item.value"
              placeholder="请选择任务场景"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="任务流程" prop="processId">
          <!-- <div class="required">*</div> -->
          <el-select
            v-model="form.processId"
            placeholder="请选择任务流程"
            filterable
            @change="changeProcessId"
          >
            <el-option
              v-for="item in taskTypeList"
              :key="item.value"
              placeholder="请选择任务流程"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="责任机构" prop="organizationName">
          <div class="required">*</div>
          <el-input
            v-model="form.organizationName"
            placeholder="请输入责任单位名称"
            readonly
          />
        </el-form-item> -->
        <el-form-item label="责任机构" prop="orgId">
          <!-- <div class="required">*</div> -->

          <el-cascader
            v-model="form.orgId"
            placeholder="请选择责任机构"
            :show-all-levels="false"
            :options="orgOptions"
            :props="{
              value: 'id',
              label: 'name',
              children: 'childNodes',
              checkStrictly: true,
              emitPath: false,
            }"
            @change="changeOrgId"
          />
        </el-form-item>
        <el-form-item label="航线名称" prop="routeId">
          <!-- <div class="required">*</div> -->

          <el-select
            v-model="form.routeId"
            placeholder="请选择航线名称"
            filterable
          >
            <el-option
              v-for="item in filename_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="type === 0" label="开始时间" prop="expectedtime">
          <!-- <div class="required">*</div> -->
          <el-date-picker
            v-model="form.expectedtime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择预计开始时间"
            align="right"
            :picker-options="pickerOptionsStart"
          />
        </el-form-item>
        <el-form-item
          v-else-if="type === 1"
          label="定时执行时间"
          prop="expectedtime"
        >
          <!-- <div class="required">*</div> -->
          <el-date-picker
            v-model="form.expectedtime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择预计开始时间"
            align="right"
            :picker-options="pickerOptionsStart"
          />
        </el-form-item>
        <el-form-item v-if="type === 2" label="周期类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="2">每日</el-radio>
            <el-radio :label="3">每周</el-radio>
            <el-radio :label="4">每月</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type === 3" label="周期日历" prop="dayOfWeek">
          <el-select
            v-model="form.dayOfWeek"
            placeholder="请选择周期日历"
            filterable
          >
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type === 4" label="周期日历" prop="dayOfMonth">
          <el-select
            v-model="form.dayOfMonth"
            placeholder="请选择周期日历"
            filterable
          >
            <el-option
              v-for="item in dateMonthList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="type === 2 || type === 3 || type === 4"
          label="执行时间"
          prop="expectedtime"
        >
          <!-- <div class="required">*</div> -->
          <el-time-picker
            v-model="form.expectedtime"
            placeholder="请选中执行时间"
            format="HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>

        <el-form-item
          v-if="type === 2 || type === 3 || type === 4"
          label="有效期限"
          prop="scheduledDate"
        >
          <el-date-picker
            v-model="form.scheduledDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd "
            value-format="yyyy-MM-dd "
            :picker-options="pickerOptionsStart"
          />
        </el-form-item>
        <!-- <el-form-item label="无人机" prop="deviceName">
          <div class="required">*</div>
          <el-select v-model="form.uav" placeholder="选择无人机" filterable>
            <el-option
              v-for="item in uav_options"
              :key="item.value"
              :label="item.label"
              :value="item"
              value-key
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="任务描述" style="margin-top: 15px">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            placeholder="任务描述"
            :show-word-limit="true"
            maxlength="64"
            :autosize="{ minRows: 3, maxRows: 5 }"
          />
        </el-form-item>
        <div class="form_btn">
          <div class="btn_create" @click="addTask">创建</div>
          <div class="btn_cancel" @click="cancel">取消</div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { flightTaskAPI, Control_API, Tg } from "@/api";
import { AirLine } from "@/api";
import dayjs from "dayjs";
export default {
  inject: ["change_active"],
  props: {
    target: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const validateTaskName = (rule, value, callback) => {
      const regEn = /[`~!@#$%^&*_+<>?:"{},.\/;'[\]]/im.test(value);
      if (!value || (value && !value.trim())) {
        callback(new Error("请输入任务名称！"));
      } else if (regEn) {
        callback(new Error("不能输入特殊符号！"));
      } else {
        callback();
      }
    };
    const validateRouteName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择航线名称！"));
      } else {
        callback();
      }
    };
    const validateOrganizationName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择责任单位！"));
      } else {
        callback();
      }
    };
    const validateExpectedtime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择开始时间！"));
      } else {
        callback();
      }
    };
    const validateDeviceName = (rule, value, callback) => {
      if (!this.form.uav) {
        if (!this.form.organizationName) {
          callback(new Error("请选择责任单位！"));
        } else {
          callback(new Error("请选择无人机！"));
        }
      } else {
        callback();
      }
    };
    const validateprocessId = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择任务类型！"));
      } else {
        callback();
      }
    };

    return {
      pickerOptionsStart: {
        disabledDate: (time) => {
          // 日期选择限制
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange: `${dayjs().format("HH:mm:ss")} - 23:59:59`,
      },
      rules: {
        sceneType: [
          { required: true,  message: "请选择任务场景", trigger: ["change"] },
        ],
        taskName: [
          { required: true, validator: validateTaskName, trigger: ["change"] },
        ],
        routeId: [
          { required: true, validator: validateRouteName, trigger: ["change"] },
        ],
        processId: [
          { required: true, validator: validateprocessId, trigger: ["change"] },
        ],
        deviceName: [
          {
            required: true,
            validator: validateDeviceName,
            trigger: ["change"],
          },
        ],
        orgId: [
          {
            required: true,
            validator: validateOrganizationName,
            trigger: ["change"],
          },
        ],
        expectedtime: [
          {
            required: true,
            validator: validateExpectedtime,
            trigger: ["change"],
          },
        ],
        type: [
          { required: true, message: "请选择周期类型", trigger: ["change"] },
        ],
        dayOfWeek: [
          { required: true, message: "请选择周期日历", trigger: ["change"] },
        ],
        dayOfMonth: [
          { required: true, message: "请选择周期日历", trigger: ["change"] },
        ],
      },
      // form: {
      //   taskName: '',
      //   routeId: '',
      //   organizationName: '科比特',
      //   uav: '',
      //   processId: "",
      //   remark: ''
      // },
      form: {
        taskName: "",
        routeId: "",
        organizationName: "科比特",
        uav: "",
        processId: "",
        remark: "",
        expectedtime: "",
        orgId: "",
        type: 2,
      },
      filename_options: [],
      company_options: [],
      uav_options: [{}],
      isDeviceHaveTask: false,
      organizationId: null,
      taskTypeList: [],
      orgOptions: [],
      dateList: [
        {
          value: 1,
          label: "周一",
        },
        {
          value: 2,
          label: "周二",
        },
        {
          value: 3,
          label: "周三",
        },
        {
          value: 4,
          label: "周四",
        },
        {
          value: 5,
          label: "周五",
        },
        {
          value: 6,
          label: "周六",
        },
        {
          value: 7,
          label: "周日",
        },
      ],
      sceneTypeList: [
        {
          value: 1,
          label: "巡查",
        },
        {
          value: 2,
          label: "预警",
        },
        {
          value: 3,
          label: "测量",
        },
        {
          value: 4,
          label: "侦测",
        },
        {
          value: 5,
          label: "服务",
        },
        {
          value: 6,
          label: "救援",
        },
        {
          value: 7,
          label: "处置",
        },
        {
          value: 8,
          label: "打击",
        },
        {
          value:  9,
          label: "宣传",
        },
      ],
      dateMonthList: [],
      createDisable: false,
    };
  },

  computed: {
    ...mapState(["projectData"]),
  },
  async created() {
    // await this.getFlightList();
    // await this.getOrgList();
    // await this.getCompanyList()
    await this.getUavList();
    this.processProcessBoxProjectIdFN();
    this.getDateMonthList();
  },
  mounted() {},
  methods: {
    async changeProcessId(val) {
      const clickItem = this.taskTypeList.find((item) => item.value === val);
      const res = await Tg.getProcessList({
        lastLabelOrgId: clickItem.lastLabelOrgId,
      });
      if (res.code === 200) {
        this.orgOptions = res.data;
        this.find(this.orgOptions);
        this.form.orgId = null;
        this.form.routeId = null;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate("routeId");
        });
      }
    },
    async changeOrgId(val) {
      const res = await Tg.getRouteList({
        organId: val,
      });
      if (res.code === 200) {
        const fileArr = res.data.map((item) => {
          return { value: item.id, label: item.name };
        });
        this.filename_options = fileArr;
        this.form.routeId = null;
        this.$nextTick(() => {
          this.$refs["form"].clearValidate("routeId");
        });
      }
    },
    getDateMonthList() {
      for (let index = 1; index <= 31; index++) {
        if (index === 31) {
          return this.dateMonthList.push({
            label: "最后一天",
            value: "L",
          });
        }
        this.dateMonthList.push({
          label: index,
          value: index,
        });
      }
    },
    async getOrgList() {
      const res = await Control_API.getAllOrg();
      if (res.code === 200) {
        this.orgOptions = res.data;
        this.find(this.orgOptions);
      }
    },
    find(list) {
      list.map((item) => {
        if (!item.childNodes.length) {
          return (item.childNodes = undefined);
        } else {
          this.find(item.childNodes);
        }
      });
    },
    async processProcessBoxProjectIdFN() {
      const res = await flightTaskAPI.processProcessBoxProjectId(
        this.projectData?.projectId ||
          JSON.parse(localStorage.getItem("subplatformInfo")).projectId
      );
      console.log(res, "res");
      if (res.code == 200) {
        this.taskTypeList = res.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            lastLabelOrgId: item.lastLabelOrgId,
          };
        });
      }
    },
    async getFlightList() {
      const param = {
        page: 1,
        pageSize: 9999,
      };
      const resp = await AirLine.lineList(param);
      if (resp.code === 200 && resp.data.records.length) {
        const fileArr = resp.data.records.map((item) => {
          return { value: item.id, label: item.name };
        });
        this.filename_options = fileArr;
      }
    },

    async getCompanyList() {
      const resp = await flightTaskAPI.getCompany();
      const companyArr = [
        {
          value: resp.data.name,
          label: resp.data.name,
        },
      ];
      this.form.organizationName = resp.data.name;
      this.organizationId = resp.data.id;
      this.company_options = companyArr;
    },

    async getUavList() {
      // const param = {
      //   organizationId: 1
      // };
      const resp = await flightTaskAPI.getUavflyList();
      let uavArr = {};
      if (resp.code == 200) {
        uavArr = resp.data.records.map((item) => {
          return {
            value: item.id,
            label: item.name,
            obj: item,
          };
        });
      } else {
        this.$message({
          type: "error",
          message: "获取设备列表失败！",
          duration: 1000,
        });
        uavArr = {};
      }
      this.uav_options = uavArr;
    },

    // 设备状态
    async getDeviceHaveTask(uavId) {
      const formdata = new FormData();
      formdata.append("id", uavId);
      let state = false;
      await flightTaskAPI.isDeviceHaveTask(formdata).then((resp) => {
        if (resp.code == 500) {
          state = false;
        } else {
          state = true;
        }
      });
      return state;
    },

    addTask() {
      const param = {
        deviceHardId: this.form.uav && this.form.uav.obj.hardId,
        projectId: JSON.parse(localStorage.getItem("subplatformInfo"))
          .projectId,
        processId: this.form?.processId,
        remark: this.form.remark,
        routeId: this.form.routeId,
        taskName: this.form.taskName,
        orgId: this.form.orgId,
        sceneType:this.form.sceneType,
        expectedtime: this.form.expectedtime,
        target: this.target,
        // 常态任务定时任务不需要 传 有效期时间
        scheduledStart: this.form.scheduledDate && this.form.expectedtime,
        scheduledEnd:
          this.form.scheduledDate && this.form.scheduledDate[1] + "23:59:59",
        dayOfWeek: this.form.dayOfWeek,
        dayOfMonth: this.form.dayOfMonth,
      };
      if (this.target && this.type === 2) {
        param.type = this.form.type;
      } else if (this.target && this.type) {
        param.type = this.type;
      } else {
        param.type = undefined;
      }
      this.$refs["form"].validate((validate) => {
        if (validate) {
          // 防止多次点击
          if (this.createDisable) {
            return;
          }
          this.createDisable = true;
          flightTaskAPI
            .tssTask({ ...param })
            .then((resp) => {
              this.createDisable = false;
              if (resp.code == 200) {
                this.change_active(1);
                this.$message({
                  showClose: true,
                  type: "success",
                  message: "创建任务成功",
                  duration: 1000,
                });
                this.$bus.$emit("reloadTaskList");
                this.$bus.$emit("closeSideList");
              } else {
                // this.change_active(4);
                this.$message({
                  showClose: true,
                  type: "error",
                  message: resp.msg || "操作失败",
                  duration: 1000,
                });
              }
            })
            .catch(() => {
              this.createDisable = false;
            });
        }
      });
    },
    cancel() {
      this.$bus.$emit("closeSideList");
    },
  },
};
</script>

<style lang="scss" scoped>
.flight-task-create {
  height: 100%;
  // background: rgba(19, 21, 24, 0.5);
  // box-shadow: inset 0 1px 8px 0 #2883b3,
  //   inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
  //   inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  // border: 1px solid #3bc1e5;
  // position: absolute;
  // top: 200px;
  // right: 700px;
  margin-left: 28px;
  position: relative;

  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    border-radius: 4px 4px 0 0;
    margin-left: 10px;

    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }

    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .body {
    height: calc(100% - 105px);
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 10px;
    border-bottom: 1px solid #23364b;
    padding-bottom: 30px;
    // border: 1px solid;
    // border-top: 1px solid #494949;

    .el-form {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      letter-spacing: 0;
      font-weight: 400;

      &::v-deep {
        .el-radio {
          color: #fff;
        }
        .el-date-editor {
          &.el-range-editor.el-input__inner.el-date-editor--daterange {
            width: 227px;
            height: 32px;
            background-color: #16181b;
          }
          .el-range-input {
            background-color: #16181b;
            color: #fff;
          }
          .el-range-separator {
            color: #fff;
            line-height: 26px;
          }
          .el-input__inner {
            border: none;
          }
          .el-range__icon {
            line-height: 26px;
          }
        }
        .el-form-item__error {
          padding-top: 0px;
          position: absolute;
          bottom: -12px;
          margin-left: 15px;
        }

        .el-input__count {
          background: none;
          position: absolute;
          right: 16px;
          bottom: -7px;
        }

        .el-form-item {
          margin-bottom: 16px;

          .required {
            color: #ed6a6a;
            width: 4px;
            position: absolute;
            left: -29%;
          }

          .el-form-item__label {
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #b3bbc5;
            width: 100px;
          }

          .el-input {
            width: 227px;
            height: 32px;

            .el-input__inner {
              height: 100%;
              width: 100%;
              border-radius: 8px;
              border: none;
              font-family: PingFangSC-Regular;
              font-size: 12px;
              letter-spacing: 0;
              font-weight: 400;
              color: #c6ccd4;
              background: #16181b;
            }

            .el-input__icon {
              line-height: 32px;
              color: #1eb9fa;
            }
          }

          .el-textarea__inner {
            margin-top: 4px;
            width: 227px;
            height: 100px;
            max-height: 100px;
            min-height: 100px;
            background: #16181b;
            border-radius: 8px;
            border: none;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #c6ccd4;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
      }
    }

    .form_btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-family: MicrosoftYaHei;

      .btn_create,
      .btn_cancel {
        box-sizing: border-box;
        width: 92px;
        height: 32px;
        background: #3a4252;
        border: 1px solid #3bc1e5;
        border-radius: 4px;
        line-height: 32px;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
