<template>
  <div v-if="navIndex" class="tb-box">
    <div v-if="navIndex == 3" class="tb-hd-box">
      <div class="tb-hd" title="序号">序号</div>
      <div class="tb-hd" title="无人机名称">无人机名称</div>
      <div class="tb-hd" title="健康状态">健康状态</div>
      <div class="tb-hd" title="联网状态">联网状态</div>
      <div v-if="isLogin" class="tb-hd">操作</div>
    </div>
    <div v-if="navIndex == 4" class="tb-hd-box">
      <div class="tb-hd" title="序号">序号</div>
      <div class="tb-hd" title="飞手名称">飞手名称</div>
      <div class="tb-hd" title="手机号码">手机号码</div>
    </div>
    <div v-if="navIndex == 5 || navIndex == 6 || navIndex == 7" class="tb-hd-box">
      <div class="tb-hd" title="序号">序号</div>
      <div class="tb-hd" title="网点名称">网点名称</div>
      <div class="tb-hd" title="业务描述">业务描述</div>
      <div class="tb-hd" title="操作">操作</div>
    </div>
    <div v-if="navIndex == 8" class="tb-hd-box">
      <div class="tb-hd" title="序号">序号</div>
      <div class="tb-hd" title="项目名称">项目名称</div>
      <div class="tb-hd" title="操作">操作</div>
    </div>
    <div class="tb-bd-box">
      <div v-for="(item, index) in rightPointsList" :key="index" class="tb-tr">
        <div class="td">{{ index + 1 || '暂无' }}</div>
        <div class="td">{{ item.carNo || item.name || '暂无' }}</div>
        <div v-if="navIndex !== 8" class="td">
          {{
            navIndex == 4
              ? item.phone_num
              : false || navIndex == 3
              ? item.healthStatus
              : false || '暂无'
          }}
        </div>
        <div v-if="navIndex == 3" class="td">
          <!-- {{ item.ipId ? "已联网" : "未联网" || "暂无" }} -->
          {{ item.online ? '在线' : '离线' || '暂无' }}
        </div>
        <div v-if="navIndex !== 4 && navIndex !== 8 && isLogin" class="td">
          <el-tooltip v-hover effect="dark" content="详情" placement="top">
            <div
              v-if="permissionFn(navIndex)"
              class="iconfont icon-chakan"
              @click="goDetails(item)"
            />
          </el-tooltip>
        </div>
        <div v-if="navIndex == 8" class="td">
          <a v-if="permissionFn(navIndex)" style="color: #336696" @click="goDetails(item)">查看</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { projectAPI } from '@/api';
import { permissionIds } from '@/utils/permission';

export default {
  inject: ['change_active'],
  data() {
    return {
      isShow: false,
      navIndex: null,
      rightPointsList: [],
      isLogin: false
    };
  },
  mounted() {
    this.isLogin = !!window.localStorage.getItem('tmj_token');
    this.showAllData();
    this.getItemList();
    this.$bus.$on('openSideList', () => {
      this.isShow = true;
      this.navIndex = null;
    });
    this.$bus.$on('closeSideList', () => {
      this.isShow = false;
    });
  },
  methods: {
    permissionFn(item) {
      // 无人机 470 飞手资源 471 服务网点 474 培训网点 476 运行项目480 展示网点 478
      let pid = null;
      if (item == 3) {
        pid = 470;
      } else if (item == 5) {
        pid = 474;
      } else if (item == 6) {
        pid = 476;
      } else if (item == 7) {
        pid = 478;
      } else if (item == 8) {
        pid = 480;
      }
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    showAllData() {
      this.$bus.$on('showSideList', () => {
        this.rightPointsList = this.sideList;
      });
    },
    getItemList() {
      this.$bus.$on('handleItemList', (list, show, e) => {
        this.navId = e.id;
        this.sideList = list;
        this.rightPointsList = this.sideList;
        this.navIndex = show;
        this.navName = e.name;
      });
    },
    // 详情点击
    async goDetails(item) {
      if (this.navId == 3) {
        // 展示无人机
        this.change_active(10);
        this.$nextTick(() => {
          this.$bus.$emit('uavDataDetails', item);
        });
      } else if (this.navId === 8) {
        if (item) {
          this.change_active(13);
          this.$nextTick(() => {
            this.$bus.$emit('runProjectDetails', item);
          });
        } else {
          this.$message({
            type: 'error',
            message: '没有数据',
            duration: 1000
          });
        }
      } else {
        // 展示网点详细
        // this.get_sidelist_item(item)
        this.change_active(11);
        console.log('==============', item);
        this.$nextTick(() => {
          this.$bus.$emit('networkDataLils', item);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/list-common.scss';

.tb-box {
  padding: 0;

  .tb-hd-box {
    .tb-hd {
      overflow: hidden;
    }
  }
}
</style>
