<script>
import GasPanels from './components/gas-panels/index.vue';
import WaterPanels from './components/water-panels/index.vue';
export default {
  name: 'Index',
  components: {
    GasPanels,
    WaterPanels
  },
  data() {
    return {
      earlyCellList: [
        {
          id: 1,
          label: '气体监测预警'
        },
        {
          id: 2,
          label: '水位监测预警'
        }
      ],
      selectCellIndex: -1
    };
  },
  methods: {
    selectCellIndexEvent(index) {
      this.selectCellIndex = index;
    }
  }
};
</script>

<template>
  <div class="early-warning">
    <div class="early-warning-content">
      <div
        v-for="(v, index) in earlyCellList"
        :key="v.id"
        class="early-cell"
        :class="{ 'early-cell-active': index === selectCellIndex }"
        @click="selectCellIndexEvent(index)"
      >
        {{ v.label }}
      </div>
    </div>
    <!--气体预警-->
    <gas-panels v-if="selectCellIndex === 0" />
    <!--水位预警-->
    <water-panels v-if="selectCellIndex === 1" />
  </div>
</template>

<style scoped lang="scss">
.early-warning {
  &-content {
    .early-cell {
      height: 36px;
      font-size: 12px;
      color: #c6ccd4;
      line-height: 36px;
      padding: 0 16px;
      box-sizing: border-box;
      background-color: #14151a;
      border: 1px solid #14151a;
      cursor: pointer;
      &-active {
        border: 1px solid #336696;
      }
    }
  }
}
</style>
