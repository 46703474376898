<template>
  <el-dialog
    :title="showStatus === 1 ? '图片' : '视频'"
    :visible="picturesVisible"
    :close-on-click-modal="false"
    custom-class="pictures-list"
    @close="onClose"
  >
    <div v-if="dataList.length != 0">
      <div v-for="item in dataList" :key="item.id" class="list-item">
        <div class="top wih100">
          <!-- <img
            class="wih100 ht100 dib"
            crossorigin="anonymous"
            v-if="showStatus === 1"
            :src="item.image"
            alt="#"
          /> -->
          <el-image
            :preview-src-list="[item.image]"
            class="wih100 ht100 dib"
            v-if="showStatus === 1"
            :src="item.image"
            @click="addAttribute(item.image)"
            crossorigin="anonymous"
          ></el-image>
          <video v-else :src="item.video" />
        </div>
        <div class="content">
          <div class="content-item">
            <span class="item-label">时间：</span>
            <span class="item-value" :title="item.addtime">{{
              item.addtime || "暂无"
            }}</span>
          </div>
          <div class="content-item">
            <span class="item-label">名称：</span>
            <span class="item-value" :title="item.name">{{
              item.name || "暂无"
            }}</span>
          </div>
          <div class="content-item">
            <span class="item-label">架次：</span>
            <span class="item-value" :title="item.flightSortieName">{{
              item.flightSortieName || "暂无"
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data">暂无数据</div>
  </el-dialog>
</template>

<script>
import { ThreeDimensionalApi } from "@/api";
export default {
  props: {
    picturesVisible: {
      type: Boolean,
      default: false,
    },
    stateRow: {
      type: Object,
      default: () => {},
    },
    showStatus: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      dataList: [],
    };
  },
  async created() {
    if (this.picturesVisible) {
      await this.getList();
    }
  },
  methods: {
    addAttribute() {
      this.$nextTick((e) => {
        document
          .querySelector(".el-image-viewer__canvas")
          .childNodes[0].setAttribute("crossorigin", "anonymous");
      });
    },
    onClose() {
      this.$emit("closePictures");
    },
    async getList() {
      if (this.showStatus === 1) {
        const res = await ThreeDimensionalApi.getSortieImage({
          sortie: this.stateRow.flightSortieName,
        });
        this.dataList = res.data.records;
      } else {
        const res = await ThreeDimensionalApi.getSortieVideo({
          id: this.stateRow.id,
        });
        this.dataList = res.data.records;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog.pictures-list {
    width: 472px;
    height: 482px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
      inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 4px 0px #2e63dc;
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(52, 223, 255, 1),
        rgba(24, 113, 214, 0)
      )
      1 1;
    backdrop-filter: blur(4px);
    padding: 4px;
    .el-dialog__header {
      height: 32px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
        0px 1px 0px 0px rgba(255, 255, 255, 0.05),
        inset 0px -1px 0px 0px #000000;
      border-radius: 4px 4px 0px 0px;
      .el-dialog__title {
        width: 28px;
        height: 19px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ebf5ff;
        line-height: 19px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      }
    }
    .el-dialog__body {
      display: flex;
      flex-wrap: wrap;
      height: 380px;
      background: rgba(0, 0, 0, 0.95);
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      padding: 8px;
      overflow-y: auto !important;
      .list-item {
        width: 408px;
        height: 273px;
        background: #2f313b;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        padding: 3px 4px;
        margin-right: 10px;
        margin-bottom: 12px;
        &:nth-child(even) {
          margin-right: 0;
        }
        .top {
          // width: 206px;
          height: 207px;
          border: 1px solid #ccc;
          margin: 0 auto;
        }
        .content {
          padding-left: 12px;
          .content-item {
            display: flex;
            align-items: center;
            margin-top: 5px;
            .item-label {
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: #c6ccd4;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            }
            .item-value {
              display: inline-block;
              width: 140px;
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: #c6ccd4;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
    .no-data {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
