<template>
  <div class="flight-task-edit">
    <div class="header">
      <div class="left">
        <el-tabs
          v-if="taskTitle === '任务详情'"
          v-model="taskType"
          @tab-click="handleClick"
        >
          <el-tab-pane label="基本信息" name="0" />
          <el-tab-pane label="任务流程" name="1" />
        </el-tabs>
        <span v-else class="title">{{ taskTitle }}</span>
      </div>
      <span v-hover class="iconfont icon-guanbi" @click="cancel" />
      <!-- v-interact -->
    </div>
    <div
      v-if="taskType === '1' && form.taskOperators && form.taskOperators.length"
      class="flow-chart"
    >
      <div class="example">
        <div class="item">
          <div class="example-line" />
          <div class="example-label">正常或通过</div>
        </div>
        <div class="item">
          <div class="example-line back" />
          <div class="example-label">异常或驳回</div>
        </div>
      </div>
      <div
        v-for="(item, index) in form.taskOperators"
        :key="item.id"
        class="chart-body"
      >
      <!-- isOperate 操作状态 0无需操作 1未操作2 成功 3失败 -->
      <!-- taskStatus 任务执行状态 0待执行 1执行中 2完成 -->
      <!-- functionType 标签功能 0发起 1审批 2执行-->
        <div
          v-if="item.functionType !== 1"
          class="pie"
          :class="{
            active: item.isOperate === 0 || item.isOperate === 2 ,
          }"
          @click="openTaskDetailsList(item)"
        >
        <!-- ||form.taskStatus==2  ? true : false -->
          <div class="pie-body">
            <div
              class="label-functionType"
              :title="typeFilter(item.functionType)"
            >
              {{ typeFilter(item.functionType) }}
            </div>
            <div class="label-orgName" :title="item.operatorName">
              {{ item.operatorName }}
            </div>
          </div>
        </div>

        <div
          v-if="item.functionType === 1"
          class="diamond-pro"
          @click="taskDetails = item"
        >
          <div class="diamond-body">
            <div
              class="label-functionType"
              :title="typeFilter(item.functionType)"
            >
              {{ typeFilter(item.functionType) }}
            </div>
            <div class="label-orgName" :title="item.operatorName">
              {{ item.operatorName }}
            </div>
          </div>
          <div
            class="diamond"
            :class="{
              active:
                item.isOperate === 0 || item.isOperate === 2 ? true : false,
            }"
          />
        </div>
        <!-- 驳回线条 -->
        <!-- :style="{ height: `${90 + 43 + (90 * 1.414) / 2 - 90 / 2}px` }" -->
        <div
          v-if="item.functionType === 1 && item.isOperate === 3"
          class="arrow-back"
          :style="{ height: heightComputed(item, index) }"
        >
          <div class="back-top-box">
            <div class="head" />
            <div
              class="line"
              style="height: 3px"
              :style="{
                width: `calc(100% + (90px) * 1.414 / 2 - (90px) / 2 - 8px)`,
              }"
            />
          </div>
          <div class="back-middle-box line" />
          <div class="back-bottom-box line" />
        </div>

        <div v-if="index !== form.taskOperators.length - 1" class="arrow">
          <div class="arrow-line" />
          <div class="arrow-head" />
        </div>
      </div>
    </div>
    <div v-else class="body">
      <el-form
        ref="form"
        :disabled="
          form.clickStatus === 'examine' || form.clickStatus === 'details'
        "
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item
          v-if="form.clickStatus === 'reload'"
          label="驳回原因"
          prop="name"
        >
          <el-input
            v-model="form.operateDescribe"
            type="textarea"
            :rows="3"
            placeholder="驳回原因"
            maxlength="64"
            :show-word-limit="true"
            :autosize="{ minRows: 3, maxRows: 5 }"
          />
        </el-form-item>
        <el-form-item label="任务名称" prop="taskName">
          <!-- <div class="required">*</div> -->
          <el-input
            v-model="form.taskName"
            placeholder="请输入任务名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="任务流程" prop="processId">
          <el-select
            v-model="form.processId"
            disabled
            placeholder="请选择任务流程"
            filterable
          >
            <el-option
              v-for="item in taskTypeList"
              :key="item.value"
              placeholder="请选择任务流程"
              :label="item.label"
              :value="item.value"
              @change="changeProcessId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="任务场景" prop="sceneType">
          <el-select
            v-model="form.sceneType"
            disabled
            filterable
          >
            <el-option
              v-for="item in sceneTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="责任单位" prop="orgName">
          <div class="required">*</div>
          <el-input
            v-model="form.orgName"
            placeholder="请输入责任单位名称"
            readonly
          />
        </el-form-item> -->
        <el-form-item label="责任机构" prop="orgId">
          <!-- <div class="required">*</div> -->

          <el-cascader
            v-model="form.orgId"
            placeholder="请选择责任机构"
            :show-all-levels="false"
            :options="orgOptions"
            :props="{
              value: 'id',
              label: 'name',
              children: 'childNodes',
              checkStrictly: true,
              emitPath: false,
            }"
            :disabled="form.clickStatus === 'edit'"
            @change="changeOrgId"
          />
        </el-form-item>
        <el-form-item label="航线名称" prop="routeName">
          <!-- <div class="required">*</div> -->
          <el-select
            v-model="form.routeId"
            placeholder="请选择航线名称"
            filterable
          >
            <el-option
              v-for="item in filename_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="开始时间" prop="expectedtime">
          <div class="required">*</div>
          <el-date-picker
            v-model="form.expectedtime"
            type="datetime"
            format="yyyy-MM-dd HH:MM:SS"
            value-format="yyyy-MM-dd HH:MM:SS"
            placeholder="请选择预计开始时间"
            align="right"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item v-if="type === 0" label="开始时间" prop="expectedtime">
          <!-- <div class="required">*</div> -->
          <el-date-picker
            v-model="form.expectedtime"
            type="datetime"
            format="yyyy-MM-dd HH:MM:SS"
            placeholder="请选择预计开始时间"
            align="right"
            @change="changeSchedul = true"
          />
          <!-- :picker-options="pickerOptionsStart" -->
        </el-form-item>
        <el-form-item
          v-else-if="type === 1"
          label="定时执行时间"
          prop="expectedtime"
        >
          <!-- <div class="required">*</div> -->
          <el-date-picker
            v-model="form.expectedtime"
            type="datetime"
            format="yyyy-MM-dd HH:MM:SS"
            placeholder="请选择预计开始时间"
            align="right"
            :picker-options="pickerOptionsStart"
            @change="changeSchedul = true"
          />
        </el-form-item>
        <el-form-item v-if="type === 2" label="周期类型" prop="type">
          <el-radio-group v-model="form.type" @input="changeSchedul = true">
            <el-radio :label="2">每日</el-radio>
            <el-radio :label="3">每周</el-radio>
            <el-radio :label="4">每月</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type === 3" label="周期日历" prop="dayOfWeek">
          <el-select
            v-model="form.dayOfWeek"
            placeholder="请选择周期日历"
            filterable
            @change="changeSchedul = true"
          >
            <el-option
              v-for="item in dateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type === 4" label="周期日历" prop="dayOfMonth">
          <el-select
            v-model="form.dayOfMonth"
            placeholder="请选择周期日历"
            filterable
            @change="changeSchedul = true"
          >
            <el-option
              v-for="item in dateMonthList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="type === 2 || type === 3 || type === 4"
          label="执行时间"
          prop="expectedtime"
        >
          <!-- <div class="required">*</div> -->
          <el-time-picker
            v-model="form.expectedtime"
            placeholder="请选择执行时间"
            format="HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          />
        </el-form-item>

        <el-form-item
          v-if="type === 2 || type === 3 || type === 4"
          label="有效期限"
          prop="scheduledDate"
        >
          <el-date-picker
            v-model="form.scheduledDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <!-- <el-form-item label="无人机" prop="deviceHardId">
          <div class="required">*</div>
          <el-select
            v-model="form.deviceHardId"
            placeholder="选择无人机"
            filterable
          >
            <el-option
              v-for="item in uav_options"
              :key="item.value"
              :label="item.label"
              :value="item"
              value-key
            />
          </el-select>
        </el-form-item> -->
        <el-form-item label="任务描述" style="margin-top: 15px">
          <el-input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            placeholder="任务描述"
            maxlength="64"
            :show-word-limit="true"
            :autosize="{ minRows: 3, maxRows: 5 }"
          />
        </el-form-item>
        <div v-if="form.clickStatus === 'examine'" class="form_btn">
          <div class="btn btn_off" @click="showOperateDescribe">不通过</div>
          <div class="btn btn_on" @click="examineTask(2)">通过</div>
        </div>
        <div v-else-if="form.clickStatus === 'reload'" class="form_btn">
          <div class="btn btn_create" @click="changeTask">创建</div>
          <div class="btn btn_cancel" @click="cancel">取消</div>
        </div>
        <div v-else-if="form.clickStatus === 'edit'" class="form_btn">
          <div class="btn btn_create" @click="changeTask">修改</div>
          <div class="btn btn_cancel" @click="cancel">取消</div>
        </div>
      </el-form>
    </div>
    <!-- 驳回原因弹框 -->
    <div v-if="openOperateDescribe" class="operateDescribe">
      <div class="header">
        <div class="left">
          <span class="title">{{ taskTitle }}</span>
        </div>
        <span
          v-hover
          class="iconfont icon-guanbi"
          @click="hiddleOperateDescribe"
        />
      </div>
      <div class="operateDescribe-body">
        <span class="required">*</span>
        <span class="label">驳回原因</span>
        <el-input
          v-model="operateDescribe"
          type="textarea"
          :rows="8"
          placeholder="驳回原因"
          maxlength="64"
          :show-word-limit="true"
          :autosize="{ minRows: 3, maxRows: 5 }"
        />
      </div>
      <div class="form_btn">
        <div class="btn btn_off" @click="examineTask(3)">驳回</div>
        <div class="btn btn_cancel" @click="hiddleOperateDescribe">取消</div>
      </div>
    </div>

    <div v-if="taskDetails" class="taskBox taskDetails">
      <div v-interact class="head">
        <div class="left">
          <span class="title">任务流程详情</span>
        </div>
        <div class="right">
          <span
            v-hover
            class="iconfont icon-guanbi"
            @click="closeTaskDetails"
          />
        </div>
      </div>
      <div class="body">
        <div class="item">
          <div class="label">流程节点名称</div>
          <div class="value">{{ taskDetails.labelName || "暂无" }}</div>
        </div>
        <div class="item">
          <div class="label">节点功能</div>
          <div class="value">
            {{ functionTypeList[taskDetails.functionType].label || "暂无" }}
          </div>
        </div>
        <div class="item">
          <div class="label">指派操作员</div>
          <div class="value">{{ taskDetails.operatorName || "暂无" }}</div>
        </div>
        <div class="item">
          <div class="label">指派类型</div>
          <div class="value">
            {{ operateClassList[taskDetails.operateClass].label || "暂无" }}
          </div>
        </div>
        <div class="item">
          <div class="label">操作状态</div>
          <div class="value">
            {{ isOperateList[taskDetails.isOperate].label || "暂无" }}
          </div>
        </div>
        <div class="item">
          <div class="label">实际操作员</div>
          <div class="value">
            {{ taskDetails.operateRealUsername || "暂无" }}
          </div>
        </div>
        <div class="item">
          <div class="label">实际操作员机构</div>
          <div class="value">
            {{ taskDetails.operateRealOgrName || "暂无" }}
          </div>
        </div>
        <div class="item">
          <div class="label">操作时间</div>
          <div class="remark">{{ taskDetails.operateTime || "暂无" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AirLine, flightTaskAPI, Control_API } from "@/api";
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  inject: ["change_active", "flight_info", "get_flight_state"],
  props: {
    target: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const validateTaskName = (rule, value, callback) => {
      const regEn = /[`~!@#$%^&*_+<>?:"{},.\/;'[\]]/im.test(value);
      if (!value || !value.trim()) {
        callback(new Error("请输入任务名！"));
      } else if (regEn) {
        callback(new Error("不能输入特殊符号！"));
      } else {
        callback();
      }
    };
    const validateRouteName = (rule, value, callback) => {
      if (!value || !value.trim()) {
        callback(new Error("请输入航线名！"));
      } else {
        callback();
      }
    };
    const validateOrganizationName = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择责任机构！"));
      } else {
        callback();
      }
    };
    const validateDeviceName = (rule, value, callback) => {
      if (!this.form.uav) {
        if (!this.form.orgId) {
          callback(new Error("请选择责任机构！"));
        } else {
          callback(new Error("请选择设备！"));
        }
      } else {
        callback();
      }
    };
    const validateExpectedtime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择开始时间！"));
      } else {
        callback();
      }
    };
    return {
      sceneTypeList: [
        {
          value: 1,
          label: "巡查",
        },
        {
          value: 2,
          label: "预警",
        },
        {
          value: 3,
          label: "测量",
        },
        {
          value: 4,
          label: "侦测",
        },
        {
          value: 5,
          label: "服务",
        },
        {
          value: 6,
          label: "救援",
        },
        {
          value: 7,
          label: "处置",
        },
        {
          value: 8,
          label: "打击",
        },
        {
          value:  9,
          label: "宣传",
        },
      ],
      pickerOptionsStart: {
        disabledDate: (time) => {
          // 日期选择限制
          return time.getTime() < Date.now() - 8.64e7;
        },
        selectableRange: `${dayjs().format("HH:mm:ss")} - 23:59:59`,
      },
      rules: {
        taskName: [
          { required: true, validator: validateTaskName, trigger: ["change"] },
        ],
        routeName: [
          { required: true, validator: validateRouteName, trigger: ["change"] },
        ],
        deviceName: [
          {
            required: true,
            validator: validateDeviceName,
            trigger: ["change"],
          },
        ],
        orgId: [
          {
            required: true,
            validator: validateOrganizationName,
            trigger: ["change"],
          },
        ],
        expectedtime: [
          {
            required: true,
            validator: validateExpectedtime,
            trigger: ["change"],
          },
        ],
        type: [
          {
            required: true,
            required: true,
            message: "请选择周期类型",
            trigger: ["change"],
          },
        ],
        dayOfWeek: [
          {
            required: true,
            required: true,
            message: "请选择周期日历",
            trigger: ["change"],
          },
        ],
        dayOfMonth: [
          {
            required: true,
            required: true,
            message: "请选择周期日历",
            trigger: ["change"],
          },
        ],
      },
      form: {
        sceneType:null,
        id: null,
        taskName: null,
        routeName: null,
        taskStatus: null,
        organizationName: "科比特",
        remark: null,
        deviceId: null,
        deviceName: null,
        deviceStatus: null,
        device: null,
      },
      device: {
        label: "",
        value: "",
        obj: {
          deviceHardId: "",
          status: "",
        },
      },
      filename_options: [],
      company_options: [],
      uav_options: [],
      organizationId: null,
      taskTypeList: [],
      openOperateDescribe: false,
      operateDescribe: "",
      orgOptions: [],
      dateList: [
        {
          value: 1,
          label: "周一",
        },
        {
          value: 2,
          label: "周二",
        },
        {
          value: 3,
          label: "周三",
        },
        {
          value: 4,
          label: "周四",
        },
        {
          value: 5,
          label: "周五",
        },
        {
          value: 6,
          label: "周六",
        },
        {
          value: 7,
          label: "周日",
        },
      ],
      dateMonthList: [],
      changeSchedul: false,
      taskType: 0,
      is_arrow_back: false,
      taskDetails: null,
      functionTypeList: [
        {
          value: 0,
          label: "发起",
        },
        {
          value: 1,
          label: "审批",
        },
        {
          value: 2,
          label: "执行",
        },
      ],
      operateClassList: [
        {
          label: "指定用户",
          value: 0,
        },
        {
          label: "指定机构",
          value: 1,
        },
      ],
      isOperateList: [
        {
          value: 0,
          label: "通过",
        },
        {
          value: 1,
          label: "未操作",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
    };
  },
  computed: {
    get_flight_info() {
      return this.flight_info();
    },
    taskTitle() {
      if (this.form.clickStatus === "examine") {
        return "任务审批";
      } else if (this.form.clickStatus === "reload") {
        return "重新发起";
      } else if (this.form.clickStatus === "edit") {
        return "任务编辑";
      } else if (this.form.clickStatus === "details") {
        return "任务详情";
      }
    },
    ...mapState(["projectData"]),
  },
  watch: {
    get_flight_info: {
      handler(nval, oval) {
        console.log(nval,'nval');
        this.form = nval;
        this.device.obj.deviceHardId = this.form.deviceHardId;
        this.device.obj.status = this.form.deviceStatus;
        // this.getFlightList();
        // this.getCompanyList()
        this.getUavList();
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    Promise.all([
      this.getFlightList(),
      this.getOrgList(),
      this.processProcessBoxProjectIdFN(),
      this.getUavList(),
      this.getDateMonthList(),
    ]).then(() => {
      this.form = JSON.parse(JSON.stringify(this.flight_info()));
      console.log(this.form, " this.form");
      if (
        (this.form.type === 2 ||
          this.form.type === 3 ||
          this.form.type === 4) &&
        this.form.scheduledStart &&
        this.form.scheduledEnd
      ) {
        this.form.scheduledDate = [
          this.form.scheduledStart,
          this.form.scheduledEnd,
        ];
      }
      if (this.form.type === 3) {
        this.form.dayOfWeek = Number(this.form.dayOfWeek);
      } else if (this.form.type === 4) {
        this.form.dayOfMonth = Number(this.form.dayOfMonth);
      }
    });
  },
  destroyed() {
    this.get_flight_state(false);
  },
  methods: {
    async changeProcessId(val) {
      const clickItem = this.taskTypeList.find((item) => item.value === val);
      const res = await Tg.getProcessList({
        lastLabelOrgId: clickItem.lastLabelOrgId,
      });
      if (res.code === 200) {
        this.orgOptions = res.data;
        this.find(this.orgOptions);
        this.form.orgId = null;
        this.form.routeId = null;
      }
    },
    async changeOrgId(val) {
      const res = await Tg.getRouteList({
        organId: val,
      });
      if (res.code === 200) {
        const fileArr = res.data.map((item) => {
          return { value: item.id, label: item.name };
        });
        this.filename_options = fileArr;
        this.form.routeId = null;
      }
    },
    openTaskDetailsList(item) {
      if (item.isOperate === 0) return;
      this.taskDetails = item;
    },
    closeTaskDetails() {
      this.taskDetails = null;
    },
    heightComputed(item, index) {
      const arr = [];
      this.form.taskOperators.map((col, ind) => {
        if (ind > index) return;
        if (col.functionType === 0 || col.functionType === 2) {
          arr.push(90);
        } else if (index > 1 && ind > 1) {
          arr.push(90 * 1.414);
        } else {
          arr.push((90 * 1.414) / 2);
        }
      });
      const height = arr.reduce((old, now) => {
        return old + now;
      }, 0);
      return height - 90 / 2 + (30 + 8 + 5) * index + "px";
    },
    typeFilter(typeNam) {
      if (typeNam === 0) {
        return "任务发起";
      } else if (typeNam === 1) {
        return "派出所审批";
      } else {
        return "任务执行";
      }
    },
    handleClick() {
      this.is_arrow_back = this.form.taskOperators.some(
        (i) => i.isOperate === 3
      );
    },
    getDateMonthList() {
      for (let index = 1; index <= 31; index++) {
        if (index === 31) {
          return this.dateMonthList.push({
            label: "最后一天",
            value: "L",
          });
        }
        this.dateMonthList.push({
          label: index,
          value: index,
        });
      }
    },
    async getOrgList() {
      const res = await Control_API.getAllOrg();
      if (res.code === 200) {
        this.orgOptions = res.data;
        this.find(this.orgOptions);
      }
    },
    find(list) {
      list.map((item) => {
        if (!item.childNodes.length) {
          return (item.childNodes = undefined);
        } else {
          this.find(item.childNodes);
        }
      });
    },
    showOperateDescribe() {
      this.openOperateDescribe = true;
    },
    hiddleOperateDescribe() {
      this.openOperateDescribe = false;
      this.operateDescribe = "";
    },
    async processProcessBoxProjectIdFN() {
      const res = await flightTaskAPI.processProcessBoxProjectId(
        this.projectData?.projectId ||
          JSON.parse(localStorage.getItem("subplatformInfo")).projectId
      );
      if (res.code == 200) {
        this.taskTypeList = res.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            lastLabelOrgId: item.lastLabelOrgId,
          };
        });
      }
    },
    cancel() {
      this.$bus.$emit("closeSideList");
    },
    changeTask() {
      const { device, form } = this;
      const param = {
        deviceHardId: device.obj.hardId,
        id: form.id,
        remark: form.remark,
        routeName: form.routeName,
        taskName: form.taskName,
        processId: form.processId,
        routeId: form.routeId,
        orgId: form.orgId,
        taskStatus: form.taskStatus,
        target: this.target,
        scheduledStart: this.form.scheduledDate && this.form.scheduledDate[0],
        scheduledEnd: this.form.scheduledDate && this.form.scheduledDate[1],
        dayOfWeek: this.form.dayOfWeek,
        dayOfMonth: this.form.dayOfMonth || undefined,
        changeSchedul: this.changeSchedul,
        expectedtime: this.form.expectedtime || undefined,
        projectId: JSON.parse(localStorage.getItem("subplatformInfo"))
          .projectId,
      };

      if (this.target && this.type === 2) {
        param.type = this.form.type;
      } else if (this.target && this.type) {
        param.type = this.type;
      } else {
        param.type = undefined;
      }

      this.$refs["form"].validate((validate) => {
        if (validate) {
          if (form.taskName) {
            if (form.clickStatus === "reload") {
              flightTaskAPI.reCreateTask(param).then((resp) => {
                if (resp.code == 200) {
                  this.change_active(1);
                  this.$message({
                    showClose: true,
                    type: "success",
                    message: resp.msg,
                    duration: 1000,
                  });
                  this.$bus.$emit("reloadTaskList");
                  this.$bus.$emit("closeSideList");
                } else {
                  this.$message({
                    showClose: true,
                    type: "error",
                    message: "修改成功",
                    duration: 1000,
                  });
                }
              });
            } else {
              flightTaskAPI.editFlight(param).then((resp) => {
                if (resp.code == 200) {
                  this.change_active(1);
                  this.$message({
                    showClose: true,
                    type: "success",
                    message: resp.msg,
                    duration: 1000,
                  });
                  this.$bus.$emit("reloadTaskList");
                  this.$bus.$emit("closeSideList");
                } else {
                  this.$message({
                    showClose: true,
                    type: "error",
                    message: "修改成功",
                    duration: 1000,
                  });
                }
              });
            }
          }
        }
      });
    },
    examineTask(approveStatus) {
      const { device, form } = this;
      if (approveStatus === 3 && !this.operateDescribe) {
        return this.$message({
          showClose: true,
          type: "error",
          message: "请输入驳回原因",
          duration: 1000,
        });
      }
      const param = {
        taskId: this.form.id,
        operateDescribe: this.operateDescribe
          ? this.operateDescribe
          : undefined,
        approveStatus,
      };
      this.$refs["form"].validate((validate) => {
        if (validate) {
          if (form.taskName) {
            flightTaskAPI.approveTask(param).then((resp) => {
              if (resp.code == 200) {
                this.change_active(1);
                this.$message({
                  showClose: true,
                  type: "success",
                  message: "审批成功",
                  duration: 1000,
                });
                this.$bus.$emit("reloadTaskList");
                this.$bus.$emit("closeSideList");
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: resp.msg,
                  duration: 1000,
                });
              }
            });
          }
        }
      });
    },
    // 获取航线列表
    async getFlightList() {
      const param = {
        page: 1,
        pageSize: 9999,
      };
      const resp = await AirLine.lineList(param);
      const fileArr = resp.data.records.map((item) => {
        return { value: item.id, label: item.name };
      });
      this.filename_options = fileArr;
    },

    // // 获取组织列表
    // async getCompanyList() {
    //   const resp = await flightTaskAPI.getCompany();
    //   // const companyArr = [{
    //   //   value: resp.data.name,
    //   //   label: resp.data.name
    //   // }];
    //   // this.organizationId = resp.data.id;
    //   console.log(companyArr,'获取组织列表');
    //   this.company_options = companyArr;
    // },

    // 获取设备列表
    async getUavList() {
      // const param = {
      //   organizationId: 1
      // };
      const resp = await flightTaskAPI.getUavflyList();
      let uavArr = {};
      if (resp.code == 200) {
        uavArr = resp.data.records.map((item) => {
          return {
            value: item.id,
            label: item.name,
            obj: item,
          };
        });
      } else {
        this.$message({
          type: "error",
          message: "获取设备列表失败！",
          duration: 1000,
        });
        uavArr = {};
      }
      this.uav_options = uavArr;
    },
  },
};
</script>

<style lang="scss" scoped>
.flight-task-edit {
  position: relative;
  height: 100%;
  // background: rgba(19, 21, 24, 0.7);
  // box-shadow: inset 0 1px 8px 0 #2883b3,
  //   inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
  //   inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  // border: 1px solid #3bc1e5;
  // position: absolute;
  // top: 200px;
  // right: 700px;
  // margin-top: -30px;
  position: relative;
  /* margin-left: 28px; */

  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    border-radius: 4px 4px 0 0;
    margin-left: 10px;

    ::v-deep {
      .el-tabs {
        .el-tabs__nav-scroll {
          display: flex;
          justify-content: center;
        }
        .el-tabs__active-bar {
          background-color: #a4ff64;
        }
        .el-tabs__nav-wrap::after {
          background-color: transparent;
        }
        .el-tabs__item {
          color: #fff;
          &:hover {
            color: #a4ff64;
          }
          &.is-active {
            color: #a4ff64;
          }
        }
      }
    }
    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }

    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
  .flow-chart {
    max-height: 600px;
    max-width: 400px;
    overflow: auto;
    position: relative;
    padding-bottom: 10px;
    .example {
      position: absolute;
      left: 20px;
      top: 0;
      width: 110px;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .example-line {
          width: 20px;
          height: 3px;
          background-color: #52c2e9;
          &.back {
            background-color: #c63d3d;
          }
        }
        .example-label {
          font-size: 14px;
          color: #fff;
        }
      }
    }
    .chart-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .pie {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        background-color: #9f9f9f;
        border: 1px solid #c9c9c9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .pie-body {
          text-align: center;
        }
      }
      .diamond-pro {
        width: calc(90px * 1.414);
        height: calc(90px * 1.414);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .diamond-body {
          position: absolute;
          text-align: center;
          z-index: 1;
        }
        .diamond {
          width: 90px;
          height: 90px;
          transform: rotate(45deg);
          background-color: #9f9f9f;
          border: 1px solid #c9c9c9;
          cursor: pointer;
        }
      }
      .label-functionType {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 20px;
      }
      .label-orgName {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
      }
      .arrow {
        width: 3px;
        .arrow-line {
          height: 30px;
          background-color: #52c2e9;
        }
        .arrow-head {
          border: 5px solid transparent;
          border-top: 8px solid #52c2e9;
          transform: translateX(-3px);
        }
      }
      .arrow-back {
        position: absolute;
        left: calc(50% + 90px * 1.414 / 2);
        top: calc(90px / 2);
        width: 25px;
        .line {
          background-color: #c63d3d;
        }
        .back-top-box {
          .head {
            position: absolute;
            left: calc((-90px) * 1.414 / 2 - (-90px) / 2 - 5px);
            top: 0;
            border: 5px solid transparent;
            border-right: 8px solid #c63d3d;
            transform: translateY(-3px);
          }
          .line {
            position: absolute;
            right: 0;
          }
        }
        .back-middle-box {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 3px;
        }
        .back-bottom-box {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
        }
      }
      .active {
        background-color: #0e290e !important;
        border: 1px solid #44d078 !important;
      }
    }
  }

  .body {
    height: calc(100% - 105px);
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 10px;
    border-bottom: 1px solid #494949;
    padding-bottom: 30px;
    // border-top: 1px solid #494949;
    .el-form {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #b3bbc5;
      letter-spacing: 0;
      font-weight: 400;

      &::v-deep {
        .el-radio {
          color: #fff;
        }
        .el-date-editor {
          &.el-range-editor.el-input__inner.el-date-editor--daterange {
            width: 227px;
            height: 32px;
            background-color: #16181b;
          }
          .el-range-input {
            background-color: #16181b;
            color: #fff;
          }
          .el-range-separator {
            color: #fff;
            line-height: 26px;
          }
          .el-input__inner {
            border: none;
          }
          .el-range__icon {
            line-height: 26px;
          }
        }
        .el-form-item__label {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #b3bbc5;
        }

        .el-input__count {
          background: none;
          position: absolute;
          right: 16px;
          bottom: -7px;
        }

        .el-form-item__error {
          padding-top: 0px;
          position: absolute;
          bottom: -12px;
          margin-left: 15px;
        }

        .el-form-item {
          margin-bottom: 16px;

          .required {
            color: #ed6a6a;
            width: 4px;
            position: absolute;
            left: -29%;
          }

          .el-input {
            width: 227px;
            height: 32px;

            .el-input__inner {
              height: 100%;
              width: 100%;
              background: #16181b;
              border-radius: 8px;
              border: none;

              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: #c6ccd4;
              letter-spacing: 0;
              font-weight: 400;
            }

            .el-input__icon {
              line-height: 32px;
              color: #1eb9fa;
            }
          }

          .el-textarea__inner {
            margin-top: 4px;
            width: 227px;
            // height: 100px;
            // max-height: 100px;
            // min-height: 100px;
            background: #16181b;
            border-radius: 8px;
            border: none;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #c6ccd4;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
      }
    }

    .form_btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-family: MicrosoftYaHei;

      .btn {
        width: 92px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #3bc1e5;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        &.btn_create {
          background: #1890ff;
        }
        &.btn_cancel {
          background: #3a4252;
        }
        &.btn_off {
          background: #fb4a2d;
          border: 1px solid #ff7171;
        }
        &.btn_on {
          background: #1890ff;
          border: 1px solid #3bc1e5;
        }
      }
    }
  }

  .operateDescribe {
    position: absolute;
    left: 405px;
    top: 0;
    width: 352px;
    height: 293px;
    border: 1px solid #34dfff;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
      inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
      inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    border-radius: 8px;
    .operateDescribe-body {
      width: 312px;
      height: 172px;
      background: #2c313b;
      border-radius: 4px 4px 0px 0px;
      margin: 8px;
      padding: 12px;
      .required {
        color: #ed6a6a;
        width: 4px;
      }
      .label {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        line-height: 16px;
      }
      &::v-deep {
        .el-input__count {
          background: none;
          position: absolute;
          right: 16px;
        }
        .el-textarea__inner {
          margin-top: 4px;
          background: #16181b;
          border-radius: 8px;
          border: none;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #c6ccd4;
          letter-spacing: 0;
          font-weight: 400;
          width: 312px;
          height: 119px !important;
        }
      }
    }
    .form_btn {
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 14px;
      color: #f8f8f8;
      letter-spacing: 0;
      text-align: center;
      font-family: MicrosoftYaHei;

      .btn {
        width: 92px;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #3bc1e5;
        line-height: 32px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
        &.btn_create {
          background: #1890ff;
        }
        &.btn_cancel {
          background: #3a4252;
        }
        &.btn_off {
          background: #fb4a2d;
          border: 1px solid #ff7171;
        }
        &.btn_on {
          background: #1890ff;
          border: 1px solid #3bc1e5;
        }
      }
    }
  }
}
.taskDetails {
  position: absolute;
  height: unset;
  right: -92%;
  top: 0%;
  z-index: 1;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 8px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(52, 223, 255, 1),
      rgba(24, 113, 214, 0)
    )
    1 1;
  backdrop-filter: blur(4px);
  .head {
    box-sizing: border-box;
    width: 352px;
    height: 32px;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    .left {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #ebf5ff;
    }
    .right {
      color: #ebf5ff;
    }
  }
  .body {
    margin: 10px 8px;
    background: #2c313b;
    border-radius: 4px 4px 0px 0px;
    overflow: auto;
    height: auto;
    .item {
      margin-top: 8px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .label {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #b3bbc5;
        margin-right: 16px;
      }
      .value {
        box-sizing: border-box;
        width: 220px;
        height: 32px;
        background: #16181b;
        border-radius: 8px;
        border: 1px solid #233a58;
        font-size: 14px;
        color: #d8dde8;
        padding: 6px 12px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
      .remark {
        box-sizing: border-box;
        width: 220px;
        height: 67px;
        background: #16181b;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #233a58;
        color: #d8dde8;
        padding: 6px 12px;
      }
    }
  }
}
</style>
