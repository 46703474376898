<template>
  <div>
    <div class="tagBox">
      <el-input
        v-model="searchTag"
        class="mb10"
        el-input
        placeholder="输入标签名称搜索"
        size="mini"
        clear
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search cp"
          @click="getList"
        />
      </el-input>
      <div class="jcsb list mt12">
        <div class="tagBtn" @click="addTagFlag = !addTagFlag">+添加标签</div>
        <div
          class="tagBtn"
          @click="dialogVisibleTagType = !dialogVisibleTagType"
        >
       类型管理
        </div>
        <div class="tagBtn ">
          <el-upload
            class="upload-demo"
            action="#"
            :http-request="httpRequest"
            :before-upload="beforeUpload"
            :before-remove="beforeRemove"
            :show-file-list="false"
            multiple
            accept=".xlsx"
            :limit="limit"
            :on-exceed="handleExceed"
            :auto-upload="true"
          >
            <el-button  size="mini" >
              导入
            </el-button>
          </el-upload>
        </div>
        <div class="tagBtn" @click="exportLabbel">
          导出
          <!-- /exportLabbel -->
        </div>
      </div>
      <div class="mt10 jcsb wih100">
        <div v-show="addTagFlag" class="seach wih100 cf">
          <el-input
            v-model="search"
            class="w248 ml26"
            placeholder="请输入标签名称"
            size="mini"
            clear
          />
          <span class="dib ml14 cp" title="添加标签" @click="addTag"> √ </span>
          <span class="dib w20 ml14 cp" @click="search = null"> X </span>
        </div>
      </div>
      <div class="mt15">
        <div
          v-for="tag in tagList"
          :key="tag.id"
          class="item mb10 jcsb p5 pl10 pr10"
        >
          <div>
            <div
              style="color: #ecb035"
              class="iconfont dib vat f20 icon-chengguoku"
            />
            <span class="tag dib cf ml10" :title="tag.name">
              <span style="color: #4fa8fa; font-weight: bold" class="f20 fwb"
                >·</span
              >
              {{ tag.name }}</span
            >
          </div>
          <div class="item_cz">
            <span
              class="iconfont icon-bianji f16 cp"
              style="color: #50abff"
              @click="edit(tag)"
            />
            <span
              class="iconfont icon-shanchu1 f16 cp ml10"
              style="color: #50abff"
              @click="del(tag)"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="TagTypestyle"
      :append-to-body="true"
      title="标签类型管理"
      :visible.sync="dialogVisibleTagType"
      width="19%"
      :before-close="dialogVisibleTagTypeClose"
    >
      <div>
        <div class="mt12 fl f12 btn w48 cp" @click="showTagTypeFn(1)">新增</div>
        <div class="fix" />
        <div class="mt15 tagTypeListBox">
          <div
            v-for="(tag, index) in tagTypeList"
            :key="index"
            class="item mb10 jcsb p5 pl10 pr10"
          >
            <div class="dib title cf ml10" :title="tag.name">
              {{ tag.name || "暂无" }}
            </div>
            <span
              v-for="i in tag.labelOuts"
              :key="i.id"
              class="dib labelOuts cf ml10"
              :title="i.labelName"
            >
              {{ i.labelName || "暂无" }}</span
            >
            <span
              class="dib btn pa cp w48 right65 top-10 f9"
              @click="showTagTypeFn(2, tag)"
              >编辑</span
            >
            <span class="w48 right9 pa btn top-10 f9" @click="delTagType(tag)"
              >删除</span
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      class="mt200"
      :append-to-body="true"
      title="修改标签"
      :visible.sync="dialogVisible"
      width="15%"
      :before-close="beforeClose"
    >
      <el-input
        v-model="editFrom.name"
        placeholder="请输入标签名称"
        clearable
      />
      <div class="wih100">
        <el-button
          type="primary"
          style="display: block; margin: 0 auto"
          size="mini"
          class="mt20 ml100"
          @click="editBtn"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      width="19%"
      class="mt142"
      :visible.sync="addTagType"
      :title="addTagTypeTitle + '标签类型'"
      left="600"
      top="235"
      @on-close="closeFn"
    >
      <div class="mt10 tagListType">
        <div style="color: #9199a2">
          <span class="ml5">输入名称：</span>
          <el-input
            v-model="tagType"
            class="w200"
            placeholder="请输入标签类型"
            size="mini "
            clear
          />
        </div>
        <div class="itemBox">
          <div v-for="tag in tagList" :key="tag.id" class="item pl5 pr5">
            <div>
              <el-checkbox v-model="tag.status" />
              <span class="tag dib ml10"> {{ tag.name }}</span>
            </div>
          </div>
        </div>
        <div class="mt10" style="display: flex; justify-content: space-around">
          <div class="fr f16 btn h32 lh32 w100 cp pt8" @click="addTagTypeFn">
            确定
          </div>
          <div
            class="fr f16 btn h32 lh32 w100 cp pt8"
            @click="addTagType = false"
          >
            取消
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Dialog from "@/components/dialog/index.vue";
import { ThreeDimensionalApi } from "@/api";
import request from '@/utils/request';
export default {
  components: { Dialog },
  data() {
    return {
      uploadUrl: "/crm/dimensionLabel/importLabbel", // 替换为你的上传地址
      limit: 1111, // 限制文件数量
      addTagFlag: false,
      tagType: "",
      searchTag: null,
      table: [
        {
          name: "+添加标签",
          icon: "icon-biaoqian",
        },
        {
          name: "标签类型管理",
          icon: "icon-biaoqian",
        },
      ],
      formEdit: {},
      itemIndex: 1,
      search: "",
      checked: "",
      tagList: [],
      dialogVisible: false,
      dialogVisibleTagType: false,
      allowedFormats: [".xlsx"],
      editFrom: {},
      tagTypeList: [
        {
          id: 21,
          name: "测试标签测试标签测试标签",
          labelOuts: [
            {
              labelId: 125,
              labelName: "31231",
              labelGroupId: 21,
            },
            {
              labelId: 25,
              labelName: "31231",
              labelGroupId: 221,
            },
            {
              labelId: 215,
              labelName: "31231",
              labelGroupId: 21,
            },
            {
              labelId: 226,
              labelName: "测试",
              labelGroupId: 212,
            },
          ],
        },
      ],
      addTagType: false,
      addTagTypeTitle: "添加",
    };
  },
  created() {
    this.getList();
    this.getTagGroupListFn();
  },
  methods: {
    exportLabbel(){
      const link = document.createElement('a'); // 创建一个链接元素
    link.href = "https://mmc-v2-statistic.oss-cn-shenzhen.aliyuncs.com/%E6%A0%87%E7%AD%BE%E6%95%B0%E6%8D%AE.xlsx"; // 设置文件 URL
    link.download = '标签数据.xlsx'; // 设置下载的文件名
    document.body.appendChild(link); // 将链接添加到文档中
    link.click(); // 自动点击链接以下载文件
    document.body.removeChild(link); // 下载后移除链接
    },
    httpRequest({ file, onSuccess, onError }) {
      // 自定义上传请求
      const formData = new FormData();
      formData.append("file", file);
      request(this.uploadUrl, {
        method: "POST",
        data: formData,
      })
        .then((data) => {
          if(data.code==525){
            return this.$message.warning(data.msg)
          }
          this.fileList = null;
          this.$message.success("导入成功");
          this.getList();
        })
        .catch((error) => {
          console.log(error,'error');
          
          this.fileList = null;
          this.$message.error("导入失败");
        });
    },
    handlePreview(file) {
      // 预览文件
      console.log("预览文件:", file);
    },
    handleRemove(file, fileList) {
      // 移除文件
      console.log("移除文件:", file);
      this.fileList = fileList;
    },
    beforeUpload(file) {
      const isAllowedFormat = this.allowedFormats.some((format) =>
        file.name.endsWith(format)
      );
      if (!isAllowedFormat) {
        this.$message.error(
          `只允许上传格式为 ${this.allowedFormats.join(", ")} 的文件`
        );
      }
      return isAllowedFormat; // 返回是否允许上传
    },
    beforeRemove(file, fileList) {
      // 移除前的确认
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleExceed(files, fileList) {
      // 超出限制时的处理
      // this.$message.warning(`只能上传 ${this.limit} 个文件`);
    },
    dialogVisibleTagTypeClose() {
      this.dialogVisibleTagType = false;
    },
    async delTagType(tag) {
      const res = await ThreeDimensionalApi.tagGroupDelete({ id: tag.id });
      if (res.code == 200) {
        this.$message.success("删除成功！");
        this.getTagGroupListFn();
      }
    },
    showTagTypeFn(num, tag) {
      if (num == 1) {
        this.addTagTypeTitle = "添加";
      } else {
        tag.labelOuts.forEach((element) => {
          this.tagList.map((e) => {
            if (e.id == element.labelId) {
              e.status = true;
            }
          });
        });
        this.tagType = tag.name;
        this.addTagTypeTitle = "修改";
        this.formEdit = tag;
      }
      this.addTagType = true;
    },
    // 添加标签类型
    async addTagTypeFn() {
      const ids = [];
      if (this.tagType == "") return this.$message.warning("请输入标签类型！");
      this.tagList.forEach((element) => {
        if (element.status) {
          ids.push(element.id);
        }
      });
      if (!ids.length) return this.$message.warning("请勾选标签类型！");
      if (this.addTagTypeTitle == "添加") {
        const res = await ThreeDimensionalApi.addTagGroup({
          name: this.tagType,
          labels: ids,
        });
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.getTagGroupListFn();
          this.tagList = this.tagList.map((e) => ({
            ...e,
            status: false,
          }));
          this.addTagType = false;
          this.tagType = "";
        }
      } else {
        if (!ids || ids.length == 0) {
          return this.$message.warning("请选择标签！");
        }
        const res = await ThreeDimensionalApi.updateGroup({
          name: this.tagType,
          labels: ids,
          id: this.formEdit.id,
        });
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.getTagGroupListFn();
          this.tagList = this.tagList.map((e) => ({
            ...e,
            status: false,
          }));
          this.addTagType = false;
          this.tagType = "";
        }
      }
    },
    async getTagGroupListFn() {
      const res = await ThreeDimensionalApi.getTagGroupList();
      if (res.code === 200) {
        this.tagTypeList = res.data;
      }
    },
    closeFn() {
      this.tagList = this.tagList.map((e) => ({
        ...e,
        status: false,
      }));
      this.tagType = "";
      this.addTagType = false;
    },
    beforeTagTypeClose() {
      this.dialogVisibleTagType = false;
    },
    beforeClose() {
      this.dialogVisible = false;
    },
    async getList() {
      const res = await ThreeDimensionalApi.getAllLabels({
        name: this.searchTag,
      });
      if (res.code == 200) {
        this.tagList = res.data.map((e) => ({
          ...e,
          status: false,
        }));
      }
    },
    async addTag() {
      if (this.search != "") {
        const res = await ThreeDimensionalApi.tagAdd({ name: this.search });
        if (res.code === 200) {
          this.$message.success("添加成功");
          this.search = "";
          this.getList();
          this.addTagFlag = false;
        } else {
          this.$message.error(res.msg);
          this.search = null;
        }
      } else {
        this.$message.warning("请输入标签名称");
      }
    },
    async del(item) {
      const res = await ThreeDimensionalApi.dropAdd({ id: item.id });
      this.$message.success("删除成功");
      this.getList();
    },
    edit(item) {
      this.editFrom = JSON.parse(JSON.stringify(item));
      this.dialogVisible = true;
    },
    async editBtn() {
      const { editFrom } = this;
      if (this.tagList.some((v) => v.name === editFrom.name)) {
        return this.$message.warning("标签已存在");
      }
      const res = await ThreeDimensionalApi.updateLabbel({
        id: editFrom.id,
        name: editFrom.name,
      });
      if (res.code == 200) {
        this.$message.success("修改成功！");
        this.dialogVisible = false;
        this.getList();
      }
    },
    handleIconClick() {},
    changeTble(num) {
      this.itemIndex = num;
    },
    closeIDsPopup() {
      this.$emit("closeDemand");
    },
  },
};
</script>
<style lang="scss" scoped>
.tagBox {
  height: 77vh;
  overflow-y: auto;
  padding: 10px;
  overflow-x: hidden;

  .list {
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    margin: 0 auto;

    .tagBtn {
      width: 127px;
      height: 33px;
      cursor: pointer;
      line-height: 33px;
      border-radius: 2px;
      border: 1px solid #50abff;
      color: #3a7bb7;
      text-align: center;
      ::v-deep .el-button--default {
        background-color: transparent;
        border: none;
        color: #3a7bb7;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .item {
      // width: calc(100% / 3);
      flex: 1;
      text-align: center;
      color: #fff;
      height: 34px;
      line-height: 34px;
      cursor: pointer;
    }

    .active {
      background: #1f4dcb;
    }
  }

  .item {
    height: 34px;
    line-height: 34px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    // border: 1px solid #73a6d1;
    backdrop-filter: blur(4px);

    .tag {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .item_cz {
      display: none;
    }
  }
}

.item:hover {
  .item_cz {
    display: block;
  }
}

.btn {
  height: 23px;
  line-height: 23px;
  background: #50abff;
  margin-left: 13px;
  border-radius: 2px;
  backdrop-filter: blur(4px);
  text-align: center;
  color: white;
}

::v-deep {
  .el-input__inner {
    // background: rgba(21, 95, 192, 0.1);
    // border: 1px solid #73a6d1;
    // min-width: 261px;
    color: #fff;
  }

  .el-textarea__inner {
    // background: rgba(21, 95, 192, 0.1);
    // border: 1px solid #73a6d1;
    color: #fff;
  }

  .el-color-picker__trigger {
    width: 308px;
  }

  .el-select--mini {
    margin-left: 0px !important;
  }

  .el-form-item__label {
    color: #fff;
  }
}

::v-deep {
  .el-input--mini {
    // box-shadow: inset 0px 1px 3px 0px #00142f;
    // border: 1px solid #73a6d1;
    backdrop-filter: blur(2px);
  }

  .el-input--mini .el-input__inner {
    // border: 1px solid #73a6d1;
    // box-shadow: inset 0px 1px 3px 0px #00142f;
    // background: rgba(21, 95, 192, 0.3);
    color: #fff;
  }

  .el-color-picker__icon,
  .el-input,
  .el-textarea {
    width: inherit;
    width: 100%;
  }

  .el-input-group__append {
    background: #143469;
    border: 1px solid #143469;
  }

  .el-dialog {
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
      inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 4px 0px #2e63dc;
    border-radius: 4px;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(52, 223, 255, 1),
        rgba(24, 113, 214, 0)
      )
      1 1;
    backdrop-filter: blur(4px);

    .el-dialog__title {
      color: #d0dae5;
      font-size: 16px;
      margin-left: 9px;
    }

    .el-dialog__header {
      padding: 0px;
      border-bottom: 2px solid #000000;
    }

    .el-dialog__body {
      padding: 0px;
    }

    .el-dialog__close {
      font-size: 22px;
      position: absolute;
      right: -7px;
      top: -5px;
    }
  }
}

.tagListType {
  height: 500px;

  .itemBox {
    margin-top: 20px;
    height: 375px;
    overflow-y: auto;
    padding: 0 17px;

    .item {
      height: 36px;
      line-height: 36px;

      .tag {
        color: #777c83;
      }
    }

    .item:nth-child(odd) {
      background-color: #0a0b0d;
    }

    .item:nth-child(even) {
      background-color: #14151a;
    }
  }

  ::v-deep {
    .el-input .el-input__inner {
      width: 100%;
      color: #b3bbc5;
      // width: 185px;
      height: 32px;
      background: #16181b;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
      border-radius: 0px;
      border: 1px solid #000000;
    }
  }
}

.TagTypestyle {
  ::v-deep {
    color: #d0dae5;
    font-size: 10px;
  }

  .tagTypeListBox {
    padding: 10px;
    width: 93%;
    height: 38vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .item {
    margin-top: 22px;
    border: 1px solid #7f7f7f;
    height: 56px;
    position: relative;
    border-radius: 2px;
    display: flex;
    width: 97%;
    justify-content: flex-start;
    line-height: 56px;
    background-color: #000000;

    .title {
      height: 24px;
      line-height: 24px;
      background: #292b30;
      font-size: 9px;
      position: absolute;
      border: 1px solid #71757e;
      top: -14px;
      width: 88px;
      color: #b3bbc5;
      left: -2px;
      text-align: center;
      background-color: #292b30;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .labelOuts {
      border: 1px solid #71757e;
      color: #b3bbc5;
      width: 42px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 17px;
      font-size: 9px;
      text-align: center;
      height: 24px;
      line-height: 24px;
    }
  }
}
</style>
